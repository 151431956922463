import { Box,Grid } from "@mui/material";
import MaterialTable from "material-table";
import SelectableSearch from "../../../Base/views/SelectableSearch";
import { useState } from "react";
import { GetQualityReportsAction } from "../../../../../store/ATS/Report/Action";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { ConvertDateTime } from "../../../Base/helper/baseFunctions";
import CustomPagination from "../../../Base/views/CustomPagination";

const QualityReport = () => {
    const dispatch = useDispatch();
    const [userData, setUserData] = useState({ load: false, values: [] })
    const [filters, setFilters] = useState({ primary_recruiter_filter: "", secondary_recruiter_filter: "" });
    const { atsQualityReport } = useSelector(state => state.AtsReducer?.AtsReportReducer);
    const { atsRecruiterData } = useSelector(state => state.AtsReducer?.AtsReportReducer);
    const parseAndSum = (field) => atsQualityReport?.reduce((acc, curr) => acc + parseInt(curr[field] || '0', 10), 0);
    const sumshortlisted = parseAndSum('shortlisted');
    const sumrejected = parseAndSum('rejected');
    const sumaccepted = parseAndSum('accepted');
    const hasNonZeroSum = sumshortlisted || sumrejected || sumaccepted
    const displayableKeys = hasNonZeroSum ? [
        ["Candidates Proposed", sumshortlisted],
        ["Candidates Rejected", sumrejected],
        ["Offer Accepted", sumaccepted],
    ] :[];

    useEffect(() => {
        dispatch(GetQualityReportsAction({
            primary_recruiter: filters?.primary_recruiter_filter ? `{${filters?.primary_recruiter_filter?.ats_users_id}}` : null,
        }))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filters])

    const handleFilter = (event, value) => {
        if (["primary_recruiter_filter"].includes(event)) {
            if (value) setFilters({ ...filters, [event]: value })
                else setFilters({ ...filters, [event]: "" })
        }
    }

    return (
        <>
            <Box sx={{ mt: 1 }}>
                <Grid container columnSpacing={1} mb={1}>
                    <Grid key="primary_recruiter_id" item xs={12} sm={5}>
                            <SelectableSearch
                                isOptionEqualToValue={(option, value) =>
                                    +option.ats_users_id === +value.ats_users_id
                                }
                                label="Recruiter"
                                value={filters.primary_recruiter_filter}
                                loading={() => setUserData({ load: false, values: atsRecruiterData })}
                                fieldLabel="first_name"
                                required={false}
                                grouping={true}
                                variant={"outlined"}
                                name="primary_recruiter_filter"
                                onChangeEvent={handleFilter}
                                data={userData}
                            />
                    </Grid>
                </Grid>
            </Box>
            <Box>
                <MaterialTable
                    columns={[
                        { title: "Job Creation Date", cellStyle: { padding: "10px", textAlign: "left" }, headerStyle: { padding: "10px", textAlign: "left" }, field: "job_creation_date", editable: "never", render: rowData => { return rowData?.job_creation_date ? ConvertDateTime(rowData?.job_creation_date) : "    " } },
                        { title: "Recruiter", cellStyle: { padding: "10px", textAlign: "left" }, headerStyle: { padding: "10px", textAlign: "left" }, field: "recruiter_name", editable: "never" },
                        { title: "Job Title", cellStyle: { padding: "10px", textAlign: "left" }, headerStyle: { padding: "10px", textAlign: "left" }, field: "job_title", editable: "never" },
                        { title: "Candidates Proposed", cellStyle: { padding: "10px", textAlign: "center" }, headerStyle: { padding: "10px", textAlign: "center" }, field: "shortlisted", editable: "never" },
                        { title: "Candidates Rejected", cellStyle: { padding: "10px", textAlign: "center" }, headerStyle: { padding: "10px", textAlign: "center" }, field: "rejected", editable: "never" },
                        { title: "Offer Accepted", cellStyle: { padding: "10px", textAlign: "center" }, headerStyle: { padding: "10px", textAlign: "center" }, field: "accepted", editable: "never" },
                        { title: "Offer Accepted %", cellStyle: { padding: "10px", textAlign: "center" }, headerStyle: { padding: "10px", textAlign: "center" }, field: "accepted_percentage", editable: "never" },
                        { title: "Rejected %", cellStyle: { padding: "10px", textAlign: "center" }, headerStyle: { padding: "10px", textAlign: "center" }, field: "rejected_percentage", editable: "never" },

                    ]}
                    data={ atsQualityReport || []}
                    options={{
                        maxBodyHeight: "54vh",
                        minBodyHeight: "54vh",
                        sorting: true,
                        search: true,
                        searchFieldAlignment: "right",
                        searchAutoFocus: true,
                        searchFieldVariant: "standard",
                        filtering: false,
                        exportButton: atsQualityReport?.length > 0 && {
                            csv: true,
                            pdf: true,
                        },
                        exportAllData:true,
                        paging: true,
                        pageSizeOptions: [25, 50],
                        pageSize: 25,
                        showTextRowsSelected: true,
                        selectionProps: rowData => ({
                            disabled: [null, '', 0, '0'].includes(rowData.commission_percentage),
                        }),
                        showSelectAllCheckbox: false,
                        actionsColumnIndex: -1,
                        grouping: false,
                        addRowPosition: "first",
                        columnsButton: true,
                        draggable:false,                        
                    }}
                    components={{
                        Pagination: props => <CustomPagination {...props} totalRecords={atsQualityReport?.length || 0} displayableKeys={displayableKeys} />
                      }}
                    title="Quality"
                />
            </Box>
        </>
    )
}

export default QualityReport;


