import NProgress from 'nprogress';
import { toast } from 'react-toastify';
import axiosInstance from "../../../../axiosInstance";
import { HRMS_ATS_API_URL } from "../../../Constants/baseConstant";
import { getUserData } from "../../Base/helper/baseFunctions";

export const GetAtsInterviewDetailsHelper = async (params) => {
    var user = getUserData();
    NProgress.start()
    return await axiosInstance(`/ats/getInterviewDetails`, {
        method: "POST",
        baseURL: HRMS_ATS_API_URL,
        data: {
            workspace: user.domain,
            user_emp_id: user?.ats_user_id,
            month_year: params?.month_year,
            month_year1: params?.month_year1 || null,
        }
    })
        .then((res) => {
            NProgress.done()
            return { message: "Success", hasError: false, data: res?.data }
        }).catch(err => {
            NProgress.done()
            return { message: "Error", hasError: true, data: err?.response?.data }
        })
}
export const AddAtsInterviewDetailsHelper = async (params) => {
    var user = getUserData();
    toast.dismiss();
    toast.info("Saving...")
    return await axiosInstance(`/ats/addInterviewDetails`, {
        method: "POST",
        baseURL: HRMS_ATS_API_URL,
        data: {
            workspace: user.domain,
            user_emp_id: user?.ats_user_id,
            candidate_job_id: params?.candidate_job_id?.candidate_job_id || null,
            ats_jobpost_id: params?.ats_jobpost_id?.ats_jobpost_id || null,
            interview_date: null,
            start_date: params?.start_date,
            end_date: params?.end_date,
            status: params?.status,
            postponed_date: params?.postponed_date || null,
            venue: params?.venue,
            modeof_interview: params?.modeof_interview,
            scheduled_by: user?.ats_user_id,
            interviewer: params?.interviewer,
            comment: params?.comment,
            response_id: params?.response_id,
            description: params?.description,
            event_name: params?.event_name,
            candidate_email: params?.candidate_email,
        }
    })
        .then((res) => {
           
            return { message: "Success", hasError: false, data: res?.data }
        }).catch(err => {

            return { message: "Error", hasError: true, data: err?.response?.data }
        })
}

export const UpdateAtsInterviewDetailsHelper = async (params) => {
    var user = getUserData();
    toast.dismiss();
    toast.info("Saving...")
    return await axiosInstance(`/ats/updateInterviewDetails`, {
        method: "PUT",
        baseURL: HRMS_ATS_API_URL,
        data: {
            workspace: user.domain,
            user_emp_id: user?.ats_user_id,
            ats_interview_details_id: params?.ats_interview_details_id,
            candidate_job_id: params?.candidate_job_id?.candidate_job_id || null,
            ats_jobpost_id: params?.ats_jobpost_id?.ats_jobpost_id || null,
            interview_date: null,
            start_date: params?.start_date,
            end_date: params?.end_date,
            status: params?.status,
            postponed_date: params?.postponed_date || null,
            venue: params?.venue,
            modeof_interview: params?.modeof_interview,
            scheduled_by: user?.ats_user_id,
            interviewer: params?.interviewer,
            comment: params?.comment,
            event_name: params?.event_name,
            description: params?.description,
            candidate_email: params?.candidate_email,
        }
    })
        .then((res) => {
            return { message: "Success", hasError: false, data: res?.data }
        }).catch(err => {
            return { message: "Error", hasError: true, data: err?.response?.data }
        })
}

export const DeleteAtsInterviewDetailsHelper = async (params) => {
    var user = getUserData();
    toast.dismiss();
    toast.info("Deleting...")
    return await axiosInstance(`/ats/deleteInterviewDetails`, {
        method: "DELETE",
        baseURL: HRMS_ATS_API_URL,
        data: {
            workspace: user.domain,
            user_emp_id: user?.ats_user_id,
            ats_interview_details_id: params?.ats_interview_details_id,
            month_year: params?.month_year,
            reason: params?.comment
        }
    })
        .then((res) => {
            return { message: "Success", hasError: false, data: res?.data }
        }).catch(err => {
            return { message: "Error", hasError: true, data: err?.response?.data }
        })
}

