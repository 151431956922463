import * as ActionTypes from '../ActionTypes';


export const GetEmployeeTemplateAction = (params) => {
    return {
        type: ActionTypes.EMPLOYEE_TEMPLATE_GET_REQUEST,
        params
    }
}

export const AddEmployeeTemplateAction = (params) => {
    return {
        type: ActionTypes.EMPLOYEE_TEMPLATE_ADD_REQUEST,
        params
    }
}

export const UpdateEmployeeTemplateAction = (params) => {
    return {
        type: ActionTypes.EMPLOYEE_TEMPLATE_UPDATE_REQUEST,
        params
    }
}
