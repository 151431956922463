import {
  Box,
  Button,
  TableFooter,
  TextField,
  Typography,
  Backdrop,
  CircularProgress,
} from "@mui/material";
import React, { useState } from "react";
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import ArrowBackButton from "../../../../Base/views/ArrowBackButton";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import "../../../css/styles.css";
import { toast } from "react-toastify";
import {
  GetMyTimeSheetLeaveInfoHelper,
  GetMyTimeSheetProjectHelper,
  GetWeekdatesHelper,
  GetWeekdaysHelper,
} from "../../../helper/TimeSheet/MyTimeSheetHelper";
import SelectableSearch from "../../../../Base/views/SelectableSearch";
import {
  AddMyTimeSheetAction,
  UpdateMyTimeSheetAction,
} from "../../../../../../store/Leave/MyTimeSheet/Action";
import { ApproveTimeSheetAction } from "../../../../../../store/Leave/MyTeam/Action";
import Popup from "../../../../Base/views/Popup";
import ApplyLeave from "../TimeOff/ApplyLeave";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
  "&.total": {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

function createData(name, sun, mon, tue, wed, thu, fri, sat) {
  return { name, sun, mon, tue, wed, thu, fri, sat };
}
const headers = [
  "Project Task",
  "Sun",
  "Mon",
  "Tue",
  "Wed",
  "Thu",
  "Fri",
  "Sat",
  "Total",
];
const columnNames = ["name", "mon", "tue", "wed", "thu", "fri", "sat", "sun"];
const rows = [createData("", "", "", "", "", "", "", "")];
let final;
const calculateTotal = (row) => {
  const numericValues = Object.values(row).map((value) => {
    const parsedValue = parseFloat(value);
    return isNaN(parsedValue) ? 0 : parsedValue; // Treat NaN values as 0
  });
  final = numericValues.reduce((total, value) => total + value, 0);
  return numericValues.reduce((total, value) => total + value, 0);
};

const MyTimeSheetForm = ({ setFormMode, tableData, setSuccessMode }) => {
  const dispatch = useDispatch();
  const [rowData, setRowData] = useState(rows);
  const [Id, setId] = useState();
  const [weekly_hour, setWeekly_hour] = useState();
  const [projectHours, setProjectHours] = useState({});
  const [errors, setErrors] = useState({});
  const [projectData, setProjectData] = useState({ load: false, values: [] });
  const [project_code, setProjectcode] = useState("");
  const [openPopup, setOpenPopup] = useState(false);
  const [weekData, setWeekData] = useState();
  const [isGetLoading, SetisGetLoading] = useState(true);
  const [projectSelected, setProjectSelected] = useState({})
  const [showComments, setShowComments] = useState(false);
  const [comments, setComments] = useState("");
  const [date, setDate] = useState();
  const [success, setSuccess] = useState(false);
  const [data, setData] = useState({});
  const { dashboardData } = useSelector(
    (state) => state?.EmployeeDashboardReducer
  );
  const { getSuccess } = useSelector(
    (state) => state?.LeaveReducer?.MyTimeSheetReducer
  );
  const { GetSuccess } = useSelector(
    (state) => state?.LeaveReducer?.TimeSheetApprovalReducer
  );
  const [ph_leave, setPh_Leave] = useState({})
  const handleDropdownChange = (event, value, rowIndex) => {
    const NewArry = [...rowData];
    // Check if the project code already exists in the table
    const isExistingProject = NewArry.some(
      (row) => row.name === value?.project_code
    );

    // If the project code already exists, don't add a new row
    if (isExistingProject) {
      NewArry[rowIndex] = {
        name: "",
        mon: "",
        tue: "",
        wed: "",
        thu: "",
        fri: "",
        sat: "",
        sun: "",
      };
      setRowData(NewArry);
      return;
    }
    const selectedProject = projectData?.values?.find(
      (project) => project?.project_code === value?.project_code
    );
    setWeekly_hour(selectedProject?.weekly_hours);
    setId(selectedProject?.mytime_configure_pk);
    // Create a new object for the updated row
    const updatedRow = {
      name: selectedProject?.project_code || "",
      mon: selectedProject?.weekly_working_days["1"] || "",
      tue: selectedProject?.weekly_working_days["2"] || "",
      wed: selectedProject?.weekly_working_days["3"] || "",
      thu: selectedProject?.weekly_working_days["4"] || "",
      fri: selectedProject?.weekly_working_days["5"] || "",
      sat: selectedProject?.weekly_working_days["6"] || "",
      sun: selectedProject?.weekly_working_days["7"] || "",
    };

    // Push the updated row only if a new project code is selected
    if (selectedProject) {
      NewArry[rowIndex] = updatedRow;
      setRowData(NewArry);
    }

    const start_date = new Date(tableData?.start_date);
    const start_date_day = start_date.getDate();
    const start_date_month = start_date.getMonth() + 1;
    const start_date_year = start_date.getFullYear();

    const end_date = new Date(tableData?.end_date);
    const end_date_day = end_date.getDate();
    const end_date_month = end_date.getMonth() + 1;
    const end_date_year = end_date.getFullYear();
    if (selectedProject?.mytime_configure_pk) {
      GetMyTimeSheetLeaveInfoHelper({
        from_date_day: start_date_day,
        from_date_month: start_date_month,
        from_date_year: start_date_year,
        to_date_day: end_date_day,
        to_date_month: end_date_month,
        to_date_year: end_date_year,
        mytime_configure_id: selectedProject?.mytime_configure_pk,
      }).then((res) => {
        const perhLeaveInfo = res?.data?.data?.[0]?.perh_leave_info || [];
        const phLeaveInfo = res?.data?.data?.[0]?.ph_leave_info || [];
        perhLeaveInfo.forEach((info) => {
          if (info.day_of_week) {
            setProjectHours((prevState) => ({
              ...prevState,
              [info.day_of_week]: info.project_hours,
            }));
          }
        });
        phLeaveInfo.forEach((info) => {
          if (info.day_of_week) {
            setProjectHours((prevState) => ({
              ...prevState,
              [info.day_of_week]: info.project_hours,
            }));
            setPh_Leave((prevState) => ({
              ...prevState,
              [info.day_of_week]: info.project_hours,
            }))
          }
        });
      });
    }

    if (["project_code"].includes(event)) {
      if (value) {
        setProjectcode({ ...project_code, [event]: value?.project_code });
      } else {
        setProjectcode({ ...project_code, [event]: "" });
        NewArry[rowIndex] = {
          name: "",
          mon: "",
          tue: "",
          wed: "",
          thu: "",
          fri: "",
          sat: "",
          sun: "",
        };
        setRowData(NewArry);
        setProjectHours({});
        return;
      }
      Object.values?.(errors)?.find((res) => res !== "")?.length > 0 &&
        validate({ ...project_code, [event]: value ? value : "" });
    }
  };

  const daysOfWeek = ["mon", "tue", "wed", "thu", "fri", "sat", "sun"];
  const rowTotal = daysOfWeek?.reduce(
    (total, day) => total + parseInt(projectHours[day] || 0),
    0
  );

  const handleInputChange = (event, rowIndex, columnIndex, columnName) => {
    const NewArry = [...rowData];
    const rowVal = NewArry[rowIndex];
    rowVal[columnName] = event?.target?.value;
    setRowData(NewArry);
  };
  const handleHolidayInputChange = (event, rowIndex, day) =>{
    const newProjectHours = { ...projectHours };
    newProjectHours[day] = event.target.value; 
    setProjectHours(newProjectHours); 
  }
  useEffect(() => {
    setProjectHours({});
    const start_date = new Date(tableData?.start_date);
    const start_date_day = start_date.getDate();
    const start_date_month = start_date.getMonth() + 1;
    const start_date_year = start_date.getFullYear();
    const end_date = new Date(tableData?.end_date);
    const end_date_day = end_date.getDate();
    const end_date_month = end_date.getMonth() + 1;
    const end_date_year = end_date.getFullYear();
    GetMyTimeSheetProjectHelper().then((res) => {
      setProjectData({
        load: false,
        values: res?.data?.data,
      });
    });
    GetWeekdaysHelper({
      start_date_day: start_date_day,
      start_date_month: start_date_month,
      start_date_year: start_date_year,
    }).then((res) => {
      setWeekData(res?.data?.data);
    });
    GetWeekdatesHelper({
      start_date_day: start_date_day,
      start_date_month: start_date_month,
      start_date_year: start_date_year,
      end_date_day: end_date_day,
      end_date_month: end_date_month,
      end_date_year: end_date_year,
    }).then((res) => {
      setDate(res?.data?.data?.[0]?.fn_map_days_to_dates);
      SetisGetLoading(false);
    });
    if (tableData?.flag !== "Not Submited") {
      const updatedRow = {
        name: tableData?.project_code || "",
        mon: tableData?.weekly_hour?.PROJECTCODE?.mon || "",
        tue: tableData?.weekly_hour?.PROJECTCODE?.tue || "",
        wed: tableData?.weekly_hour?.PROJECTCODE?.wed || "",
        thu: tableData?.weekly_hour?.PROJECTCODE?.thu || "",
        fri: tableData?.weekly_hour?.PROJECTCODE?.fri || "",
        sat: tableData?.weekly_hour?.PROJECTCODE?.sat || "",
        sun: tableData?.weekly_hour?.PROJECTCODE?.sun || "",
      };

      setRowData([updatedRow]);

      if (tableData?.mytime_configure_pk && tableData?.flag !== "Not Submited" ) {
         GetMyTimeSheetLeaveInfoHelper({
           from_date_day: start_date_day,
           from_date_month: start_date_month,
           from_date_year: start_date_year,
           to_date_day: end_date_day,
           to_date_month: end_date_month,
           to_date_year: end_date_year,
           mytime_configure_id: tableData?.mytime_configure_pk,
         }).then((res) => {
           const phLeaveInfo = res?.data?.data?.[0]?.ph_leave_info || [];
           phLeaveInfo.forEach((info) => {
             if (info.day_of_week) {
               setPh_Leave((prevState) => ({
                ...prevState,
                [info.day_of_week]: info.project_hours,
              }))
             }
           });
         });
       }

      const projectHours = {};
      daysOfWeek.forEach((day) => {
        projectHours[day.substring(0, 3).toLowerCase()] =
          tableData?.weekly_hour?.LEAVECODE?.[
            day.substring(0, 3).toLowerCase()
          ] || 0;
      });
      setProjectHours(projectHours);
      setProjectcode({ project_code: tableData?.project_code });
      setWeekly_hour(tableData?.configure_weekly_working_time);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const validate = () => {
    let isValid = true;
    const tempErrors = {};
    // Validate each row
    rowData.forEach((row, rowIndex) => {
      Object.entries(row).forEach(([columnName, value], columnIndex) => {
        if (value.trim() === "" || Number(value) < 0 ) {
          isValid = false;
          tempErrors[`row${rowIndex}_${columnNames[columnIndex]}`] =
            "This field is required.";
        }
      });
    });

     // Validate projectHours
      Object.entries(projectHours).forEach(([day, hours]) => {
        if (hours.trim() === "" || isNaN(hours) || Number(hours) < 0) {
          isValid = false;
          tempErrors[`projectHours_${day}`] = "Please enter a valid number of hours.";
        }
      });

    setErrors(tempErrors); // Update state with errors

    return isValid;
  };
  const handleAdd = () => {
    if (validate()) {
      const start_date = new Date(tableData?.start_date);
      const from_date_day = start_date.getDate();
      const from_date_month = start_date.getMonth() + 1;
      const from_date_year = start_date.getFullYear();
      const end_date = new Date(tableData?.end_date);
      const to_date_day = end_date.getDate();
      const to_date_month = end_date.getMonth() + 1;
      const to_date_year = end_date.getFullYear();
      const PROJECTCODE = Object.assign({}, rowData[0]);
      delete PROJECTCODE.name;
      const LEAVECODE = {};

      daysOfWeek.forEach((day) => {
        LEAVECODE[day] = projectHours[day] || "0";
      });
      daysOfWeek.forEach((day) => {
        if (LEAVECODE[day] !== "0") {
          if (
            PROJECTCODE[day] === LEAVECODE[day] ||
            PROJECTCODE[day] !== LEAVECODE[day]
          ) {
            PROJECTCODE[day] = "0"; // Replace value with "0"
          }
        }
      });
      let total = 0;
      for (const day in PROJECTCODE) {
        total += parseInt(PROJECTCODE[day]) + parseInt(LEAVECODE[day]);
      }
      if (total < weekly_hour) {
        toast.error(
          `The total weekly hours for ${project_code?.project_code} is ${weekly_hour} hrs.Please re-check your entered timesheet`
        );
      } else {
        const weekly_working_days = JSON.stringify({ PROJECTCODE, LEAVECODE });
        if (tableData?.flag === "Reopened" || tableData?.flag === "Rejected") {
          dispatch(
            UpdateMyTimeSheetAction({
              from_date_day: from_date_day,
              from_date_month: from_date_month,
              from_date_year: from_date_year,
              to_date_day: to_date_day,
              to_date_month: to_date_month,
              to_date_year: to_date_year,
              week_no: parseInt(weekData?.[0]?.week_no_pk),
              weekly_working_days: weekly_working_days,
              manager_emp_id:
                dashboardData?.[0]?.reporting_manager_info?.[0]?.Id,
              mytime_configure_id: Id || tableData?.mytime_configure_pk,
            })
          );
        } else {
          dispatch(
            AddMyTimeSheetAction({
              from_date_day: from_date_day,
              from_date_month: from_date_month,
              from_date_year: from_date_year,
              to_date_day: to_date_day,
              to_date_month: to_date_month,
              to_date_year: to_date_year,
              week_no: parseInt(weekData?.[0]?.week_no_pk),
              weekly_working_days: weekly_working_days,
              manager_emp_id:
                dashboardData?.[0]?.reporting_manager_info?.[0]?.Id,
              mytime_configure_id: Id,
            })
          );
        }
      }
    }
  };
  useEffect(() => {
    if (getSuccess === 200) {
      setFormMode("card");
      setSuccessMode(true);
    }
  }, [getSuccess]);
  const handleBookClick = () => {
    setOpenPopup(true);
  };
  const getEventColor = (status) => {
    switch (status) {
      case "Approved":
        return "#20e277";
      case "Submitted":
        return "#20e277";
      case "Rejected":
        return "#e68d45";
      case "Reopened":
        return "#fbeae8";
      default:
        return "#94b2d1";
    }
  };

  const handleReOpen = (status) => {
    setShowComments(true);
    setData({
      flag: status,
      week_no: tableData?.week_no_pk,
      weekly_working_days: JSON.stringify(tableData?.weekly_hour),
      timesheet_id: tableData?.timesheet_pk,
      comments: comments,
      employee_id: tableData?.employee_pk,
    });
  };

  const handleConfirm = () => {
    data["comments"] = comments;
    dispatch(ApproveTimeSheetAction(data));
    setSuccess(true);
  };
  useEffect(() => {
    if (GetSuccess === 200 && success) {
      setShowComments(false);
      setComments("");
      setFormMode("card");
      setSuccessMode(true);
    }
    //eslint-disable-next-line
  }, [GetSuccess, success]);

 useEffect(()=>{
  const selectedProject = projectData?.values?.find(
    (project) => project?.project_code === project_code?.project_code
  );
  setProjectSelected(selectedProject)
 },[project_code, projectData?.values])

  return (
    <>
      {isGetLoading && (
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={true}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
      <Box
        sx={{
          padding: "30px 0px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "start",
            "@media (max-width: 600px)": {
              flexDirection: "column",
              justifyContent: "start",
              alignItems: "start",
            },
          }}
        >
          <ArrowBackButton
            onClick={() => {
              setFormMode("card");
              setSuccessMode(true);
            }}
          />
          <Typography sx={{ padding: "10px" }} variant="h4">
            {tableData?.start_date} - {tableData?.end_date}
          </Typography>
        </Box>
        <Box></Box>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Box
            sx={{
              background: getEventColor(tableData?.flag),
              fontWeight: 500,
              color: "#000",
              fontSize: "12px",
              padding: "8px 10px",
              borderRadius: "50px",
              width: "fit-content",
              marginTop: "10px",
            }}
          >
            {tableData?.flag}
          </Box>
          <Typography sx={{ padding: "10px", marginTop: "10px" }}>
            Due on {tableData?.due_date}
          </Typography>
        </Box>
        <Box sx={{ marginTop: "20px" }}>
          <Button
            sx={{
              fontWeight: 700,
              borderRadius: "8px",
              fontSize: "13px",
              height: "30px",
              padding: "4px 10px",
              boxShadow: "none",
              lineHeight: 1.71429,
              marginRight: "10px",
            }}
            size="small"
            variant="contained"
            className="mpl-primary-btn"
            onClick={handleAdd}
            disabled={
              tableData?.flag === "Approved" || tableData?.flag === "Submitted"
            }
          >
            Submit for Approval
          </Button>
          <Button
            sx={{
              fontWeight: 700,
              borderRadius: "8px",
              fontSize: "13px",
              height: "30px",
              padding: "4px 10px",
              boxShadow: "none",
              lineHeight: 1.71429,
            }}
            onClick={() => handleBookClick()}
            size="small"
            variant="contained"
            className="mpl-primary-btn"
          >
            Book TimeOff
          </Button>

          {tableData?.flag === "Submitted" && (
            <Button
              sx={{
                fontWeight: 700,
                borderRadius: "8px",
                fontSize: "13px",
                height: "30px",
                padding: "4px 10px",
                boxShadow: "none",
                lineHeight: 1.71429,
                margin: "0px 12px",
              }}
              size="small"
              variant="contained"
              className="mpl-primary-btn"
              onClick={() => handleReOpen("Reopened")}
            >
              ReOpen
            </Button>
          )}
        </Box>

        <Popup
          fullWidth={true}
          maxWidth="lg"
          title="Book TimeOff"
          openPopup={openPopup}
          setOpenPopup={setOpenPopup}
        >
          <ApplyLeave setOpenPopup={setOpenPopup} />
        </Popup>
      </Box>
      <Box sx={{ marginTop: "20px" }}>
        <Typography sx={{ padding: "10px" }} variant="h4">
          Time Distribution
        </Typography>
      </Box>
      <TableContainer component={Paper}>
        <Table aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell
                sx={{ width: "200px", padding: "16px 16px 16px 30px" }}
                align="left"
              >
                Project Task
              </StyledTableCell>
              {date?.map((header, index) => (
                <StyledTableCell align="center" key={index}>
                  {header}
                </StyledTableCell>
              ))}
              <StyledTableCell sx={{ width: "95px" }} align="center">
                Total
              </StyledTableCell>
            </TableRow>
          </TableHead>      
          <TableBody>
            {rowData?.map((row, rowIndex) => (
              <StyledTableRow key={rowIndex}>
                {Object.values(row).map((value, columnIndex) => (
                  <StyledTableCell
                    sx={{
                      width:
                        headers[columnIndex] === "Project Task"
                          ? "200px"
                          : "95px",
                    }}
                    key={columnIndex}
                  >
                    {headers[columnIndex] === "Project Task" ? (
                      <SelectableSearch
                        value={project_code}
                        size="small"
                        label="Project Code"
                        loading={() =>
                          setProjectData({ load: false, values: projectData })
                        }
                        fieldLabel="project_code"
                        required={false}
                        variant={"outlined"}
                        name="project_code"
                        onChangeEvent={(event, value) =>
                          handleDropdownChange(event, value, rowIndex)
                        }
                        data={projectData}
                        {...(errors[
                          `row${rowIndex}_${columnNames[columnIndex]}`
                        ] && {
                          error: true,
                        })}
                        disabled={
                          tableData?.flag === "Approved" ||
                          tableData?.flag === "Submitted"
                        }
                      />
                    ) : (
                      <TextField
                        className="centered-text-input"
                        value={value}
                        onChange={(event, value) =>
                          handleInputChange(
                            event,
                            rowIndex,
                            columnIndex,
                            columnNames[columnIndex]
                          )
                        }
                        type="number"
                        error={!!errors[`row${rowIndex}_${columnNames[columnIndex]}`]}
                        disabled={
                          !projectSelected?.working_days?.some(
                            (day) => day.working_days === columnIndex 
                          ) ||
                          tableData?.flag === "Approved" ||
                          tableData?.flag === "Submitted"
                        }
                      />
                    )}
                  </StyledTableCell>
                ))}
                <TableCell align="center">{calculateTotal(row)}</TableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TableContainer sx={{ maxHeight: 440 }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead
            sx={{
              backgroundColor: "#8b44ff !important",
              color: "#fff",
            }}
          >
            <TableRow>
              <StyledTableCell
                sx={{ width: "200px", padding: "16px 16px 16px 30px" }}
                align="left"
              >
                Time Off
              </StyledTableCell>
              {date?.map((day) => (
                <StyledTableCell align="center">{day}</StyledTableCell>
              ))}
              <StyledTableCell sx={{ width: "95px" }} align="center">
                Total
              </StyledTableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            <TableRow>
              <TableCell
                sx={{ width: "200px", padding: "16px 16px 16px 30px" }}
                align="left"
              >
                Holiday
              </TableCell>
              {daysOfWeek?.map((day, rowIndex) => (
                <TableCell sx={{ width: "95px" }} align="center">
                  <TextField
                    className="centered-text-input"
                    value={projectHours[day]}
                    type="number"
                    onChange={(event, value) =>{
                          handleHolidayInputChange(
                            event,
                            rowIndex,
                            day
                          )}
                        }
                    InputProps={{
                          style: {
                            fontWeight: ph_leave[day] ? 'bold' : 'normal',                           
                          },
                        }} 
                    disabled={ !ph_leave[day] ||
                          tableData?.flag === "Approved" ||
                          tableData?.flag === "Submitted"
                        }
                    error={!!errors[`projectHours_${day}`]} 
                  />
                </TableCell>
              ))}
              <TableCell sx={{ width: "95px" }} align="center">
                {rowTotal}
              </TableCell>
            </TableRow>
          </TableBody>
          <TableFooter
            sx={{
              backgroundColor: "#8b44ff !important",
              color: "#fff",
              "&:hover": {
                backgroundColor: "#8b44ff !important",
              },
            }}
          >
            <StyledTableCell
              sx={{
                width: "200px",
                color: "#fff",
                padding: "16px 16px 16px 30px",
              }}
              align="left"
            >
              Total
            </StyledTableCell>
            {daysOfWeek?.map((day) => (
              <StyledTableCell
                sx={{
                  width: "95px",
                  color: "#fff",
                  padding: "16px 16px 16px 16px !important",
                }}
                align="center"
              >
                {Number(rowData?.[0][day]) && Number(projectHours[day])
                  ? Number(projectHours[day])
                  : Number(rowData?.[0][day]) + Number(projectHours[day] || 0)}
              </StyledTableCell>
            ))}
            <StyledTableCell sx={{ color: "#fff" }} align="center">
              {rowTotal + final || 0}{" "}
            </StyledTableCell>
          </TableFooter>
        </Table>
      </TableContainer>
      <Popup
        title="Comments"
        openPopup={showComments}
        setOpenPopup={setShowComments}
        fullWidth={true}
      >
        <TextField
          autoFocus={false}
          size="small"
          multiline
          rows={4}
          value={comments}
          onChange={(e) => setComments(e.target.value)}
          variant="outlined"
          fullWidth
          id="comments"
          label="Comments"
          name="comments"
        />
        <Box style={{ display: "flex", justifyContent: "flex-end" }}>
          <Button
            variant="contained"
            color="primary"
            type="submit"
            className="mpl-primary-btn"
            sx={{ marginTop: "1.75rem" }}
            onClick={handleConfirm}
          >
            Save
          </Button>
        </Box>
      </Popup>
    </>
  );
};

export default MyTimeSheetForm;
