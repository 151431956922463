import { combineReducers } from "redux";
import { TaxDeclarationReducer } from "./TaxDeclaration/Reducer";
import { EmployeeProfileReducer } from "./Profile/Reducer";
import { SalaryStructureReducer } from "./SalaryStructure/Reducer";
import { SalaryComponentReducer } from "./SalaryComponent/Reducer";
import { SalaryComponentTypeReducer } from "./SalaryComponentType/Reducer";
import { ComponentReducer } from "./Component/Reducer";
import { PayrollTemplateReducer } from "./PayRollTemplate/Reducer";
import { EmployeeTemplateReducer } from "./Employee/Reducer";
import { WageReportsReducer } from "./Reports/Reducer";


const FinanceReducer = combineReducers({
    TaxDeclarationReducer,
    EmployeeProfileReducer,
    SalaryStructureReducer,
    SalaryComponentReducer,
    SalaryComponentTypeReducer,
    ComponentReducer,
    PayrollTemplateReducer,
    EmployeeTemplateReducer,
    WageReportsReducer
});

export default FinanceReducer;