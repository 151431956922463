import { Menu, MenuItem, Typography, TextField, Box, Button } from "@mui/material";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { useEffect } from "react";
import { TimeOffHelper } from "../../../helper/TimeOff/TimeOffHelper";
import { ConvertDateTime } from "../../../../Base/helper/baseFunctions";
import Popup from "../../../../Base/views/Popup";
import { styled } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import { ApproveTimeOffAction } from "../../../../../../store/Leave/MyTeam/Action";


const useStyles = makeStyles({
  table: {
    minWidth: 650,
    "& .MuiTableCell-root": {
      border: "1px solid rgba(224, 224, 224, 1)",
      borderCollapse: "collapse",
    },
  },
});
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    padding:"10px",
    fontSize: '14px',
  },
  [`&.${tableCellClasses.body}`]: {
    padding:"10px", // Adding padding of 8 for body cells
  },
}));

const ApproveTimeOffGrid = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [approvalData, setApprovalData] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [showComments, setShowComments] = useState(false);
  const [comments, setComments] = useState("");
  const [data, setData] = useState({});
  const [currentData, setCurrentData] = useState({});
  const [success, setSuccess] = useState(false);
  const openMoreVertIcon = Boolean(anchorEl);
  const { GetSuccessCode } = useSelector(
    (state) => state?.LeaveReducer?.TimeSheetApprovalReducer
  );
  useEffect(() => {
    TimeOffHelper({
      approval_screen:true
    }).then((res) => {
      setApprovalData(res?.data?.data?.[0]?.leaverequest);
    });
    //eslint-disable-next-line
  }, []);

  const handleApprove = (status) => {
    setAnchorEl(null);
    setShowComments(true);
    setData({ flag: status, comments: comments, leaverequest_id: currentData?.leaverequest_id, dashboardData: currentData })
  };
  const handleReject = (status) => {
    setAnchorEl(null);
    setShowComments(true);
    setData({ flag: status, comments: comments, leaverequest_id: currentData?.leaverequest_id, dashboardData: currentData })
    };
  const handleMoreVertIconClose = () => {
    setAnchorEl(null);
  };
  const handleMoreVertIconClick = (event, row) => {
    setCurrentData(row)
    setAnchorEl(event.currentTarget);
};
const handleConfirm = () => {
  data["comments"] = comments;
  dispatch(ApproveTimeOffAction(data))
  setSuccess(true)

}
useEffect(() => {
  if (GetSuccessCode === 200 && success) {
    setShowComments(false);
    setComments("")
    TimeOffHelper({
      approval_screen:true
    }).then((res) => {
      setApprovalData(res?.data?.data?.[0]?.leaverequest);
    });
  }
  //eslint-disable-next-line
}, [GetSuccessCode, success]);
  return (
    <>
      <Paper sx={{ width: "100%" }}>
        <TableContainer sx={{ maxHeight: 440 }}>
          <Table className={classes.table}  stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                <StyledTableCell align="center" rowSpan={1}>
                  Employee Name
                </StyledTableCell>
                <StyledTableCell align="center" rowSpan={1}>
                  Leave Type
                </StyledTableCell>
                <StyledTableCell align="center" rowSpan={1}>
                  Start Date
                </StyledTableCell>
                <StyledTableCell align="center" rowSpan={1}>
                  End Date
                </StyledTableCell>
                <StyledTableCell align="center" rowSpan={1}>
                  Duration
                </StyledTableCell>
                <StyledTableCell align="center" rowSpan={1}>
                  Actions
                </StyledTableCell>
              </TableRow>
            </TableHead>
            {approvalData?.length === 0  || approvalData === null ? (
              <TableBody>
                <TableRow>
                  <StyledTableCell colSpan={6} align="center">
                  No Records found
                  </StyledTableCell>
                </TableRow>
              </TableBody>
            ) : (
              <TableBody>
                {approvalData?.map((row) => {
                  return (
                    <TableRow>
                      <StyledTableCell align="center">{row?.name}</StyledTableCell>
                      <StyledTableCell align="center">{row?.Leavetype}</StyledTableCell>
                      <StyledTableCell align="center">
                        {ConvertDateTime(row?.start)}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {ConvertDateTime(row?.end)}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {row?.leave_duration}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                      <MoreVertIcon sx={{ cursor:"pointer"}} onClick={(_event) => handleMoreVertIconClick(_event, row)} />
                      </StyledTableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            )}
          </Table>
        </TableContainer>
        <Menu
          id="more-options-menu"
          anchorEl={anchorEl}
          open={openMoreVertIcon}
          onClose={handleMoreVertIconClose}
        >
          <MenuItem key="approve" onClick={() => handleApprove("Approved")}>
            <DoneIcon color="success" /> &nbsp;{" "}
            <Typography sx={{ fontSize: "13px" }} variant="body2">
              Approve
            </Typography>
          </MenuItem>
          <MenuItem key="reject" onClick={() => handleReject("Rejected")}>
            <CloseIcon color="error" /> &nbsp;{" "}
            <Typography sx={{ fontSize: "13px" }}>Reject</Typography>
          </MenuItem>
        </Menu>
      </Paper>
      <Popup
        title="Comments"
        openPopup={showComments}
        setOpenPopup={setShowComments}
        fullWidth={true}
      >
        <TextField
          autoFocus={false}
          size="small"
          multiline
          rows={4}
          value={comments}
          onChange={(e) => setComments(e.target.value)}
          variant="outlined"
          fullWidth
          id="comments"
          label="Comments"
          name="comments"
        />
        <Box style={{ display: "flex", justifyContent: "flex-end" }}>
          <Button
            variant="contained"
            color="primary"
            type="submit"
            className="mpl-primary-btn"
            sx={{ marginTop: "1.75rem" }}
            onClick={handleConfirm}
          >
            Save
          </Button>
        </Box>
      </Popup>
    </>
  );
};

export default ApproveTimeOffGrid;
