import {
    Box,
    Button,
    Divider,
    InputBase,
    Typography,
} from "@mui/material";
import React, { useState } from "react";
import { Mention, MentionsInput } from "react-mentions"
import MentionInputStyles from "../../../ATS/views/ATSCandidate/MentionStyles/MentionInputStyles";
import { grey } from "@mui/material/colors";
import { useEffect } from "react";
import { GetAtsUsersEmailHelper } from "../../../ATS/helper/AtsUserHelper";
import { convertBase64 } from "../../../Base/helper/baseFunctions";
import { ShareAttachHelper } from "../../helper/AttachmentHelper";

const ReportEmailPopup = ({ setOpenPopup, employees_options, name, wage_report_data }) => {
    const initialValues = {
        subject: "Pay Summary Report",
        content: "Check Pay Summary Report"
    };
    const [showCc, setShowCc] = useState(false);
    const [mentionTo, setMentionTo] = useState("");
    const [mentionCc, setMentionCc] = useState("");
    const [mentionUsers, setMentionUsers] = useState([]);
    const [inputData, setInputData] = useState(initialValues);
    const [errors, setErrors] = useState({});

    const validate = (fieldValues) => {
        let temp = { ...errors };

        if ("mentionTo" in fieldValues) {
            temp.mentionTo =
                fieldValues.mentionTo?.trim() === "" ? "To Email is required" : "";
        }
        if ("subject" in fieldValues) {
            temp.subject =
                fieldValues.subject?.trim() === "" ? "Subject is required" : "";
        }
        setErrors({
            ...temp,
        });

        return Object.values(temp).every((x) => x === "");
    };
    useEffect(() => {
        GetAtsUsersEmailHelper().then((res) => {
            let arr = [];
            res?.data?.data?.forEach((temp) =>
                arr.push({ ...temp, id: temp?.email_id, display: temp?.email_id })
            );
            setMentionUsers(arr);
        });
        // eslint-disable-next-line
    }, []);

    const handleShowCc = () => {
        setMentionCc("");
        setShowCc(!showCc);
    };

    const handleInputChange = (event) => {
        setInputData({ ...inputData, [event?.target?.name]: event?.target?.value });
        Object.values?.(errors)?.find((res) => res !== "")?.length > 0 &&
            validate({ ...inputData, [event.target.name]: event.target.value });
    };

    const handleChange = (e, name) => {
        const inputValue = e.target.value;
        const endsWithSpace = inputValue.endsWith(" ");
        if (endsWithSpace) {
            const trimmedValue = inputValue.trim();
            const updatedValue = trimmedValue + ",";
            name === "mentionTo"
                ? setMentionTo(updatedValue)
                : setMentionCc(updatedValue);
        } else {
            name === "mentionTo"
                ? setMentionTo(inputValue)
                : setMentionCc(inputValue);
        }
    };
    const convertCSVToBase64 = async (csvData) => {
        const blob = new Blob([csvData], { type: "text/csv" });
        return await convertBase64(blob);
    };
    const generateCSV = (data, columns) => {
        const headers = columns
            .filter((column) => column.export)
            .map((column) => column.title);
        const rows = data.map((item) =>
            columns
                .filter((column) => column.export)
                .map((column) => {
                    const flattenedItem = flattenData(item);
                    return flattenedItem[column.field] || "";
                })
                .join(",")
        );
        return `${headers.join(",")}\n${rows.join("\n")}`;
    };
    const handleSend = () => {
        const processEmails = (emailString) => {
            const emailArray = emailString.split(",");
            const uniqueEmailSet = new Set(emailArray);
            const uniqueEmailArray = Array.from(uniqueEmailSet);
            return uniqueEmailArray.join(",");
        };
        const to = processEmails(mentionTo);
        const cc = processEmails(mentionCc);
        if (validate({ ...inputData, mentionTo })) {
            const attach = async () => {
                const csvData = generateCSV(wage_report_data, employees_options); // Pass your data here
                const base64Csv = await convertCSVToBase64(csvData);
                ShareAttachHelper({
                    ...inputData,
                    to: to,
                    cc: cc,
                    invoice_file:"",
                    file_name:"",
                    csv_file: base64Csv,
                    content: inputData?.content,
                    csv_file_name: `${name}.csv`,
                    message: "Pay Summary Report Sent Successfully",
                })
                setOpenPopup(false);
            };
            attach();
        }
    };
    const flattenData = (data) => {
        const flatObject = {};
        Object.keys(data).forEach((key) => {
            if (typeof data[key] === 'object' && data[key] !== null) {
                Object.keys(data[key]).forEach((nestedKey) => {
                    flatObject[nestedKey] = data[key][nestedKey];
                });
            } else {
                flatObject[key] = data[key];
            }
        });
        return flatObject;
    };
    const handleExportAll = () => {
        const headers = employees_options?.map((col) => ({
            label: col.title,
            key: col.field,
        }));

        const csvData = [headers.map((header) => header.label)];
        wage_report_data.forEach((row) => {
            const flattenedRow = flattenData(row);
            const rowData = headers.map((header) => flattenedRow[header.key] || "");
            csvData.push(rowData);
        });
        const csv = csvData.map((row) => row.join(",")).join("\n");
        const blob = new Blob([csv], { type: "text/csv" });
        const url = URL.createObjectURL(blob);
        const downloadLink = document.createElement("a");
        downloadLink.href = url;
        downloadLink.download = `${name}.csv`;
        downloadLink.setAttribute("target", "_blank");
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
        URL.revokeObjectURL(url);
    };
    return (
        <div>
            <Divider sx={{ borderStyle: "dashed" }} />
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                }}
            >
                <Box sx={{ width: "100%" }}>
                    <MentionsInput
                        fullWidth
                        style={MentionInputStyles}
                        value={mentionTo}
                        name="mentionTo"
                        onChange={(e, name) => handleChange(e, "mentionTo")}
                        placeholder={"TO"}
                    >
                        <Mention
                            markup="__id__,"
                            displayTransform={(email_id) => `${email_id}, `}
                            data={mentionUsers}
                            displayName="email_id"
                        />
                    </MentionsInput>
                </Box>
                <Typography variant="body1">
                    <Box
                        onClick={handleShowCc}
                        sx={{ cursor: "pointer" }}
                        component={"span"}
                    >
                        CC
                    </Box>
                </Typography>
            </Box>
            <Divider sx={{ borderStyle: "dashed" }} />
            <Typography color="error">{errors.mentionTo}</Typography>
            {showCc && (
                <>
                    <MentionsInput
                        fullWidth
                        style={MentionInputStyles}
                        value={mentionCc}
                        name="mentionCc"
                        onChange={(e, name) => handleChange(e, "mentionCc")}
                        placeholder={"CC"}
                    >
                        <Mention
                            markup="__id__,"
                            displayTransform={(email_id) => `${email_id}, `}
                            data={mentionUsers}
                            displayName="email_id"
                        />
                    </MentionsInput>
                    <Divider sx={{ borderStyle: "dashed" }} />
                </>
            )}
            <InputBase
                fullWidth
                name="subject"
                value={inputData?.subject}
                onChange={handleInputChange}
                sx={{ padding: "5px 0px" }}
                placeholder="Subject"
            />
            <Divider sx={{ borderStyle: "dashed" }} />
            <Typography color="error">{errors.subject}</Typography>
            <Divider sx={{ borderStyle: "dashed" }} />
            <InputBase
                fullWidth
                name="content"
                value={inputData?.content}
                onChange={handleInputChange}
                minRows={4}
                multiline
                sx={{ minHeight: "10px", adding: "5px 0px" }}
                placeholder="Content"
            />
            <Typography variant="h5">Attachment </Typography>
            <Box marginTop={2} display="flex" alignItems={"center"}>
                <Typography sx={{ fontWeight: "bold", textAlign: "left", ml: 2 }}>
                    Attachment :&nbsp;
                </Typography>
                <Typography
                    className="file-name"
                    sx={{ color: "#1155cc" }}
                    onClick={() => handleExportAll()}
                    style={{ cursor: "pointer" }}
                >
                    {`${name}.csv`}
                </Typography>
            </Box>

            <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                <Button
                    className="mpl-secondary-btn"
                    sx={{ ":hover": { color: grey[50] } }}
                    size="small"
                    variant="text"
                    onClick={() => setOpenPopup(false)}
                >
                    Cancel
                </Button>
                <Button
                    sx={{
                        ml: 1,
                        color: grey[50],
                        ":hover": { backgroundColor: "#f9c307", color: "#000000" },
                    }}
                    size="small"
                    variant="contained"
                    className="mpl-primary-btn"
                    onClick={handleSend}
                >
                    Send
                </Button>
            </Box>
        </div>
    );
};

export default ReportEmailPopup;
