import { BASE_URL } from "../../../../../Constants/baseConstant"

const formatDate = (date_data) =>{
    const date = new Date(date_data);
    const day = date.getDate();
    const year = date.getFullYear();
    
    // Define ordinal suffixes
    const suffix = (day % 10 === 1 && day !== 11) ? "st" :
                   (day % 10 === 2 && day !== 12) ? "nd" :
                   (day % 10 === 3 && day !== 13) ? "rd" : "th";
                   
    const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    const month = monthNames[date.getMonth()];

    return `${day}${suffix} ${month} ${year}`
}

export const RequestContent = ({ userName, managerName, fromDate, toDate, leaveType, reqDays, guid }) => (
    <table width="100%" border="0" align="center" cellPadding="0" cellSpacing="0"
        style={{ maxWidth: "1070px", background: "#fff", borderRadius: "3px", textAlign: "center", WebkitBoxShadow: "0 6px 18px 0 rgba(0,0,0,.06)", MozBoxShadow: "0 6px 18px 0 rgba(0,0,0,.06)", "boxShadow": "0 6px 18px 0 rgba(0,0,0,.06)" }}>
        <tr style={{ textAlign: "left" }}>
            <span style={{ color: "#455056", fontSize: "15px", lineHeight: "24px", margin: 0 }}>
                Dear {managerName},
            </span>
        </tr>
        <tr>
            <td style={{ height: "40px" }}>&nbsp;</td>
        </tr>
        <tr>
            <td style={{ padding:"0px 45px", textAlign:"start" }}>
                <span
                    style={{ display: "inlineBlock", verticalAlign: "middle", margin: "29px 0 26px", borderBottom: "1px solid #cecece", width: "100px" }}></span>
                <span style={{ color: "#455056", fontSize: "15px", lineHeight: "24px", margin: 0 }}>
                <span style={{ fontWeight: "bold" }}>{userName}</span> has requested { reqDays?(<> {reqDays} {reqDays === 1 ? "day" : "days"} of </>) : ""} <span style={{fontWeight:"bold"}}>{leaveType}</span> leave {reqDays === 1 ? (<> for <span style={{ fontWeight: "bold" }}>{formatDate(fromDate)}</span></>) : (<> from <span style={{ fontWeight: "bold" }}>{formatDate(fromDate)}</span> to <span style={{ fontWeight: "bold" }}>{formatDate(toDate)}</span></>)}.
                </span>
                <br />
                <span style={{ color: "#455056", fontSize: "15px", lineHeight: "24px", margin: 0 }}>
                    Please review and approve the request by clicking on the Approve button below.
                </span>
                <br />
                <br />
            </td>
        </tr>
        <tr>
            <td style={{ height: "20px", textAlign:"center"  }}>
                <a href={`${BASE_URL}/signup/`} style={{
                    background: "#20e277",
                    textDecoration: "none !important", fontWeight: 500,
                    color: "#fff", textTransform: "uppercase", fontSize: "14px",
                    padding: "10px 24px", borderRadius: "50px"
                }}>
                    Approve
                </a>
            </td>
        </tr>
        <tr>
            <td style={{ height: "20px" }}>&nbsp;</td>
        </tr>
    </table>
)

export const CancelRequestContent = ({ userName, managerName, fromDate, toDate, leaveType, reqDays }) => (
    <table width="100%" border="0" align="center" cellPadding="0" cellSpacing="0"
        style={{ maxWidth: "1070px", background: "#fff", borderRadius: "3px", textAlign: "center", WebkitBoxShadow: "0 6px 18px 0 rgba(0,0,0,.06)", MozBoxShadow: "0 6px 18px 0 rgba(0,0,0,.06)", "boxShadow": "0 6px 18px 0 rgba(0,0,0,.06)" }}>
        <tr style={{ textAlign: "left" }}>
            <span style={{ color: "#455056", fontSize: "15px", lineHeight: "24px", margin: 0 }}>
                Dear {managerName},
            </span>
        </tr>
        <tr>
            <td style={{ height: "40px" }}>&nbsp;</td>
        </tr>
        <tr>
            <td style={{ padding:"0px 45px", textAlign:"start" }}>
                <span
                    style={{ display: "inlineBlock", verticalAlign: "middle", margin: "29px 0 26px", borderBottom: "1px solid #cecece", width: "100px" }}></span>
                <span style={{ color: "#455056", fontSize: "15px", lineHeight: "24px", margin: 0 }}>
                   <span style={{ fontWeight: "bold" }}>{userName}</span> has requested to cancel his/her <span style={{fontWeight:"bold"}}>{leaveType}</span> previously scheduled {reqDays === 1 ? (<> for <span style={{ fontWeight: "bold" }}>{formatDate(fromDate)}</span></>) : (<> from <span style={{ fontWeight: "bold" }}>{formatDate(fromDate)}</span> to <span style={{ fontWeight: "bold" }}>{formatDate(toDate)}</span></>)}.
                </span>
                <br />
                <span style={{ color: "#455056", fontSize: "15px", lineHeight: "24px", margin: 0 }}>
                    Please review and confirm the cancellation by clicking on the Cancel button below.
                </span>
                <br />
                <br />
            </td>
        </tr>
        <tr>
            <td style={{ height: "20px", textAlign:"center"  }}>
                <a href={`${BASE_URL}/signup/`} style={{
                    background: "#20e277",
                    textDecoration: "none !important", fontWeight: 500,
                    color: "#fff", textTransform: "uppercase", fontSize: "14px",
                    padding: "10px 24px", borderRadius: "50px"
                }}>
                    Cancel
                </a>
            </td>
        </tr>
        <tr>
            <td style={{ height: "20px" }}>&nbsp;</td>
        </tr>
    </table>
)

export const ApprovedContent = ({ userName, fromDate, toDate, leaveType, reqDays }) => (
    <table width="100%" border="0" align="center" cellPadding="0" cellSpacing="0"
        style={{ maxWidth: "1070px", background: "#fff", borderRadius: "3px", textAlign: "center", WebkitBoxShadow: "0 6px 18px 0 rgba(0,0,0,.06)", MozBoxShadow: "0 6px 18px 0 rgba(0,0,0,.06)", "boxShadow": "0 6px 18px 0 rgba(0,0,0,.06)" }}>
        <tr style={{ textAlign: "left" }}>
            <span style={{ color: "#455056", fontSize: "15px", lineHeight: "24px", margin: 0 }}>
                Dear {userName},
            </span>
        </tr>
        <tr>
            <td style={{ height: "40px" }}>&nbsp;</td>
        </tr>
        <tr>
            <td style={{ padding: "0 35px" }}>
                <span
                    style={{ display: "inlineBlock", verticalAlign: "middle", margin: "29px 0 26px", borderBottom: "1px solid #cecece", width: "100px" }}></span>
                <span style={{ color: "#455056", fontSize: "15px", lineHeight: "24px", margin: 0 }}>
                    Your request for <span style={{fontWeight:"bold"}}>{leaveType}</span>{reqDays === 1 ? (<> for <span style={{ fontWeight: "bold" }}>{formatDate(fromDate)}</span></>) : (<> from <span style={{ fontWeight: "bold" }}>{formatDate(fromDate)}</span> to <span style={{ fontWeight: "bold" }}>{formatDate(toDate)}</span></>)} has been approved.
                </span>
                <br />
                <br />
            </td>
        </tr>
        <tr>
            <td style={{ height: "20px" }}>&nbsp;</td>
        </tr>
    </table>
)

export const RejectedContent = ({ userName, fromDate, toDate, leaveType, reqDays }) => (
    <table width="100%" border="0" align="center" cellPadding="0" cellSpacing="0"
        style={{ maxWidth: "1070px", background: "#fff", borderRadius: "3px", textAlign: "center", WebkitBoxShadow: "0 6px 18px 0 rgba(0,0,0,.06)", MozBoxShadow: "0 6px 18px 0 rgba(0,0,0,.06)", "boxShadow": "0 6px 18px 0 rgba(0,0,0,.06)" }}>
        <tr style={{ textAlign: "left" }}>
            <span style={{ color: "#455056", fontSize: "15px", lineHeight: "24px", margin: 0 }}>
                Dear {userName},
            </span>
        </tr>
        <tr>
            <td style={{ height: "40px" }}>&nbsp;</td>
        </tr>
        <tr>
            <td style={{ padding: "0 35px" }}>
                <span
                    style={{ display: "inlineBlock", verticalAlign: "middle", margin: "29px 0 26px", borderBottom: "1px solid #cecece", width: "100px" }}></span>
                <span style={{ color: "#455056", fontSize: "15px", lineHeight: "24px", margin: 0 }}>
                Your request for <span style={{fontWeight:"bold"}}>{leaveType}</span> {reqDays === 1 ? (<> for <span style={{ fontWeight: "bold" }}>{formatDate(fromDate)}</span></>) : (<> from <span style={{ fontWeight: "bold" }}>{formatDate(fromDate)}</span> to <span style={{ fontWeight: "bold" }}>{formatDate(toDate)}</span></>)} has been rejected.
                </span>
                <br />
                <br />
            </td>
        </tr>
        <tr>
            <td style={{ height: "20px" }}>&nbsp;</td>
        </tr>
    </table>
)
export const CancelledContent = ({ userName, fromDate, toDate, leaveType, reqDays }) => (
    <table width="100%" border="0" align="center" cellPadding="0" cellSpacing="0"
        style={{ maxWidth: "1070px", background: "#fff", borderRadius: "3px", textAlign: "center", WebkitBoxShadow: "0 6px 18px 0 rgba(0,0,0,.06)", MozBoxShadow: "0 6px 18px 0 rgba(0,0,0,.06)", "boxShadow": "0 6px 18px 0 rgba(0,0,0,.06)" }}>
        <tr style={{ textAlign: "left" }}>
            <span style={{ color: "#455056", fontSize: "15px", lineHeight: "24px", margin: 0 }}>
                Dear {userName},
            </span>
        </tr>
        <tr>
            <td style={{ height: "40px" }}>&nbsp;</td>
        </tr>
        <tr>
            <td style={{ padding: "0 35px" }}>
                <span
                    style={{ display: "inlineBlock", verticalAlign: "middle", margin: "29px 0 26px", borderBottom: "1px solid #cecece", width: "100px" }}></span>
                <span style={{ color: "#455056", fontSize: "15px", lineHeight: "24px", margin: 0 }}>
                Your <span style={{fontWeight:"bold"}}>{leaveType}</span> request{reqDays === 1 ? (<> for <span style={{ fontWeight: "bold" }}>{formatDate(fromDate)}</span></>) : (<> from <span style={{ fontWeight: "bold" }}>{formatDate(fromDate)}</span> to <span style={{ fontWeight: "bold" }}>{formatDate(toDate)}</span></>)} has been canceled.
                </span>
                <br />
                <br />
            </td>
        </tr>
        <tr>
            <td style={{ height: "20px" }}>&nbsp;</td>
        </tr>
    </table>
)


export const LeaveCancelContent = ({ userName, managerName, fromDate, toDate, leaveType, reqDays }) => (
    <table width="100%" border="0" align="center" cellPadding="0" cellSpacing="0"
        style={{ maxWidth: "1070px", background: "#fff", borderRadius: "3px", textAlign: "center", WebkitBoxShadow: "0 6px 18px 0 rgba(0,0,0,.06)", MozBoxShadow: "0 6px 18px 0 rgba(0,0,0,.06)", "boxShadow": "0 6px 18px 0 rgba(0,0,0,.06)" }}>
        <tr>
            <td style={{ height: "40px" }}>&nbsp;</td>
        </tr>
        <tr>
            <td style={{ padding:"0px 45px", textAlign:"start" }}>
                <span
                    style={{ display: "inlineBlock", verticalAlign: "middle", margin: "29px 0 26px", borderBottom: "1px solid #cecece", width: "100px" }}></span>
                <span style={{ color: "#455056", fontSize: "15px", lineHeight: "24px", margin: 0 }}>
                    This is to notify that request for <span style={{fontWeight:"bold"}}>{leaveType}</span>{reqDays === 1 ? (<> for <span style={{ fontWeight: "bold" }}>{formatDate(fromDate)}</span></>) : (<> from <span style={{ fontWeight: "bold" }}>{formatDate(fromDate)}</span> to <span style={{ fontWeight: "bold" }}>{formatDate(toDate)}</span></>)} has been cancelled successfully.
                </span>
                <br />
                <br />
            </td>
        </tr>
        <tr>
            <td style={{ height: "20px" }}>&nbsp;</td>
        </tr>
    </table>
)
