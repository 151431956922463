import axiosInstance from "../../../../../axiosInstance";
import { HRMS_FINANCE_API_URL } from "../../../../Constants/baseConstant";
import { getUserData } from "../../../Base/helper/baseFunctions";


export const GetEarningHelper = async (params) => {
    var data = getUserData();
    return await axiosInstance(`/finance/getEarning`, {
        method: "POST",
        baseURL: HRMS_FINANCE_API_URL,
        data: {
            domain_name: data.domain,
        }
    })
        .then(res => {
            return { message: "Successfully", hasError: false, data: res?.data }
        }).catch(err => {
            return { message: "Error", hasError: true, data: err?.response?.data }
        })
}

export const GetDeductionHelper = async (params) => {
    var data = getUserData();
    return await axiosInstance(`/finance/getDeduction`, {
        method: "POST",
        baseURL: HRMS_FINANCE_API_URL,
        data: {
            domain_name: data.domain,
        }
    })
        .then(res => {
            return { message: "Successfully", hasError: false, data: res?.data }
        }).catch(err => {
            return { message: "Error", hasError: true, data: err?.response?.data }
        })
}

