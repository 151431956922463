import { useEffect } from "react";
import MaterialTable from "material-table";
import {
  Box,
} from "@mui/material";
import { useSelector } from "react-redux";
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { useState } from "react";
import { useDispatch } from "react-redux";
import Base from "../../Base/views/Base";
import { GetEmployeesAction } from "../../../../store/Employee/Employee/Action";
import RoleandAccess from "./RoleandAccess/RoleandAccess";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { ListItemIcon, ListItemText, Menu, MenuItem } from '@mui/material';
import LockResetIcon from '@mui/icons-material/LockReset';
import ConfirmationDialog from "../../Base/views/ConfirmationDialog";
import { validateEmailHelper } from "../../SignIn/helper/SigninHelper";
import { getUserData } from "../../Base/helper/baseFunctions";


const MyEmployeeAccess= ({ }) => {
    const dispatch = useDispatch();
    const user = getUserData()
    const [currentMode, setCurrentMode] = useState("grid");
    const [currentData, setCurrentData] = useState("")
    const { employees_options } = useSelector(
        (state) => state?.EmployeeReducer.EmployeesReducer
      );
      const [anchorEl, setAnchorEl] = useState(null);
      const open = Boolean(anchorEl);
      const [showResetConfirm, setShowResetConfirm] = useState(false);

  useEffect(() => {
    dispatch(GetEmployeesAction({ is_active: true }));
  }, []);
 
  const hanldeEdit = (data) => {
    setCurrentMode("edit");
    setCurrentData(data);
  };
  const handleClick = (event, data) => {
    setCurrentData(data)
    setAnchorEl(event.currentTarget);
  };
  const handleResetPassword = () => {
    validateEmailHelper({ domain: user?.domain, email: currentData?.office_email, message: "Reset Link has been sent to the Email" })
    setShowResetConfirm(false);
  }
  return (
    <>
    <Base>
      <Box sx={{ maxHeight: "87vh", overflowY: "auto" }}>

      {
                    currentMode === "grid" ?
                    <>
      <MaterialTable
        onRowClick={(event, rowData) => hanldeEdit(rowData)}
          columns={[
            {
              title: "Name",
              cellStyle: { padding: "10px 10px 10px 13px" },
              field: "name",
              grouping: false,
            },
            {
              title: "Email",
              cellStyle: { padding: "10px 10px 10px 13px" },
              field: "office_email",
              grouping: false,
            },
            {
              title: "Designation",
              cellStyle: { padding: "10px 10px 10px 13px" },
              field: "designation",
            },
            {
              title: "User Type",
              cellStyle: { padding: "10px 10px 10px 13px" },
              field: "core_hr_user_type",
            },
            {
              title: "Core HR & LMS", field: "enable_corehr",headerStyle:{ textAlign:"center"}, cellStyle: { padding: "10px 10px 10px 13px", textAlign:"center" },
              render: rowData => {
                  return rowData.enable_corehr ? <TaskAltIcon sx={{ color: "rgb(86, 202, 0)" }} />
                      : <HighlightOffIcon sx={{ color: "rgb(255, 76, 81)" }} />
              }
          },
          {
            title: "ATS", field: "enable_ats", headerStyle:{ textAlign:"center"}, cellStyle: { padding: "10px 10px 10px 13px",textAlign:"center" },
            render: rowData => {
                return rowData.enable_ats ? <TaskAltIcon sx={{ color: "rgb(86, 202, 0)" }} />
                    : <HighlightOffIcon sx={{ color: "rgb(255, 76, 81)" }} />
            }
        },
        
          ]}
          data={employees_options}
          options={{
            maxBodyHeight: "65vh",
            minBodyHeight: "65vh",
            rowStyle: { fontSize: "13px" },
            headerStyle: { padding: "10px 10px 10px 13px", fontWeight: "bold" },
            sorting: true,
            search: true,
            searchText: "",
            searchFieldAlignment: "right",
            searchAutoFocus: true,
            searchFieldVariant: "standard",
            filtering: false,
            paging: true,
            pageSizeOptions: [25, 50],
            pageSize: 25,
            paginationType: "stepped",
            showFirstLastPageButtons: false,
            paginationPosition: "bottom",
            exportButton: false,
            exportAllData: false,
            exportFileName: "Employees",
            addRowPosition: "first",
            selection: false,
            actionsColumnIndex: -1,
            showSelectAllCheckbox: true,
            showTextRowsSelected: true,
            grouping: false,
            columnsButton: false,
          }}
          actions={[
            {
              icon: () => <MoreVertIcon fontSize='small' />,
              tooltip: "Settings",
              onClick: (_event, rowData) => { handleClick(_event, rowData) },
            },
          ]}
          title="Active Employees"
        />
          <Menu
            id="more-options-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={() => setAnchorEl(null)}
            MenuListProps={{
              'aria-labelledby': 'more-options-button',
            }}
            >
                <MenuItem onClick={() => { setShowResetConfirm(true); setAnchorEl(null); }}>
                    <ListItemIcon>
                        <LockResetIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText>Reset Password</ListItemText>
                </MenuItem>
          </Menu>

            <ConfirmationDialog
                title="Reset Password"
                content="Are you sure you want to sent reset password email ?"
                openDialog={showResetConfirm}
                closeDialog={setShowResetConfirm}
                popupTitle="Users"
                CancelButtonText="Cancel"
                OkButtonText="Send Email"
                onCancel={() => setShowResetConfirm(false)}
                onSubmit={() => handleResetPassword()}
            />
        </> : <>
        <RoleandAccess setCurrentMode={setCurrentMode} currentMode={currentMode} OnboardingEmpData={currentData} />
        </>
}
      </Box>
      </Base>
    </>
  );
};

export default MyEmployeeAccess;