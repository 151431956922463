import MaterialTable from 'material-table'
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import { useSelector } from 'react-redux';
import { Grid } from '@mui/material';
import { useDispatch } from 'react-redux';
import { GetAtsEmployeesAction } from '../../../../store/Employee/Employee/Action';
import { useEffect } from 'react';
import CommonDatePicker from '../../Base/views/CommonDatePicker';
import { useState } from 'react';
import SelectableSearch from '../../Base/views/SelectableSearch';
import { Avatar, Button, IconButton } from '@mui/material';
import { ConvertDateTime, getUserData } from '../../Base/helper/baseFunctions';
import { GetATSModuleFeaturesAction } from '../../../../store/RoleAccess/ATSModuleFeatures/Action';
import CustomToggle from '../../Base/views/CustomToggle';
import ReceiptIcon from '@mui/icons-material/Receipt';
import history from '../../../../history';
import { AddMenuHeadingAction } from '../../../../store/Menu/Action';

const EmployeeGrid = ({ setCurrentMode, setData, access, internal}) => {
    const user = getUserData();
    const isGroupOrgFlowTrue = user?.grouporg_flow === true;
    const dispatch = useDispatch();
    const { employees_options } = useSelector(state => state?.EmployeeReducer.EmployeesReducer);
    const { featuresData } = useSelector(state => state?.RoleAndAccessReducer?.ATSModuleFeaturesReducer)
    const [inputData, setInputData] = useState({ onboarded_from: "", modifiedon_from: "", modifiedon_to: "" });
    const [selectedRows, setSelectedRows] = useState([]);
    const [inactive, setInactive] = useState(internal ? false : true);
    const [filterOptions,] = useState({
        load: false, values: [
            { value: "This Month", label: "This Month" },
            { value: "Last Month", label: "Last Month" },
            { value: "Last Quater", label: "Last Quater" },
            { value: "Custom Range", label: "Custom Range" },
        ]
    })

    useEffect(() => {
        if (inactive === true) {
          dispatch(GetAtsEmployeesAction({ date: inputData?.onboarded_from?.value, from_date: inputData?.modifiedon_from, to_date: inputData?.modifiedon_to, is_active: "false" }));
        } else dispatch(GetAtsEmployeesAction({ date: inputData?.onboarded_from?.value, from_date: inputData?.modifiedon_from, to_date: inputData?.modifiedon_to, is_active: "true" }));
       
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [inactive, inputData, inputData?.onboarded_from?.value !== "Custom Range"]);

    useEffect(() => {
        dispatch(GetATSModuleFeaturesAction({ module_name: "ATS Employees Onboarding" }));
      }, []);
    const handleDisabled = () => {
        let value = featuresData?.find(item => item?.permission === "employees_onboard_export")
        return value ? true : false
    }

    const handleAdd = () => {
        setCurrentMode("add")
    }

    const handleEdit = (event, row) => {
        setCurrentMode("edit")
        setData(row)
    }

    function stringToColor(string) {
        let hash = 0;
        let i;

        /* eslint-disable no-bitwise */
        for (i = 0; i < string.length; i += 1) {
            hash = string.charCodeAt(i) + ((hash << 5) - hash);
        }
        let color = '#';
        for (i = 0; i < 3; i += 1) {
            const value = (hash >> (i * 8)) & 0xff;
            color += `00${value.toString(16)}`.slice(-2);
        }
        /* eslint-enable no-bitwise */
        return color;
    }

    function stringAvatar(name) {
        let c = name.trim()
        return {
            sx: {
                bgcolor: stringToColor(name), width: "30px", height: "30px", fontSize: "12px"
            },
            children: c.split(" ").length >= 2 ? `${c.split(" ")[0][0]} ${c.split(" ")[1][0]}` : `${c.split(" ")[0][0]}`,
        };
    }

    const handleInputChange = (e, value) => {
        if (e === "onboarded_from") {
            if (value) setInputData({ [e]: value })
            else setInputData({ ...inputData, [e]: "" })
        } else setInputData({ ...inputData, [e.target.name]: e.target.value, modifiedon_to: e.target.value })
    }

    const handleExportSelected = () => {
        const headers = [
            { label: "Name", key: "name" },
            { label: "Personal Mail", key: "personal_email" },
            { label: "Personal Mobile", key: "personal_mobile" },
            { label: "Designation", key: "designation" },
            { label: "Date of Birth", key: "date_of_birth" },
            { label: "Gender", key: "gender" },
            { label: "Marital Status", key: "marital_status" },
            { label: "Employee No", key: "employee_no" },
            { label: "Role", key: "role" },
            { label: "Work Location", key: "work_location" },
            { label: "Onboarded By", key: "createdby_name" },
            { label: "Onboarded on", key: "createdon" },
            { label: "Reporting Manager", key: "reporting_manager" },
            {label: "Company", key: "client_name"}
        ];

        const csvData = [headers.map(header => header.label)];
        selectedRows.forEach(row => {
            const rowData = headers.map(header => row[header.key]);
            csvData.push(rowData);
        });

        const csv = csvData.map(row => row.join(",")).join("\n");
        const blob = new Blob([csv], { type: "text/csv" });
        const url = URL.createObjectURL(blob);
        const downloadLink = document.createElement("a");
        downloadLink.href = url;
        downloadLink.download = "Employees.csv";
        downloadLink.setAttribute("target", "_blank");
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
        URL.revokeObjectURL(url);
    };

    const handleToggle = (e) => {
        setInactive(!inactive);
    };
    const  handleGenerateInvoice = (company_id) =>{
        sessionStorage.setItem("selectedMenu", "Invoice");
        dispatch(AddMenuHeadingAction({ heading: "Invoice" }));
        history.push({
          pathname: "/ats/invoice",
          state: { defaultValue: company_id },
        });
    }
    return (
        <>
            <Grid container sx={{ pb: 2, pt:2}} spacing={2}>
                <Grid item xs={12} md={3}>
                    <SelectableSearch
                        label="Onboarded Date"
                        value={inputData?.onboarded_from}
                        fieldLabel="label"
                        required={false}
                        variant={"contained"}
                        name="onboarded_from"
                        size="small"
                        data={filterOptions}
                        onChangeEvent={handleInputChange}
                    />
                </Grid>
                {
                    inputData?.onboarded_from?.value === "Custom Range" &&
                    <>
                        <Grid item xs={12} md={3}>
                            <CommonDatePicker
                                size="small"
                                name="dob"
                                label="Onboarded From"
                                pickerType="date"
                                onKeyPress={(e) => e.preventDefault()}
                                onKeyDown={(e) => e.preventDefault()}
                                pickerFormat="dd/MM/yyyy"
                                DefaultDTvalue={inputData?.modifiedon_from}
                                maxDate={new Date()}
                                handleChange={(value) =>
                                    handleInputChange({
                                        target: {
                                            name: "modifiedon_from",
                                            value: value
                                                ? `${value?.getFullYear() +
                                                "-" +
                                                ("0" + (value?.getMonth() + 1)).slice(-2) +
                                                "-" +
                                                ("0" + value?.getDate()).slice(-2)
                                                }`
                                                : "",
                                        },
                                    })
                                }
                            />
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <CommonDatePicker
                                size="small"
                                name="dob"
                                label="Onboarded To"
                                pickerType="date"
                                onKeyDown={(e) => e.preventDefault()}
                                onKeyPress={(e) => e.preventDefault()}
                                pickerFormat="dd/MM/yyyy"
                                DefaultDTvalue={inputData?.modifiedon_to}
                                maxDate={new Date()}
                                minDate={new Date(inputData?.modifiedon_from)}
                                handleChange={(value) =>
                                    handleInputChange({
                                        target: {
                                            name: "modifiedon_to",
                                            value: value
                                                ? `${value?.getFullYear() +
                                                "-" +
                                                ("0" + (value?.getMonth() + 1)).slice(-2) +
                                                "-" +
                                                ("0" + value?.getDate()).slice(-2)
                                                }`
                                                : "",
                                        },
                                    })
                                }
                            />
                        </Grid>
                    </>}
            </Grid>
            <MaterialTable
                onRowClick={(event, rowData) => { access?.[2] === "U" && handleEdit(event, rowData) }}
                columns={[
                    {
                        title: "Name", cellStyle: { padding: "10px 10px 10px 13px" }, field: "name", grouping: false, render: rowData => {
                            if (rowData?.image) {
                                return (
                                    <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                                        <Avatar sx={{ width: "30px", height: "30px" }} alt={rowData?.name} src={rowData?.image} />
                                        <span style={{ margin: "0px 0px 0px 16px" }}>{rowData?.name}</span>
                                    </div>
                                )
                            } else {
                                return (
                                    <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                                        <Avatar {...stringAvatar(rowData?.name)} />
                                        <span style={{ margin: "0px 0px 0px 16px" }}>{rowData?.name}</span>
                                    </div>

                                )
                            }
                        }
                    },
                    { title: "Email", cellStyle: { padding: "10px 10px 10px 13px" }, field: "personal_email", grouping: false },
                    { title: "Designation", cellStyle: { padding: "10px 10px 10px 13px" }, field: "designation" },
                    { title: "Onboarded By", cellStyle: { padding: "10px 10px 10px 13px" }, field: "createdby_name" },
                    {
                        title: "Onboarded On", cellStyle: { padding: "10px 10px 10px 13px" }, field: "createdon",
                        render: rowData => { return ConvertDateTime(rowData.createdon) }
                    },
                    { title: "Company", cellStyle: { padding: "10px 10px 10px 25px" }, field: "client_name" },
                    {
                        title: "Generate Invoice",
                        cellStyle: { padding: "10px 10px 10px 30px" },
                        sorting: false, 
                        grouping: false,
                        hidden: !inactive,
                        render: rowData => (
                          <div>
                            <IconButton
                                onClick={() => { handleGenerateInvoice(rowData?.client_id) } }
                            >
                                    <ReceiptIcon />                                      
                            </IconButton>
                          </div>
                        ),
                      },
                ]}
                data={employees_options}
                options={{
                    maxBodyHeight: "65vh",
                    minBodyHeight: "65vh",
                    rowStyle: { fontSize: "13px" },
                    headerStyle: { padding: "10px 10px 10px 13px", fontWeight: "bold" },
                    sorting: false,
                    search: true,
                    searchText: "",
                    searchFieldAlignment: "right",
                    searchAutoFocus: true,
                    searchFieldVariant: "standard",
                    filtering: false,
                    paging: true,
                    pageSizeOptions: [25, 50],
                    pageSize: 25,
                    paginationType: "stepped",
                    showFirstLastPageButtons: false,
                    paginationPosition: "bottom",
                    exportButton: false,
                    exportAllData: false,
                    exportFileName: "Employees",
                    addRowPosition: "first",
                    selection: true,
                    actionsColumnIndex: -1,
                    showSelectAllCheckbox: true,
                    showTextRowsSelected: true,
                    grouping: false,
                    columnsButton: false,
                }}

                icons={{
                    Add: () => <AddIcon />,
                    Delete: () => <DeleteIcon />
                }}
                actions={[
                     {
                        icon: () => (
                            <CustomToggle
                            isChecked={inactive}
                            setIsChecked={handleToggle}
                            handleToggleChange={handleToggle}
                            Yestext={isGroupOrgFlowTrue ? "Group Org" : "Client"}
                            NoText={"Internal"}
                            style={{
                              height: "32px",
                              padding: "0px",
                              width: "200px",
                              labelPadding: "4px",
                            }}
                          />
                        ),
                        position: "row",
                        isFreeAction: true,
                    },
                    access?.[0] === "C" &&
                    {
                        icon: 'add',
                        tooltip: 'Add',
                        isFreeAction: true,
                        onClick: (event) => { handleAdd() }
                    },
                    {
                        icon: () => <Button component="span" variant="contained" disabled={handleDisabled()} startIcon={<CloudDownloadIcon />} className="mpl-primary-btn">Export</Button>,
                        tooltip: 'Export Selected Rows',
                        onClick: () => handleExportSelected(),
                        disabled: handleDisabled(),
                        hidden: selectedRows.length === 0,
                    },
                ]}
                onSelectionChange={(rows) => setSelectedRows(rows)}
                title="Employees"
            />

        </>
    )
}

export default EmployeeGrid