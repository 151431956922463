import { Box, Paper } from "@mui/material"
import Base from "../../../Base/views/Base"
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab'
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import EmployeeFaceTime from "./EmployeeFaceTime";
import EmployeeTime from "./EmployeeTime";

const EmployeeTimeLandingPage = () => {
    const [value, setValue] = useState(0);
    const dispatch = useDispatch()

    useEffect(() => {
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    function TabPanel(props) {
        const { children, value, index, ...other } = props;

        return (
            <div
                role="tabpanel"
                hidden={value !== index}
                id={`simple-tabpanel-${index}`}
                aria-labelledby={`simple-tab-${index}`}
                {...other}
            >
                {value === index && (
                    <Paper sx={{ borderRadius: "10px", maxHeight: "87vh", overflowY: "auto" }}>
                        {children}
                    </Paper>
                )}
            </div>
        );
    }

    TabPanel.propTypes = {
        children: PropTypes.node,
        index: PropTypes.number.isRequired,
        value: PropTypes.number.isRequired,
    };

    function a11yProps(index) {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <Base>
            <Box sx={{ maxHeight: "87vh", overflowY: 'auto' }}>
                <Tabs value={value} onChange={handleChange} aria-label="basic tabs example" sx={{ mb: 1 }}>
                    <Tab label="Employee Time" {...a11yProps(0)} />
                    <Tab label="Employee Face Time" {...a11yProps(1)} />
                </Tabs>

                <TabPanel value={value} index={0}>
                    <EmployeeTime />
                </TabPanel>
                <TabPanel value={value} index={1}>
                    <EmployeeFaceTime />
                </TabPanel>
            </Box>
        </Base>
    )
}

export default EmployeeTimeLandingPage;