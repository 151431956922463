import React, { useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';
import Routes from './Routes';
import history from './history';
import rootStore from './store/rootStore';
import { CookiesProvider } from 'react-cookie';
import "nprogress/nprogress.css";
import './css/appCss.css';
import { GenerateHrmsBearerToken, getCountry } from './app/Components/Base/helper/authHelper';
import { BASE_URL } from './app/Constants/baseConstant';
import { addSessionData } from './app/Components/Base/helper/baseHelper';

const store = rootStore();
const root = ReactDOM.createRoot(document.getElementById('root'));

function App() {
  useEffect(() => {
    async function redirectBasedOnCountry() {
      const country = await getCountry();
      const currentUrl = window.location.href;
      const targetUrl = `${BASE_URL}/au`;
      addSessionData({ key: "CountryId", value: country?.data?.country?.country });
      if (country?.data?.country?.country === 'AU' && currentUrl !== targetUrl) {
        window.location.href = targetUrl;
      }
    }
    redirectBasedOnCountry();
    GenerateHrmsBearerToken();
  }, []);

  return (
    <CookiesProvider>
      <Router history={history}>
        <Provider store={store}>
          <Routes />
        </Provider>
      </Router>
    </CookiesProvider>
  );
}

root.render(<App />);