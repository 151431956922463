/* Leave Type */
export const LEAVE_TYPE_GET_REQUEST = "GET_LEAVE_TYPE_REQUEST";
export const LEAVE_TYPE_GET_SUCCESS = "GET_LEAVE_TYPE_SUCCESS";
export const LEAVE_TYPE_GET_ERROR = "GET_LEAVE_TYPE_ERROR";

export const LEAVE_TYPE_ADD_REQUEST = "ADD_LEAVE_TYPE_REQUEST";
export const LEAVE_TYPE_ADD_SUCCESS = "ADD_LEAVE_TYPE_SUCCESS";
export const LEAVE_TYPE_ADD_ERROR = "ADD_LEAVE_TYPE_ERROR";

export const LEAVE_TYPE_UPDATE_REQUEST = "UPDATE_LEAVE_TYPE_REQUEST";
export const LEAVE_TYPE_UPDATE_SUCCESS = "UPDATE_LEAVE_TYPE_SUCCESS";
export const LEAVE_TYPE_UPDATE_ERROR = "UPDATE_LEAVE_TYPE_ERROR";

export const LEAVE_TYPE_DELETE_REQUEST = "DELETE_LEAVE_TYPE_REQUEST";
export const LEAVE_TYPE_DELETE_SUCCESS = "DELETE_LEAVE_TYPE_SUCCESS";
export const LEAVE_TYPE_DELETE_ERROR = "DELETE_LEAVE_TYPE_ERROR";

/* Leave Request */
export const LEAVE_REQUEST_GET_REQUEST = "GET_LEAVE_REQUEST_REQUEST";
export const LEAVE_REQUEST_GET_SUCCESS = "GET_LEAVE_REQUEST_SUCCESS";
export const LEAVE_REQUEST_GET_ERROR = "GET_LEAVE_REQUEST_ERROR";

export const LEAVE_REQUEST_ADD_REQUEST = "ADD_LEAVE_REQUEST_REQUEST";
export const LEAVE_REQUEST_ADD_SUCCESS = "ADD_LEAVE_REQUEST_SUCCESS";
export const LEAVE_REQUEST_ADD_ERROR = "ADD_LEAVE_REQUEST_ERROR";

export const LEAVE_REQUEST_UPDATE_REQUEST = "UPDATE_LEAVE_REQUEST_REQUEST";
export const LEAVE_REQUEST_UPDATE_SUCCESS = "UPDATE_LEAVE_REQUEST_SUCCESS";
export const LEAVE_REQUEST_UPDATE_ERROR = "UPDATE_LEAVE_REQUEST_ERROR";

export const LEAVE_REQUEST_DELETE_REQUEST = "DELETE_LEAVE_REQUEST_REQUEST";
export const LEAVE_REQUEST_DELETE_SUCCESS = "DELETE_LEAVE_REQUEST_SUCCESS";
export const LEAVE_REQUEST_DELETE_ERROR = "DELETE_LEAVE_REQUEST_ERROR";

/* Leave History */
export const LEAVE_HISTORY_GET_REQUEST = "GET_LEAVE_HISTORY_REQUEST";
export const LEAVE_HISTORY_GET_SUCCESS = "GET_LEAVE_HISTORY_SUCCESS";
export const LEAVE_HISTORY_GET_ERROR = "GET_LEAVE_HISTORY_ERROR";

/* Leave Status Update */
export const LEAVE_STATUS_UPDATE_REQUEST = "LEAVE_STATUS_UPDATE_REQUEST";
export const LEAVE_STATUS_UPDATE_SUCCESS = "LEAVE_STATUS_UPDATE_SUCCESS";
export const LEAVE_STATUS_UPDATE_ERROR = "LEAVE_STATUS_UPDATE_ERROR";

/* Overall Leave Balance */
export const LEAVE_OVERALL_BALANCE_GET_REQUEST = "GET_LEAVE_OVERALL_BALANCE_REQUEST";
export const LEAVE_OVERALL_BALANCE_GET_SUCCESS = "GET_LEAVE_OVERALL_BALANCE_SUCCESS";
export const LEAVE_OVERALL_BALANCE_GET_ERROR = "GET_LEAVE_OVERALL_BALANCE_ERROR";

/* Pending Leave Approval */
export const PENDING_LEAVE_APPROVAL_GET_REQUEST = "GET_PENDING_LEAVE_APPROVAL_REQUEST";
export const PENDING_LEAVE_APPROVAL_GET_SUCCESS = "GET_PENDING_LEAVE_APPROVAL_SUCCESS";
export const PENDING_LEAVE_APPROVAL_GET_ERROR = "GET_PENDING_LEAVE_APPROVAL_ERROR";

/* Holiday Calendar  */
export const UPCOMING_HOLIDAYS_GET_REQUEST = "GET_UPCOMING_HOLIDAYS_REQUEST";
export const UPCOMING_HOLIDAYS_GET_SUCCESS = "GET_UPCOMING_HOLIDAYS_SUCCESS";
export const UPCOMING_HOLIDAYS_GET_ERROR = "GET_UPCOMING_HOLIDAYS_ERROR";

export const HOLIDAY_CALENDAR_GET_REQUEST = "GET_HOLIDAY_CALENDAR_REQUEST";
export const HOLIDAY_CALENDAR_GET_SUCCESS = "GET_HOLIDAY_CALENDAR_SUCCESS";
export const HOLIDAY_CALENDAR_GET_ERROR = "GET_HOLIDAY_CALENDAR_ERROR";

export const HOLIDAY_CALENDAR_ADD_REQUEST = "ADD_HOLIDAY_CALENDAR_REQUEST";
export const HOLIDAY_CALENDAR_ADD_SUCCESS = "ADD_HOLIDAY_CALENDAR_SUCCESS";
export const HOLIDAY_CALENDAR_ADD_ERROR = "ADD_HOLIDAY_CALENDAR_ERROR";

export const HOLIDAY_CALENDAR_UPDATE_REQUEST = "UPDATE_HOLIDAY_CALENDAR_REQUEST";
export const HOLIDAY_CALENDAR_UPDATE_SUCCESS = "UPDATE_HOLIDAY_CALENDAR_SUCCESS";
export const HOLIDAY_CALENDAR_UPDATE_ERROR = "UPDATE_HOLIDAY_CALENDAR_ERROR";

export const HOLIDAY_CALENDAR_DELETE_REQUEST = "DELETE_HOLIDAY_CALENDAR_REQUEST";
export const HOLIDAY_CALENDAR_DELETE_SUCCESS = "DELETE_HOLIDAY_CALENDAR_SUCCESS";
export const HOLIDAY_CALENDAR_DELETE_ERROR = "DELETE_HOLIDAY_CALENDAR_ERROR";
/* Leave Summary */
export const LEAVE_SUMMARY_GET_REQUEST = "GET_LEAVE_SUMMARY_REQUEST";
export const LEAVE_SUMMARY_GET_SUCCESS = "GET_LEAVE_SUMMARY_SUCCESS";
export const LEAVE_SUMMARY_GET_ERROR = "GET_LEAVE_SUMMARY_ERROR";

/* Leave Reports */
export const LEAVE_REPORT_GET_REQUEST = "GET_LEAVE_REPORT_REQUEST";
export const LEAVE_REPORT_GET_SUCCESS = "GET_LEAVE_REPORT_SUCCESS";
export const LEAVE_REPORT_GET_ERROR = "GET_LEAVE_REPORT_ERROR";

/*Bookings Report */

export const BOOKINGS_LEAVE_GET_REQUEST = "GET_BOOKINGS_LEAVE_REQUEST"
export const BOOKINGS_LEAVE_GET_SUCCESS = "GET_BOOKINGS_LEAVE_SUCCESS"
export const BOOKINGS_LEAVE_GET_ERROR = "GET_BOOKINGS_LEAVE_ERROR"

/* Leave Ledger */
export const LEAVE_LEDGER_GET_REQUEST = "GET_LEAVE_LEDGER_REQUEST";
export const LEAVE_LEDGER_GET_SUCCESS = "GET_LEAVE_LEDGER_SUCCESS";
export const LEAVE_LEDGER_GET_ERROR = "GET_LEAVE_LEDGER_ERROR";

/* Manage Leave */
export const LEAVE_APPROVAL_GET_REQUEST = "GET_LEAVE_APPROVAL_REQUEST";
export const LEAVE_APPROVAL_GET_SUCCESS = "GET_LEAVE_APPROVAL_SUCCESS";
export const LEAVE_APPROVAL_GET_ERROR = "GET_LEAVE_APPROVAL_ERROR";

/* Yearly Holiday Calendar */
export const YEARLY_HOLIDAY_CALENDAR_GET_REQUEST = "YEARLY_HOLIDAY_CALENDAR_GET_REQUEST";
export const YEARLY_HOLIDAY_CALENDAR_GET_SUCCESS = "YEARLY_HOLIDAY_CALENDAR_GET_SUCCESS";
export const YEARLY_HOLIDAY_CALENDAR_GET_ERROR = "YEARLY_HOLIDAY_CALENDAR_GET_ERROR";

export const YEARLY_HOLIDAY_CALENDAR_ADD_REQUEST = "YEARLY_HOLIDAY_CALENDAR_ADD_REQUEST";
export const YEARLY_HOLIDAY_CALENDAR_ADD_SUCCESS = "YEARLY_HOLIDAY_CALENDAR_ADD_SUCCESS";
export const YEARLY_HOLIDAY_CALENDAR_ADD_ERROR = "YEARLY_HOLIDAY_CALENDAR_ADD_ERROR";

export const YEARLY_HOLIDAY_CALENDAR_UPDATE_REQUEST = "YEARLY_HOLIDAY_CALENDAR_UPDATE_REQUEST";
export const YEARLY_HOLIDAY_CALENDAR_UPDATE_SUCCESS = "YEARLY_HOLIDAY_CALENDAR_UPDATE_SUCCESS";
export const YEARLY_HOLIDAY_CALENDAR_UPDATE_ERROR = "YEARLY_HOLIDAY_CALENDAR_UPDATE_ERROR";

export const YEARLY_HOLIDAY_CALENDAR_DELETE_REQUEST = "YEARLY_HOLIDAY_CALENDAR_DELETE_REQUEST";
export const YEARLY_HOLIDAY_CALENDAR_DELETE_SUCCESS = "YEARLY_HOLIDAY_CALENDAR_DELETE_SUCCESS";
export const YEARLY_HOLIDAY_CALENDAR_DELETE_ERROR = "YEARLY_HOLIDAY_CALENDAR_DELETE_ERROR";

/* Leave Reason */
export const LEAVE_REASON_GET_REQUEST = "GET_LEAVE_REASON_REQUEST";
export const LEAVE_REASON_GET_SUCCESS = "GET_LEAVE_REASON_SUCCESS";
export const LEAVE_REASON_GET_ERROR = "GET_LEAVE_REASON_ERROR";

export const LEAVE_REASON_ADD_REQUEST = "ADD_LEAVE_REASON_REQUEST";
export const LEAVE_REASON_ADD_SUCCESS = "ADD_LEAVE_REASON_SUCCESS";
export const LEAVE_REASON_ADD_ERROR = "ADD_LEAVE_REASON_ERROR";

export const LEAVE_REASON_UPDATE_REQUEST = "UPDATE_LEAVE_REASON_REQUEST";
export const LEAVE_REASON_UPDATE_SUCCESS = "UPDATE_LEAVE_REASON_SUCCESS";
export const LEAVE_REASON_UPDATE_ERROR = "UPDATE_LEAVE_REASON_ERROR";

export const LEAVE_REASON_DELETE_REQUEST = "DELETE_LEAVE_REASON_REQUEST";
export const LEAVE_REASON_DELETE_SUCCESS = "DELETE_LEAVE_REASON_SUCCESS";
export const LEAVE_REASON_DELETE_ERROR = "DELETE_LEAVE_REASON_ERROR";

//LEAVE YEAR TYPE
export const LEAVE_YEAR_TYPE_GET_REQUEST = "LEAVE_YEAR_TYPE_GET_REQUEST";
export const LEAVE_YEAR_TYPE_GET_SUCCESS = "LEAVE_YEAR_TYPE_GET_SUCCESS";
export const LEAVE_YEAR_TYPE_GET_ERROR = "LEAVE_YEAR_TYPE_GET_ERROR";

export const LEAVE_YEAR_TYPE_UPDATE_REQUEST = "LEAVE_YEAR_TYPE_UPDATE_REQUEST";
export const LEAVE_YEAR_TYPE_UPDATE_SUCCESS = "LEAVE_YEAR_TYPE_UPDATE_SUCCESS";
export const LEAVE_YEAR_TYPE_UPDATE_ERROR = "LEAVE_YEAR_TYPE_UPDATE_ERROR";



/* My Time Configure */
export const MY_TIME_CONFIGURE_GET_REQUEST = "MY_TIME_CONFIGURE_GET_REQUEST";
export const MY_TIME_CONFIGURE_GET_SUCCESS = "MY_TIME_CONFIGURE_GET_SUCCESS";
export const MY_TIME_CONFIGURE_GET_ERROR = "MY_TIME_CONFIGURE_GET_ERROR";

export const MY_TIME_CONFIGURE_ADD_REQUEST = "MY_TIME_CONFIGURE_ADD_REQUEST";
export const MY_TIME_CONFIGURE_ADD_SUCCESS = "MY_TIME_CONFIGURE_ADD_SUCCESS";
export const MY_TIME_CONFIGURE_ADD_ERROR = "MY_TIME_CONFIGURE_ADD_ERROR";

export const MY_TIME_CONFIGURE_UPDATE_REQUEST = "MY_TIME_CONFIGURE_UPDATE_REQUEST";
export const MY_TIME_CONFIGURE_UPDATE_SUCCESS = "MY_TIME_CONFIGURE_UPDATE_SUCCESS";
export const MY_TIME_CONFIGURE_UPDATE_ERROR = "MY_TIME_CONFIGURE_UPDATE_ERROR";

export const MY_TIME_CONFIGURE_DELETE_REQUEST = "MY_TIME_CONFIGURE_DELETE_REQUEST";
export const MY_TIME_CONFIGURE_DELETE_SUCCESS = "MY_TIME_CONFIGURE_DELETE_SUCCESS";
export const MY_TIME_CONFIGURE_DELETE_ERROR = "MY_TIME_CONFIGURE_DELETE_ERROR";

export const CURRENT_TIME_GET_REQUEST = "CURRENT_TIME_GET_REQUEST";
export const CURRENT_TIME_GET_SUCCESS = "CURRENT_TIME_GET_SUCCESS";
export const CURRENT_TIME_GET_ERROR = "CURRENT_TIME_GET_ERROR";




/* My Time Sheet */
export const MY_TIME_SHEET_GET_REQUEST = "MY_TIME_SHEET_GET_REQUEST";
export const MY_TIME_SHEET_GET_SUCCESS = "MY_TIME_SHEET_GET_SUCCESS";
export const MY_TIME_SHEET_GET_ERROR = "MY_TIME_SHEET_GET_ERROR";

export const MY_TIME_SHEET_ADD_REQUEST = "MY_TIME_SHEET_ADD_REQUEST";
export const MY_TIME_SHEET_ADD_SUCCESS = "MY_TIME_SHEET_ADD_SUCCESS";
export const MY_TIME_SHEET_ADD_ERROR = "MY_TIME_SHEET_ADD_ERROR";

export const MY_TIME_SHEET_UPDATE_REQUEST = "MY_TIME_SHEET_UPDATE_REQUEST";
export const MY_TIME_SHEET_UPDATE_SUCCESS = "MY_TIME_SHEET_UPDATE_SUCCESS";
export const MY_TIME_SHEET_UPDATE_ERROR = "MY_TIME_SHEET_UPDATE_ERROR";

export const MY_TIME_SHEET_DELETE_REQUEST = "MY_TIME_SHEET_DELETE_REQUEST";
export const MY_TIME_SHEET_DELETE_SUCCESS = "MY_TIME_SHEET_DELETE_SUCCESS";
export const MY_TIME_SHEET_DELETE_ERROR = "MY_TIME_SHEET_DELETE_ERROR";

export const MY_TIME_SHEET_LEAVE_INFO_GET_REQUEST = "MY_TIME_SHEET_LEAVE_INFO_GET_REQUEST";
export const MY_TIME_SHEET_LEAVE_INFO_GET_SUCCESS = "MY_TIME_SHEET_LEAVE_INFO_GET_SUCCESS";
export const MY_TIME_SHEET_LEAVE_INFO_GET_ERROR = "MY_TIME_SHEET_LEAVE_INFO_GET_ERROR";

export const GET_FACE_DATA_REQUEST = "GET_FACE_DATA_REQUEST";
export const GET_FACE_DATA_SUCCESS = "GET_FACE_DATA_SUCCESS";
export const GET_FACE_DATA_ERROR = "GET_FACE_DATA_ERROR";

/* My Time Sheet APPROVAL */
export const TIME_SHEET_APPROVAL_GET_REQUEST = "TIME_SHEET_APPROVAL_GET_REQUEST";
export const TIME_SHEET_APPROVAL_GET_SUCCESS = "TIME_SHEET_APPROVAL_GET_SUCCESS";
export const TIME_SHEET_APPROVAL_GET_ERROR = "TIME_SHEET_APPROVAL_GET_ERROR";

export const APPROVE_TIME_SHEET_REQUEST = "APPROVE_TIME_SHEET_REQUEST";
export const APPROVE_TIME_SHEET_SUCCESS = "APPROVE_TIME_SHEET_SUCCESS";
export const APPROVE_TIME_SHEET_ERROR = "APPROVE_TIME_SHEET_ERROR";


export const APPROVE_TIME_OFF_REQUEST = "APPROVE_TIME_OFF_REQUEST";
export const APPROVE_TIME_OFF_SUCCESS = "APPROVE_TIME_OFF_SUCCESS";
export const APPROVE_TIME_OFF_ERROR = "APPROVE_TIME_OFF_ERROR";

/* Week Off Configure */
export const WEEK_OFF_CONFIGURE_GET_REQUEST = "WEEK_OFF_CONFIGURE_GET_REQUEST";
export const WEEK_OFF_CONFIGURE_GET_SUCCESS = "WEEK_OFF_CONFIGURE_GET_SUCCESS";
export const WEEK_OFF_CONFIGURE_GET_ERROR = "WEEK_OFF_CONFIGURE_GET_ERROR";

export const WEEK_OFF_CONFIGURE_ADD_REQUEST = "WEEK_OFF_CONFIGURE_ADD_REQUEST";
export const WEEK_OFF_CONFIGURE_ADD_SUCCESS = "WEEK_OFF_CONFIGURE_ADD_SUCCESS";
export const WEEK_OFF_CONFIGURE_ADD_ERROR = "WEEK_OFF_CONFIGURE_ADD_ERROR";

export const WEEK_OFF_CONFIGURE_UPDATE_REQUEST = "WEEK_OFF_CONFIGURE_UPDATE_REQUEST";
export const WEEK_OFF_CONFIGURE_UPDATE_SUCCESS = "WEEK_OFF_CONFIGURE_UPDATE_SUCCESS";
export const WEEK_OFF_CONFIGURE_UPDATE_ERROR = "WEEK_OFF_CONFIGURE_UPDATE_ERROR";

//Team Balance
export const TEAM_BALANCE_GET_REQUEST = "TEAM_BALANCE_GET_REQUEST";
export const TEAM_BALANCE_GET_SUCCESS = "TEAM_BALANCE_GET_SUCCESS";
export const TEAM_BALANCE_GET_ERROR = "TEAM_BALANCE_GET_ERROR";

export const REPORTING_EMPLOYEE_GET_REQUEST = "REPORTING_EMPLOYEE_GET_REQUEST";
export const REPORTING_EMPLOYEE_GET_SUCCESS = "REPORTING_EMPLOYEE_GET_SUCCESS";
export const REPORTING_EMPLOYEE_GET_ERROR = "REPORTING_EMPLOYEE_GET_ERROR";

export const WORK_HOURS_GET_REQUEST = "WORK_HOURS_GET_REQUEST";
export const WORK_HOURS_GET_SUCCESS = "WORK_HOURS_GET_SUCCESS";
export const WORK_HOURS_GET_ERROR = "WORK_HOURS_GET_ERROR";

export const WORK_HOURS_UPDATE_REQUEST = "WORK_HOURS_UPDATE_REQUEST";
export const WORK_HOURS_UPDATE_SUCCESS = "WORK_HOURS_UPDATE_SUCCESS";
export const WORK_HOURS_UPDATE_ERROR = "WORK_HOURS_UPDATE_ERROR";

