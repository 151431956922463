
import { Backdrop, Box, Button, CircularProgress, TextField, Typography } from "@mui/material";
import React, { useState } from "react";
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import ArrowBackButton from "../../../../Base/views/ArrowBackButton";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

import Paper from "@mui/material/Paper";
import { makeStyles } from "@mui/styles";
import Popup from "../../../../Base/views/Popup";
import {
  ApproveTimeSheetAction,
  GetTimeSheetApprovalAction,
} from "../../../../../../store/Leave/MyTeam/Action";
import { ConvertDateTime } from "../../../../Base/helper/baseFunctions";
import { GetWeekdatesHelper } from "../../../helper/TimeSheet/MyTimeSheetHelper";

const useStyles = makeStyles({
  table: {
    minWidth: 650,
    "& .MuiTableCell-root": {
      border: "1px solid rgba(224, 224, 224, 1)",
      borderCollapse: "collapse",
    },
  },
});

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
  "&.total": {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
  },
  "& .MuiTableCell-root": {
    border: "1px solid rgba(224, 224, 224, 1)",
    borderCollapse: "collapse",
  },
}));

const headers = [
  "Project Task",
  "Mon",
  "Tue",
  "Wed",
  "Thu",
  "Fri",
  "Sat",
  "Sun",
  "Total",
];
const dayMap = {
  Sun: "sun",
  Mon: "mon",
  Tue: "tue",
  Wed: "wed",
  Thu: "thu",
  Fri: "fri",
  Sat: "sat",
};
const TeamApprove = ({ setFormMode, currentData }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [showComments, setShowComments] = useState(false);
  const [comments, setComments] = useState("");
  const [success, setSuccess] = useState(false);
  const [data, setData] = useState({});
  const [date, setDate] = useState();
  const [isGetLoading, SetisGetLoading] = useState(true);
  const { GetSuccess } = useSelector(
    (state) => state?.LeaveReducer?.TimeSheetApprovalReducer
  );
  const handleApprove = (status) => {
    setShowComments(true);
    setData({
      flag: status,
      week_no: currentData?.week_no_pk,
      weekly_working_days: JSON.stringify(currentData?.weekly_hour),
      timesheet_id: currentData?.timesheet_pk,
      comments: comments,
      employee_id: currentData?.employee_pk
    });
  };
  const handleReject = (status) => {
    setShowComments(true);
    setData({
      flag: status,
      week_no: currentData?.week_no_pk,
      weekly_working_days: JSON.stringify(currentData?.weekly_hour),
      timesheet_id: currentData?.timesheet_pk,
      comments: comments,
      employee_id: currentData?.employee_pk
    });
  };
  const handleReOpen = (status) => {
    setShowComments(true);
    setData({
      flag: status,
      week_no: currentData?.week_no_pk,
      weekly_working_days: JSON.stringify(currentData?.weekly_hour),
      timesheet_id: currentData?.timesheet_pk,
      comments: comments,
      employee_id: currentData?.employee_pk
    });
  };

  const handleConfirm = () => {
    data["comments"] = comments;
    dispatch(ApproveTimeSheetAction(data));
    setSuccess(true);
  };

  useEffect(()=>{
    const start_date = new Date(currentData?.start_date);
    const start_date_day = start_date.getDate();
    const start_date_month = start_date.getMonth() + 1;
    const start_date_year = start_date.getFullYear();
    const end_date = new Date(currentData?.end_date);
    const end_date_day = end_date.getDate();
    const end_date_month = end_date.getMonth() + 1;
    const end_date_year = end_date.getFullYear();

    GetWeekdatesHelper({
      start_date_day: start_date_day,
      start_date_month: start_date_month,
      start_date_year: start_date_year,
      end_date_day: end_date_day,
      end_date_month: end_date_month,
      end_date_year: end_date_year,
    }).then((res) => {
      setDate(res?.data?.data?.[0]?.fn_map_days_to_dates);
      SetisGetLoading(false);
    });

  },[currentData])

  useEffect(() => {
    if (GetSuccess === 200 && success) {
      setShowComments(false);
      setComments("");
      setFormMode("card");

      dispatch(GetTimeSheetApprovalAction({ status: "Submitted" }));
    }
    //eslint-disable-next-line
  }, [GetSuccess, success]);
  const getEventColor = (status) => {
    switch (status) {
      case "Approved":
        return "#20e277";
      case "Submitted":
        return "#20e277";
      case "Rejected":
        return "#e68d45";
      case "Reopened":
        return "#fbeae8";
      default:
        return "#94b2d1";
    }
  };
  return (
    <>
      {isGetLoading && (
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={true}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
      <Box
        sx={{
          padding: "30px 0px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "start",
            "@media (max-width: 600px)": {
              flexDirection: "column",
              justifyContent: "start",
              alignItems: "start",
            },
          }}
        >
          <ArrowBackButton
            onClick={() => {
              setFormMode("card");
            }}
          />
          <Typography sx={{ padding: "10px" }} variant="h4">
            {currentData?.employee_name}
          </Typography>
          <Typography sx={{ padding: "10px" }} variant="h4">
            {currentData?.start_date} - {currentData?.end_date}
          </Typography>
        </Box>
        <Box></Box>
        <Box
          sx={{
            background: getEventColor(currentData?.flag),
            fontWeight: 500,
            color: "#000",
            fontSize: "12px",
            padding: "8px 10px",
            borderRadius: "50px",
            width: "fit-content",
            marginTop: "10px",
          }}
        >
          {currentData?.flag}
        </Box>
        <Box sx={{ marginTop: "20px" }}>
          <Button
            sx={{
              fontWeight: 700,
              borderRadius: "8px",
              fontSize: "13px",
              height: "30px",
              padding: "4px 10px",
              boxShadow: "none",
              lineHeight: 1.71429,
              marginRight: "10px",
            }}
            size="small"
            variant="contained"
            className="mpl-primary-btn"
            onClick={() => handleApprove("Approved")}
            disabled={
              currentData?.flag === "Approved" || currentData?.flag === "Rejected"
            }
          >
            Approve
          </Button>
          <Button
            sx={{
              fontWeight: 700,
              borderRadius: "8px",
              fontSize: "13px",
              height: "30px",
              marginRight: "10px",
              padding: "4px 10px",
              boxShadow: "none",
              lineHeight: 1.71429,
            }}
            size="small"
            variant="contained"
            className="mpl-primary-btn"
            onClick={() => handleReject("Rejected")}
            disabled={
              currentData?.flag === "Approved" || currentData?.flag === "Rejected"
            }
          >
            Reject
          </Button>

          <Button
            sx={{
              fontWeight: 700,
              borderRadius: "8px",
              fontSize: "13px",
              height: "30px",
              padding: "4px 10px",
              boxShadow: "none",
              lineHeight: 1.71429,
            }}
            size="small"
            variant="contained"
            className="mpl-primary-btn"
            onClick={() => handleReOpen("Reopened")}
          >
            ReOpen
          </Button>

        </Box>
      </Box>
      <Box sx={{ marginTop: "20px" }}>
        <Typography sx={{ padding: "10px" }} variant="h4">
          Time Distribution
        </Typography>
      </Box>
      <TableContainer component={Paper}>
        <Table
          className={classes.table}
          sx={{ minWidth: 700 }}
          aria-label="customized table"
        >
          <TableHead>
            <TableRow>
            <StyledTableCell
                align="left"
              >
                Project Task
              </StyledTableCell>
              {date?.map((header, index) => (
                <StyledTableCell key={index}>{header}</StyledTableCell>
              ))}
              <StyledTableCell sx={{ width: "95px" }} align="left">
                Total
              </StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <StyledTableCell>{currentData?.project_code}</StyledTableCell>
              {headers.slice(1, 8).map((header, index) => (
                <StyledTableCell key={index}>
                  {currentData?.weekly_hour?.PROJECTCODE[dayMap[header]]}
                </StyledTableCell>
              ))}
              <StyledTableCell>
                {Object.values(currentData?.weekly_hour?.PROJECTCODE).reduce(
                  (total, value) => total + parseInt(value),
                  0
                )}
              </StyledTableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>

      <Box sx={{ marginTop: "20px" }}>
        <Typography sx={{ padding: "10px" }} variant="h6">
          APPROVAL HISTORY
        </Typography>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 700 }} aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell>Date</StyledTableCell>
                <StyledTableCell>Action</StyledTableCell>
                <StyledTableCell>User</StyledTableCell>
                <StyledTableCell>Comments</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <StyledTableCell component="th" scope="row">
                  {ConvertDateTime(currentData?.modifiedon)}
                </StyledTableCell>
                <StyledTableCell>{currentData?.flag}</StyledTableCell>
                <StyledTableCell>{currentData?.employee_name}</StyledTableCell>
                <StyledTableCell>{currentData?.comments}</StyledTableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      <Popup
        title="Comments"
        openPopup={showComments}
        setOpenPopup={setShowComments}
        fullWidth={true}
      >
        <TextField
          autoFocus={false}
          size="small"
          multiline
          rows={4}
          value={comments}
          onChange={(e) => setComments(e.target.value)}
          variant="outlined"
          fullWidth
          id="comments"
          label="Comments"
          name="comments"
        />
        <Box style={{ display: "flex", justifyContent: "flex-end" }}>
          <Button
            variant="contained"
            color="primary"
            type="submit"
            className="mpl-primary-btn"
            sx={{ marginTop: "1.75rem" }}
            onClick={handleConfirm}
          >
            Save
          </Button>
        </Box>
      </Popup>
    </>
  );
};

export default TeamApprove;