import { Box, Card, Divider, Grid, IconButton, Typography } from "@mui/material";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { GetDesignationAction } from "../../../../../store/Ancillary/Designation/Action";
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { GetBankAction } from "../../../../../store/Ancillary/Bank/Action";
import { GetLeavingReasonAction } from "../../../../../store/Ancillary/LeavingReason/Action";
import { GetRelationshipAction } from "../../../../../store/Ancillary/Relationship/Action";
import { GetJobAction } from "../../../../../store/Ancillary/Job/Action";
import { GetBusinessUnitAction } from "../../../../../store/Ancillary/BusinessUnit/Action";
import { GetSubBusinessUnitAction } from "../../../../../store/Ancillary/SubBusinessUnit/Action";
import { GetCasteAction } from "../../../../../store/Ancillary/Caste/Action";
import { GetStudyLevelAction } from "../../../../../store/Ancillary/StudyLevel/Action";
import Skeleton from '@mui/material/Skeleton';
// import SaveAsIcon from '@mui/icons-material/SaveAs';
import { NoData } from "../../../Base/views/NotAuthorized";
import { GetLeaveTypeAction } from "../../../../../store/Leave/LeaveType/Action";
import { GetPaygradeAction } from "../../../../../store/Ancillary/Paygrade/Action";
import { GetEmploymentTypeAction } from "../../../../../store/Ancillary/EmploymentType/Action";
import { GetCostCenterAction } from "../../../../../store/Ancillary/CostCenter/Action";
import { GetWorkLocationAction } from "../../../../../store/Ancillary/WorkLocation/Action";
import { GetYearlyHolidayCalendarAction } from "../../../../../store/Leave/YearlyHolidayCalendar/Action";
import { GetProofTypeAction } from "../../../../../store/Ancillary/ProofType/Action";
import { GetLeaveReasonAction } from "../../../../../store/Leave/LeaveReason/Action";
import { GetMyTimeConfigureAction } from "../../../../../store/Leave/MyTimeConfigure/Action";
import { GetStudyProgramAction } from "../../../../../store/Ancillary/StudyProgram/Action";
import { GetWeekOffConfigureAction, GetWorkHoursAction } from "../../../../../store/Leave/WeekOff/Action";

const Dashboard = ({ setValue, setAccordian }) => {
    const dispatch = useDispatch();
    const { designation_options } = useSelector(state => state?.AncillaryReducer?.DesignationReducer);
    const { leavingreasonData } = useSelector(state => state?.AncillaryReducer?.LeavingReasonReducer);
    const { businessunitData } = useSelector(state => state.AncillaryReducer?.BusinessUnitReducer);
    const { subBusinessunitData } = useSelector(state => state.AncillaryReducer?.SubBusinessUnitReducer);
    const { leavetypes_options } = useSelector(state => state.LeaveReducer?.LeaveTypeReducer);
    const { employment_type_options } = useSelector(state => state?.AncillaryReducer?.EmploymentTypeReducer);
    const { cost_center_options } = useSelector(state => state?.AncillaryReducer?.CostCenterReducer);
    const { workLocation_options, isLoading } = useSelector(state => state?.AncillaryReducer?.WorkLocationReducer);
    const { holidayData } = useSelector(state => state?.LeaveReducer?.YearlyHolidayCalendarReducer)
    const { proof_type_data } = useSelector(state => state?.AncillaryReducer?.ProofTypeReducer)
    const { leavereasonData } = useSelector(state => state?.LeaveReducer?.LeaveReasonReducer);
    const { mytime_options } = useSelector(state => state.LeaveReducer?.MyTimeConfigureReducer);
    const { week_off_data } = useSelector(state => state.LeaveReducer?.WeekOffConfigureReducer);
    const { work_hours_data } = useSelector(state => state.LeaveReducer?.WeekOffConfigureReducer);
    const { studylevel_options } = useSelector(state => state?.AncillaryReducer?.StudyLevelReducer);
    const { studyprogram_options } = useSelector(state => state?.AncillaryReducer?.StudyProgramReducer);

    useEffect(() => {
        dispatch(GetDesignationAction());
        dispatch(GetBankAction());
        dispatch(GetLeavingReasonAction());
        dispatch(GetRelationshipAction());
        dispatch(GetJobAction());
        dispatch(GetBusinessUnitAction());
        dispatch(GetSubBusinessUnitAction());
        dispatch(GetCasteAction());
        dispatch(GetLeaveTypeAction());
        dispatch(GetStudyLevelAction());
        dispatch(GetStudyProgramAction({ studylevel_id: 0}));
        dispatch(GetPaygradeAction());
        dispatch(GetEmploymentTypeAction());
        dispatch(GetCostCenterAction());
        dispatch(GetWorkLocationAction());
        dispatch(GetYearlyHolidayCalendarAction({ year: 0 }));
        dispatch(GetProofTypeAction());
        dispatch(GetLeaveReasonAction());
        dispatch(GetMyTimeConfigureAction());
        dispatch(GetWeekOffConfigureAction());
        dispatch(GetWorkHoursAction());
        
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleClick = (value, accordian) => {
        setValue(value);
        setAccordian(accordian)
    }

    const data = [
        { title: "Cost Center", data: cost_center_options || [], field_name: "name" },
        { title: "Designation", data: designation_options || [], field_name: "name" },
        { title: "Leaving Reason", data: leavingreasonData || [], field_name: "lreason_name" },
        { title: "Business Unit", data: businessunitData || [], field_name: "name" },
        { title: "Sub Business Unit", data: subBusinessunitData || [], field_name: "name" },
        { title: "Employment Type", data: employment_type_options || [], field_name: "etype_name" },
        { title: "Work Location", data: workLocation_options || [], field_name: "work_location_name" },
        { title: "Proof Type", data: proof_type_data || [], field_name: "name" },
        { title: "Study Level", data: studylevel_options || [], field_name: "name" },
        { title: "Study Program", data: studyprogram_options || [], field_name: "name" },
    ]

    const leaveData = [
        { title: "Leave Types", data: leavetypes_options || [], field_name: "name" },
        { title: "Holidays", data: holidayData || [], field_name: "name" },
        { title: "Leave Reason", data: leavereasonData || [], field_name: "reason_name" },
    ]
    const myTimeData = [
        { title: "Projects", data: mytime_options || [], field_name: "project_code" },
        { title: "Week off configuration", data: week_off_data || [], field_name: "day_name" },
        { title: "Work Hours", data: work_hours_data || [], field_name: "working_hours" },
    ]

    return (
        <>
            <Grid container sx={{ padding: "10px" }} className="hr-settings">
                {
                    data?.map((res, index) => {
                        return (
                            <Grid key={index} item xs={12} sm={6} md={3} lg={3} sx={{ padding: "10px" }}>
                                <Card className="shadow" sx={{ border: "1px solid #dbdada", borderRadius: "10px" }}>
                                    <Box sx={{ display: "flex", justifyContent: "space-between", padding: "5px" }} className="card-title-dashboard">
                                        {
                                            isLoading ?
                                                <Skeleton width="85%">
                                                    <Typography>.</Typography>
                                                </Skeleton>
                                                : <Typography variant="h6" sx={{ marginLeft: "5px" }}>{res.title}</Typography>
                                        }
                                        <IconButton size="small" onClick={() => handleClick("2", res.title)} sx={{ color: "white" }}>
                                            <OpenInNewIcon />
                                        </IconButton>
                                    </Box>
                                    <Divider sx={{ backgroundColor: "#8534b9" }} />
                                    <Box sx={{ overflowY: "auto", maxHeight: "12rem", minHeight: "12rem", padding: "10px" }}>
                                        {
                                            isLoading ?
                                                <Skeleton variant="rectangular" width="90%" sx={{ margin: "10px" }}>
                                                    <div style={{ paddingTop: '60%' }} />
                                                </Skeleton>
                                                : res.data?.length > 0 ?
                                                    res.data?.map((item, id) => {
                                                        return (
                                                            <>
                                                                <Typography sx={{ padding: "2px" }} key={id} variant="body1">{item?.[res?.field_name]}</Typography>
                                                            </>
                                                        )
                                                    })
                                                    : <NoData title="No data found" />
                                        }
                                    </Box>
                                </Card>
                            </Grid>
                        )
                    })
                }
                {
                    leaveData?.map((res, index) => {
                        return (
                            <Grid key={index} item xs={12} sm={6} md={3} lg={3} sx={{ padding: "10px" }}>
                                <Card className="shadow" sx={{ border: "1px solid #dbdada", borderRadius: "10px" }}>
                                    <Box sx={{ display: "flex", justifyContent: "space-between", padding: "5px" }} className="card-title-dashboard">
                                        {
                                            isLoading ?
                                                <Skeleton width="85%">
                                                    <Typography>.</Typography>
                                                </Skeleton>
                                                : <Typography variant="h6" sx={{ marginLeft: "5px" }}>{res.title}</Typography>
                                        }
                                        <IconButton size="small" onClick={() => handleClick("3", res.title)} sx={{ color: "white" }}>
                                            <OpenInNewIcon />
                                        </IconButton>
                                    </Box>
                                    <Divider sx={{ backgroundColor: "#8534b9" }} />
                                    <Box sx={{ overflowY: "auto", maxHeight: "12rem", minHeight: "12rem", padding: "10px" }}>
                                        {
                                            isLoading ?
                                                <Skeleton variant="rectangular" width="90%" sx={{ margin: "10px" }}>
                                                    <div style={{ paddingTop: '60%' }} />
                                                </Skeleton>
                                                : res.data?.length > 0 ?
                                                    res.data?.map((item, id) => {
                                                        return (
                                                            <>
                                                                <Typography sx={{ padding: "2px" }} key={id} variant="body1">{item?.[res?.field_name]}</Typography>
                                                            </>
                                                        )
                                                    })
                                                    : <NoData title="No data found" />
                                        }
                                    </Box>
                                </Card>
                            </Grid>
                        )
                    })
                }

{
                    myTimeData?.map((res, index) => {
                        return (
                            <Grid key={index} item xs={12} sm={6} md={3} lg={3} sx={{ padding: "10px" }}>
                                <Card className="shadow" sx={{ border: "1px solid #dbdada", borderRadius: "10px" }}>
                                    <Box sx={{ display: "flex", justifyContent: "space-between", padding: "5px" }} className="card-title-dashboard">
                                        {
                                            isLoading ?
                                                <Skeleton width="85%">
                                                    <Typography>.</Typography>
                                                </Skeleton>
                                                : <Typography variant="h6" sx={{ marginLeft: "5px" }}>{res.title}</Typography>
                                        }
                                        <IconButton size="small" onClick={() => handleClick("4", res.title)} sx={{ color: "white" }}>
                                            <OpenInNewIcon />
                                        </IconButton>
                                    </Box>
                                    <Divider sx={{ backgroundColor: "#8534b9" }} />
                                    <Box sx={{ overflowY: "auto", maxHeight: "12rem", minHeight: "12rem", padding: "10px" }}>
                                        {
                                            isLoading ?
                                                <Skeleton variant="rectangular" width="90%" sx={{ margin: "10px" }}>
                                                    <div style={{ paddingTop: '60%' }} />
                                                </Skeleton>
                                                : res.data?.length > 0 ?
                                                    res.data?.map((item, id) => {
                                                        return (
                                                            <>
                                                                <Typography sx={{ padding: "2px" }} key={id} variant="body1">{item?.[res?.field_name]}</Typography>
                                                            </>
                                                        )
                                                    })
                                                    : <NoData title="No data found" />
                                        }
                                    </Box>
                                </Card>
                            </Grid>
                        )
                    })
                }
            </Grid>
        </>
    )
}

export default Dashboard;