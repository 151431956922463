import React, { useEffect, useState } from "react";
import {
    Button,
    FormControl,
    Grid,
    TextField,
    Divider,
    Typography,
    Box,
    Tooltip,
    IconButton,
    Card,
    CardContent
} from "@mui/material";
import { toast } from "react-toastify";
import { grey } from "@mui/material/colors";
import AddIcon from "@mui/icons-material/Add";
import SelectableSearch from "../../../Base/views/SelectableSearch";
import DeleteIcon from "@mui/icons-material/Delete";
import { GetDeductionAction, GetEarningAction } from "../../../../../store/Finance/Component/Action";
import { useDispatch, useSelector } from "react-redux";
import { AddPayrollTemplateAction, UpdatePayrollTemplatetAction } from "../../../../../store/Finance/PayRollTemplate/Action";
import ArrowBackButton from "../../../Base/views/ArrowBackButton";

const TemplateForm = (props) => {
    const { setCurrentMode, currentMode, data, setData } = props
    const initialValue = {
        template_name: "",
    };
    const templateEarningsValues = {
        earning_key: "",
    };
    const templateDeductionValues = {
        deduction_key: "",
    };
    const [templateEarningData, setTemplateEarningData] = useState([]);
    const [templateDeductionData, setTemplateDeductionData] = useState([]);
    const [templatename, setTemplatename] = useState(initialValue);
    const [errors, setErrors] = useState({});
    const [templateEarningErrors, setTemplateEarningErrors] = useState([]);
    const [templateDeductionErrors, setTemplateDeductionErrors] = useState([]);
    const [disabledEarningTemplate, setDisabledEarningTemplate] = useState(false);
    const [disabledDeductionTemplate, setDisabledDeductionTemplate] = useState(false);
    const [earningData, setEarningData] = useState({
        load: true,
        values: [],
    });
    const [deductionData, setDeductionData] = useState({
        load: true,
        values: [],
    });
    useEffect(() => {
        const allFieldsEmpty = templateEarningData?.every(
            (field) =>
                field?.earning_key !== "" &&
                field?.earning_value !== ""
        );
        setDisabledEarningTemplate(!allFieldsEmpty);
    }, [templateEarningData]);
    useEffect(() => {
        const allFieldsEmpty = templateDeductionData?.every(
            (field) =>
                field?.deduction_key !== "" &&
                field?.deduction_value !== ""
        );
        setDisabledDeductionTemplate(!allFieldsEmpty);
    }, [templateDeductionData]);
    const { earning_data, deduction_data } = useSelector(
        (state) => state?.FinanceReducer.ComponentReducer
    );
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(GetEarningAction());
        dispatch(GetDeductionAction());
        if (data?.template_id) {
            setTemplatename({ template_name: data?.template_name });
            if (data?.template_fields?.earning_key?.length) {
                const earningFields = data?.template_fields?.earning_key?.map((key) => ({
                    earning_key: {
                        label: key?.label,
                        name: key?.name
                    },
                }));
                setTemplateEarningData(earningFields);
            }
            if (data?.template_fields?.deduction_key?.length) {
                const deductionFields = data.template_fields.deduction_key.map((key) => ({
                    deduction_key: {
                        label: key?.label,
                        name: key?.name
                    },
                }));
                setTemplateDeductionData(deductionFields);
            }
        }
    }, []);

    const handleInputChange = (event, value) => {
        setTemplatename({
            ...templatename,
            [event.target.name]: event.target.value,
        });
    };

    const validate = (fieldValues) => {
        let temp = { ...errors };
        if ("template_name" in fieldValues) {
            temp.template_name = fieldValues.template_name === "" ? "Template Name is required" : "";
        }
        setErrors({
            ...temp,
        });
        return Object.values(temp).every((x) => x === "");
    };
    const handleSubmit = () => {
        const isEarningsValid = Earningvalidate();
        const isDeductionsValid = Deductionvalidate();
        if (validate(templatename) && isEarningsValid && isDeductionsValid) {
            if (templateEarningData?.length === 0 && templateDeductionData?.length === 0) {
                toast.error("Atleast Choose One in Earning or Deduction", { duration: 2000, });
                return
            }
            const earningKeys = templateEarningData?.map(item => item.earning_key).filter(Boolean) || "";
            const deductionKeys = templateDeductionData?.map(item => item.deduction_key).filter(Boolean) || "";
            const templateFields = JSON.stringify({
                earning_key: earningKeys,
                deduction_key: deductionKeys
            });
            if (data?.template_id) {
                dispatch(UpdatePayrollTemplatetAction({
                    payroll_generation_template_id: data?.template_id,
                    template_name: templatename?.template_name,
                    template_fields: templateFields
                }));
            }
            else {
                dispatch(AddPayrollTemplateAction({
                    template_name: templatename?.template_name,
                    template_fields: templateFields
                }));
            }
            handleBack();
        }
    };
    //Earning Template Name Section
    const handleAddEarningFields = () => {
        setTemplateEarningData((state) => [...state, templateEarningsValues]);
    };
    const handleEarningDelete = (e, id, deleteid) => {
        let Data = [...templateEarningData];
        Data.splice(id, 1);
        setTemplateEarningData(Data);
    };
    const Earningvalidate = () => {
        let isValid = true;
        const newErrors = [...templateEarningErrors];
        templateEarningData?.forEach((store, index) => {
            const error = {};
            if (!store.earning_key) {
                error.earning_key = "Name is required";
                isValid = false;
            }
            while (newErrors.length <= index) {
                newErrors.push({});
            }
            newErrors[index] = error;
        });
        setTemplateEarningErrors(newErrors);
        return isValid;
    };
    const handleEarningChange = (e, value, id) => {
        let newArr = [...templateEarningData];
        const newErrors = [...templateEarningErrors];
        let item = newArr[id];
        item = { ...item, [e]: value };
        newArr[id] = item;
        newErrors[id] = { ...newErrors[id], [e]: "" };
        setTemplateEarningData(newArr);
        setTemplateEarningErrors(newErrors);
    }
    const handleEarnings = () => {
        earning_data &&
            setEarningData({ load: false, values: earning_data?.[0]?.fn_get_earning_components });
    };
    const getFilteredEarnings = (id) => {
        const selectedEarnings = templateEarningData.map((item, index) => index !== id && item.earning_key?.label).filter(Boolean);
        return earningData.values.filter(
            (option) => !selectedEarnings.includes(option.label)
        );
    };

    //Deduction Template Name Section
    const handleDeduction = () => {
        deduction_data &&
            setDeductionData({ load: false, values: deduction_data?.[0]?.fn_get_deduction_components });
    };
    const handleAddDeductionFields = () => {
        setTemplateDeductionData((state) => [...state, templateDeductionValues]);
    };
    const handleDeductionDelete = (e, id, deleteid) => {
        let Data = [...templateDeductionData];
        Data.splice(id, 1);
        setTemplateDeductionData(Data);
    };
    const Deductionvalidate = () => {
        let isValid = true;
        const newErrors = [...templateDeductionErrors];
        templateDeductionData?.forEach((store, index) => {
            const error = {};
            if (!store.deduction_key) {
                error.deduction_key = "Name is required";
                isValid = false;
            }
            while (newErrors.length <= index) {
                newErrors.push({});
            }
            newErrors[index] = error;
        });
        setTemplateDeductionErrors(newErrors);
        return isValid;
    };
    const handleDeductionChange = (e, value, id) => {
        let newArr = [...templateDeductionData];
        const newErrors = [...templateDeductionErrors];
        let item = newArr[id];
        item = { ...item, [e]: value };
        newArr[id] = item;
        newErrors[id] = { ...newErrors[id], [e]: "" };
        setTemplateDeductionData(newArr);
        setTemplateDeductionErrors(newErrors);
    }
    const getFilteredDeduction = (id) => {
        const selectedDeduction = templateDeductionData.map((item, index) => index !== id && item.deduction_key?.label).filter(Boolean);
        return deductionData.values.filter(
            (option) => !selectedDeduction.includes(option.label)
        );
    };
    const handleBack = () => {
        setCurrentMode("grid")
        setTemplateEarningData([]);
        setTemplateDeductionData([]);
        setTemplateEarningErrors({});
        setTemplateDeductionErrors({})
        setTemplatename({ template_name: "" })
    }
    return (
        <>
            <Box sx={{ pb: 2 }}>
                <Box sx={{ position: "sticky", top: 0, zIndex: 1, backgroundColor: "#ffffff", display: "flex", alignItems: "center", justifyContent: "space-between", p: 2 }}>
                    <ArrowBackButton onClick={handleBack} />
                    <Button
                        variant="contained"
                        className="mpl-primary-btn"
                        onClick={handleSubmit}
                    >
                        Save
                    </Button>
                </Box>

                <Box>
                    <Box sx={{ display: "flex", flexDirection: "column" }}>
                        <Grid container spacing={2} style={{ justifyContent: "center" }}>
                            <Grid item xs={10} md={10}>
                                <Card>
                                    <CardContent>
                                        <FormControl fullWidth>
                                            <TextField
                                                size="small"
                                                required
                                                value={templatename?.template_name}
                                                onChange={handleInputChange}
                                                variant="standard"
                                                fullWidth
                                                id="template_name"
                                                label="Template Name"
                                                name="template_name"
                                                sx={{ mb: 4 }}
                                                inputProps={{ style: { fontSize: 23 } }}
                                                InputLabelProps={{ style: { fontSize: 20 } }}
                                                autoComplete="template_name"
                                                {...(errors.template_name && {
                                                    error: true,
                                                    helperText: errors.template_name,
                                                })}
                                            />
                                        </FormControl>
                                        <Box
                                            style={{
                                                backgroundColor: grey[100],
                                                borderRadius: "10px",
                                            }}
                                        >
                                            <Grid container>
                                                <Grid
                                                    item
                                                    xs={12}
                                                    md={12}
                                                    sx={{
                                                        marginTop: "1rem",
                                                        paddingRight: "15px",
                                                        paddingLeft: "15px",
                                                    }}
                                                >
                                                    <Box sx={{
                                                        width: "100%",
                                                        display: "flex",
                                                        justifyContent: "space-between",
                                                    }}>
                                                        <Typography
                                                            variant="h6"
                                                            gutterBottom
                                                        >
                                                            Earnings
                                                        </Typography>
                                                        <Tooltip title="Add">
                                                            <IconButton
                                                                disabled={disabledEarningTemplate}
                                                                className="mpl-primary-btn"
                                                                onClick={handleAddEarningFields}
                                                                style={{ padding: '4px', minWidth: '30px', height: '30px', marginBottom: "8px" }}
                                                            >
                                                                <AddIcon fontSize="small" />
                                                            </IconButton>
                                                        </Tooltip>
                                                    </Box>
                                                </Grid>
                                            </Grid>

                                            {templateEarningData?.map((res, id) => {
                                                return (
                                                    <>
                                                        <Grid container>
                                                            <Grid
                                                                item
                                                                xs={12}
                                                                md={11}
                                                                sx={{
                                                                    marginTop: "1rem",
                                                                    paddingRight: "15px",
                                                                    paddingLeft: "15px",
                                                                    marginBottom: "1rem"
                                                                }}
                                                            >
                                                                <FormControl fullWidth>
                                                                    <SelectableSearch
                                                                        isOptionEqualToValue={(option, value) =>
                                                                            +option.label === +value.label
                                                                        }
                                                                        label="Select an Earnings"
                                                                        fullWidth
                                                                        value={res?.earning_key}
                                                                        loading={() => handleEarnings()}
                                                                        fieldLabel="label"
                                                                        required={false}
                                                                        variantType={"standard"}
                                                                        name="earning_key"
                                                                        size="small"
                                                                        onChangeEvent={(e, newValue) =>
                                                                            handleEarningChange("earning_key", newValue, id)  // Handle change
                                                                        }
                                                                        data={{ load: earningData.load, values: getFilteredEarnings(id) }}
                                                                        {...(templateEarningErrors[id] &&
                                                                            templateEarningErrors[id].earning_key && {
                                                                            error: true,
                                                                            helperText: templateEarningErrors[id].earning_key,
                                                                        })}
                                                                    />
                                                                </FormControl>
                                                            </Grid>

                                                            <Grid item xs={12} sm={6} md={1}>
                                                                <Box
                                                                    sx={{
                                                                        marginTop: "40px",
                                                                        display: "flex",
                                                                        justifyContent: "center",
                                                                        alignItems: "flex-end",
                                                                        cursor: "pointer"
                                                                    }}
                                                                >
                                                                    <DeleteIcon color="error" onClick={(e) => {
                                                                        handleEarningDelete(e, id, res?.certificate_id);
                                                                    }} fontSize="small" />

                                                                </Box>
                                                            </Grid>
                                                        </Grid>
                                                    </>
                                                );
                                            })}
                                            <Divider />
                                            <Grid container>
                                                <Grid
                                                    item
                                                    xs={12}
                                                    md={12}
                                                    sx={{
                                                        marginTop: "1rem",
                                                        paddingRight: "15px",
                                                        paddingLeft: "15px",
                                                    }}
                                                >
                                                    <Box sx={{
                                                        width: "100%",
                                                        display: "flex",
                                                        justifyContent: "space-between",
                                                    }}>
                                                        <Typography
                                                            variant="h6"
                                                            gutterBottom
                                                        >
                                                            Deduction
                                                        </Typography>
                                                        <Tooltip title="Add">
                                                            <IconButton
                                                                disabled={disabledDeductionTemplate}
                                                                className="mpl-primary-btn"
                                                                style={{ padding: '4px', minWidth: '30px', height: '30px', marginBottom: "8px" }}
                                                                onClick={handleAddDeductionFields}
                                                            >
                                                                <AddIcon fontSize="small" />
                                                            </IconButton>
                                                        </Tooltip>
                                                    </Box>
                                                </Grid>
                                            </Grid>
                                            {templateDeductionData?.map((res, id) => {
                                                return (
                                                    <>
                                                        <Grid container>
                                                            <Grid
                                                                item
                                                                xs={12}
                                                                md={11}
                                                                sx={{
                                                                    marginTop: "1rem",
                                                                    paddingRight: "15px",
                                                                    paddingLeft: "15px",
                                                                    marginBottom: "1rem"
                                                                }}
                                                            >
                                                                <FormControl fullWidth>
                                                                    <SelectableSearch
                                                                        isOptionEqualToValue={(option, value) =>
                                                                            +option.label === +value.label
                                                                        }
                                                                        label="Select an Deductions"
                                                                        fullWidth
                                                                        value={res?.deduction_key}
                                                                        loading={() => handleDeduction()}
                                                                        fieldLabel="label"
                                                                        required={false}
                                                                        variantType={"standard"}
                                                                        name="deduction_key"
                                                                        size="small"
                                                                        onChangeEvent={(e, newValue) =>
                                                                            handleDeductionChange("deduction_key", newValue, id)  // Handle change
                                                                        }
                                                                        data={{ load: deductionData.load, values: getFilteredDeduction(id) }}
                                                                        {...(templateDeductionErrors[id] &&
                                                                            templateDeductionErrors[id].deduction_key && {
                                                                            error: true,
                                                                            helperText: templateDeductionErrors[id].deduction_key,
                                                                        })}
                                                                    />
                                                                </FormControl>
                                                            </Grid>
                                                            <Grid item xs={12} sm={6} md={1}>
                                                                <Box
                                                                    sx={{
                                                                        marginTop: "40px",
                                                                        display: "flex",
                                                                        justifyContent: "center",
                                                                        alignItems: "flex-end",
                                                                        cursor: "pointer"
                                                                    }}
                                                                >
                                                                    <DeleteIcon color="error" onClick={(e) => {
                                                                        handleDeductionDelete(e, id, res?.certificate_id);
                                                                    }} fontSize="small" />

                                                                </Box>
                                                            </Grid>
                                                        </Grid>
                                                    </>
                                                );
                                            })}
                                        </Box>
                                    </CardContent>
                                </Card>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </Box>
        </>
    );
}

export default TemplateForm;
