import { useEffect } from "react";
import MaterialTable from "material-table";
import {
    Box,
} from "@mui/material";
import { useSelector } from "react-redux";
import { useState } from "react";
import { useDispatch } from "react-redux";
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { GetEmployeesAction } from "../../../../../store/Employee/Employee/Action";
import EmployeePayroleTemplate from "./EmployeePayroleTemplate";
import Base from "../../../Base/views/Base";
import { ConvertDateTime } from "../../../Base/helper/baseFunctions";
import Popup from "../../../Base/views/Popup";

const MyEmployeePayrole = ({ }) => {
    const dispatch = useDispatch();
    const [openTemplate, setOpenTemplate] = useState(false);
    const [currentData, setCurrentData] = useState("")
    const { employees_options } = useSelector(
        (state) => state?.EmployeeReducer.EmployeesReducer
    );
    useEffect(() => {
        dispatch(GetEmployeesAction({ is_active: true }));
    }, []);

    const hanldeEdit = (data) => {
        setOpenTemplate(true);
        setCurrentData(data);
    };
    return (
        <>
            <Base>
                <Box sx={{ maxHeight: "87vh", overflowY: "auto" }}>
                    <MaterialTable
                        onRowClick={(event, rowData) => hanldeEdit(rowData)}
                        columns={[
                            {
                                title: "Name",
                                cellStyle: { padding: "10px 10px 10px 13px" },
                                field: "name",
                                grouping: false,
                            },
                            {
                                title: "Email",
                                cellStyle: { padding: "10px 10px 10px 13px" },
                                field: "office_email",
                                grouping: false,
                            },
                            {
                                title: "Joining Date",
                                cellStyle: { padding: "10px 10px 10px 13px" },
                                field: "doj",
                                render: (rowData) => {
                                    return ConvertDateTime(rowData.doj);
                                },
                            },
                            {
                                title: "Payroll Setup", field: "payroll_setup_flag",headerStyle:{ textAlign:"left"}, cellStyle: { padding: "10px 10px 10px 35px", textAlign:"left" },
                                render: rowData => {
                                    return rowData.payroll_setup_flag ? <TaskAltIcon sx={{ color: "rgb(86, 202, 0)" }} />
                                        : <HighlightOffIcon sx={{ color: "rgb(255, 76, 81)" }} />
                                }
                            },
                        ]}
                        data={employees_options}
                        options={{
                            maxBodyHeight: "65vh",
                            minBodyHeight: "65vh",
                            rowStyle: { fontSize: "13px" },
                            headerStyle: { padding: "10px 10px 10px 13px", fontWeight: "bold" },
                            sorting: true,
                            search: true,
                            searchText: "",
                            searchFieldAlignment: "right",
                            searchAutoFocus: true,
                            searchFieldVariant: "standard",
                            filtering: false,
                            paging: true,
                            pageSizeOptions: [25, 50],
                            pageSize: 25,
                            paginationType: "stepped",
                            showFirstLastPageButtons: false,
                            paginationPosition: "bottom",
                            exportButton: false,
                            exportAllData: false,
                            exportFileName: "Employees",
                            addRowPosition: "first",
                            selection: false,
                            actionsColumnIndex: -1,
                            showSelectAllCheckbox: true,
                            showTextRowsSelected: true,
                            grouping: false,
                            columnsButton: false,
                        }}

                        title="Employees"
                    />
                    <Popup
                        maxWidth="md"
                        fullWidth={true}
                        title={`Payroll Configuration - ${currentData?.name}`}
                        openPopup={openTemplate}
                        setOpenPopup={setOpenTemplate}
                    >
                        <EmployeePayroleTemplate emp={true} createEmp={false} setOpenTemplate={setOpenTemplate} openTemplate={openTemplate} currentData={currentData} />
                    </Popup>
                </Box>
            </Base>
        </>
    );
};

export default MyEmployeePayrole;