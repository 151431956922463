import {  Collapse, Dialog, DialogContent, DialogTitle, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Typography } from '@mui/material';
import React, { useState } from 'react';
import ActionButton from '../../Base/views/ActionButton';
import { Link } from 'react-router-dom';
import CloseIcon from '@mui/icons-material/Close';
import SettingsSuggestIcon from "@mui/icons-material/SettingsSuggest";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { useSelector } from "react-redux";
import FinanceMenu from './FinanceMenu';
import AtsMenu from './AtsMenu';
import LmsMenu from './LmsMenu';
import EmployeeMenu from './EmployeeMenu';

const MobileMenu = ({
    settingsAccess,
    open,
    close,
    sideBarData1,
    sideBarData2,
    handleMenuClick,
    selectedMenu,
    isMobile,
    theme,
    userData,
    payrollCollapse,
    financeCollapse,
    setFinanceCollapse,
    setPayrollCollapse,
    financeOptions,
    payrollOptions,
    access,
    atsCollapse,
    setAtsCollapse,
    atsOptions,
    atsSubMenu,
    atsManageCollapse,
    setAtsManageCollapse,
    atsReportCollapse,
    setReportCollapse,
    atsReportMenu,
    lmsCollapse,
    setLmsCollapse,
    lmsSubMenu,
    EmployeeCollapse,
    setEmployeeCollapse,
    employeeOptions
}) => {
    const [collapse, setCollapse] = useState(false);
    const { userInfo } = useSelector(state => state?.SignInReducer)
    const { atsModuleData } = useSelector(state => state?.RoleAndAccessReducer?.ATSModuleReducer);
    const ATSAccess = atsModuleData?.find(
        (item) => item.module_name === "ATS"
      );
      const atsDashboarAccess = atsModuleData?.find(
        (item) => item.module_name === "ATS Dashboard"
      );
      const leaveAccess = atsModuleData?.find(
        (item) => item.module_name === "Leave"
      );
      const myEmployeeAccess = atsModuleData?.find(
        (item) => item.module_name === "My Employee"
      );
    return (
        <Dialog
            sx={{ ".MuiDialog-paper": { maxWidth: "330px", borderRadius: "10px", marginTop: "2.5rem", marginBottom: "10px" } }}
            open={open}
            fullScreen
            maxWidth="xs"
            classes={{
                paper: {
                    padding: "2px",
                    position: "absolute",
                    top: "5px",
                },
            }}
        >
            <DialogTitle sx={{ justifyContent: "flex-end", display: "flex", padding: "0px", paddingTop: "5px" }}>
                <ActionButton
                    disableHover={false}
                    onClick={() => {
                        close(false);
                    }}
                >
                    <CloseIcon color="action" />
                </ActionButton>
            </DialogTitle>
            <DialogContent sx={{ padding: "0px", }}>
                <List sx={{ p: 0, fontSize: "15px", overflowY: "auto", maxHeight: collapse ? "40%" : "90%" }}>
                    {
                        sideBarData1.map((res, id) => {
                            return (
                                <Link key={id} to={res?.route} style={{ color: theme.palette.mode === "dark" ? "white" : "black" }}>
                                    {res?.show && 
                                    <ListItem
                                        disablePadding
                                        sx={{ display: 'block', fontSize: "12px", marginLeft: `${isMobile ? "-4px" : ''}` }}
                                        onClick={(e) => (handleMenuClick(res?.label))}
                                        className={selectedMenu === res?.label ? "mobile-gradiant" : ""}
                                    >
                                        <ListItemButton
                                            sx={{
                                                minHeight: 10,
                                                justifyContent: open ? 'initial' : 'center',
                                                px: 2.5,
                                            }}
                                        >
                                            <ListItemIcon
                                                sx={{
                                                    minWidth: 0,
                                                    mr: open ? 2 : 'auto',
                                                    justifyContent: 'center',
                                                }}
                                            >
                                                <span style={{ color: selectedMenu === res?.label && "white" }}>{res?.icon}</span>
                                            </ListItemIcon>
                                            <ListItemText primary={res?.label} sx={{ opacity: open ? 1 : 0 }} />
                                        </ListItemButton>
                                    </ListItem>
                                    }
                                </Link>
                            )
                        })
                    }
                    { myEmployeeAccess && <EmployeeMenu
                    EmployeeCollapse={EmployeeCollapse}
                    setEmployeeCollapse={setEmployeeCollapse}
                    open={open}
                    employeeOptions={employeeOptions}
                    selectedMenu={selectedMenu}
                    handleMenuClick={handleMenuClick}
                    isMobile={isMobile}
                    theme={theme}
                  />}
                     {leaveAccess && <LmsMenu
                    lmsCollapse={lmsCollapse}
                    setLmsCollapse={setLmsCollapse}
                    open={open}
                    lmsSubMenu={lmsSubMenu}
                    selectedMenu={selectedMenu}
                    handleMenuClick={handleMenuClick}
                    isMobile={isMobile}
                    theme={theme}
                  />}

                    {/* Finance Menu */}
                    { myEmployeeAccess &&  ['cvtmeepl', "xpohrmeepl", "altiio", "adobemeepl", "kmkmeepl"].includes(userData?.domain) &&
                     <FinanceMenu
                        financeCollapse={financeCollapse}
                        setFinanceCollapse={setFinanceCollapse}
                        payrollCollapse={payrollCollapse}
                        setPayrollCollapse={setPayrollCollapse}
                        open={open}
                        financeOptions={financeOptions}
                        payrollOptions={payrollOptions}
                        selectedMenu={selectedMenu}
                        handleMenuClick={handleMenuClick}
                        isMobile={isMobile}
                        theme={theme}
                    />
                    }
                    {/* ATS Menu */}
                    {atsDashboarAccess && ATSAccess && <AtsMenu
                        atsCollapse={atsCollapse}
                        setAtsCollapse={setAtsCollapse}
                        open={open}
                        sideBarData={atsOptions}
                        userData={userData}
                        selectedMenu={selectedMenu}
                        handleMenuClick={handleMenuClick}
                        theme={theme}
                        isMobile={isMobile}
                        atsSubMenu={atsSubMenu}
                        setAtsManageCollapse={setAtsManageCollapse}
                        atsManageCollapse={atsManageCollapse}
                        atsReportCollapse={atsReportCollapse}
                        setReportCollapse={setReportCollapse}
                        atsReportMenu={atsReportMenu}
                    />}
                </List>
                <Collapse in={collapse} timeout="auto" unmountOnExit sx={{ position: "fixed", bottom: 50, width: "330px" }}>
                    <ListItem disablePadding sx={{ display: 'block', fontSize: "12px", backgroundColor: collapse && "#e3e3e3" }}>
                        <ListItemButton onClick={() => setCollapse(!collapse)} disableRipple>
                            <ListItemIcon sx={{ minWidth: "35px" }}>
                                <SettingsSuggestIcon />
                            </ListItemIcon>
                            <ListItemText sx={{ opacity: open ? 1 : 0 }}>
                                <Typography style={{ fontWeight: "bold" }}>Settings</Typography>
                            </ListItemText>
                            {open && collapse ? <ExpandMore /> : open && <ExpandLess />}
                        </ListItemButton>
                    </ListItem>
                    <List sx={{ padding: "0px" }}>
                        {
                            sideBarData2.map((res, id) => {
                                return (

                                    <Link key={id} to={res?.route} style={{ color: theme.palette.mode === "dark" ? "white" : "black" }}>
                                        {res?.show && 
                                        <ListItem
                                            disablePadding
                                            sx={{ display: 'block', fontSize: "12px", pl: 3 }}
                                            onClick={() => handleMenuClick(res?.label)}
                                            className={selectedMenu === res?.label ? "gradiant" : ""}
                                        >
                                            <ListItemButton
                                                key={id}
                                                sx={{
                                                    minHeight: 10,
                                                    justifyContent: open ? 'initial' : 'center',
                                                    px: 2.5,
                                                }}
                                            >
                                                <ListItemIcon
                                                    key={id}
                                                    sx={{
                                                        minWidth: 0,
                                                        mr: open ? 2 : 'auto',
                                                        justifyContent: 'center',
                                                    }}
                                                >
                                                    <span style={{ color: selectedMenu === res?.label && "white" }}>{res?.icon}</span>
                                                </ListItemIcon>
                                                <ListItemText primary={res?.label} sx={{ opacity: open ? 1 : 0 }} />
                                            </ListItemButton>
                                        </ListItem>
                                       }
                                    </Link>
                                )
                            })
                        }
                    </List>
                </Collapse>
                <List sx={{ position: "fixed", bottom: 0, width: "330px", padding: "0px" }}>
                    {
                        settingsAccess && !collapse &&
                        <>
                            {
                                
                                <ListItem disablePadding sx={{ display: 'block', fontSize: "12px", paddingLeft: "5px" }}>
                                    <ListItemButton onClick={() => {!collapse && atsCollapse && setAtsCollapse(); setCollapse(!collapse)}} disableRipple>
                                        <ListItemIcon sx={{ minWidth: "35px" }}>
                                            <SettingsSuggestIcon />
                                        </ListItemIcon>
                                        <ListItemText sx={{ opacity: open ? 1 : 0 }}> Settings </ListItemText>
                                        {open && collapse ? <ExpandMore /> : open && <ExpandLess />}
                                    </ListItemButton>
                                </ListItem>
                            }

                        </>
                    }
                </List>
            </DialogContent>
        </Dialog>
    );
};

export default MobileMenu;