import * as ActionTypes from "../ActionTypes";

const initialState = {
    isLoading: true,
    isSuccess: false,
    isError: false,
    earning_data: [],
    deduction_data:[]
}

export const ComponentReducer = function (state = initialState, action) {
    switch (action.type) {
        case ActionTypes.PAYROLL_EARNING_GET_REQUEST:
            return {
                ...state,
                isLoading: true,
                success: false,
                error: false,
            }
        case ActionTypes.PAYROLL_EARNING_GET_SUCCESS:
            return {
                ...state,
                isLoading: false,
                success: true,
                successMessage: action.payload?.message,
                earning_data: action.payload?.data?.data
            };
        case ActionTypes.PAYROLL_EARNING_GET_ERROR:
            return {
                ...state,
                isLoading: false,
                error: true,
                success: false,
                errorMessage: action.payload?.data?.error?.[0]?.message
            }
            case ActionTypes.PAYROLL_DEDUCTION_GET_REQUEST:
                return {
                    ...state,
                    isLoading: true,
                    success: false,
                    error: false,
                }
            case ActionTypes.PAYROLL_DEDUCTION_GET_SUCCESS:
                return {
                    ...state,
                    isLoading: false,
                    success: true,
                    successMessage: action.payload?.message,
                    deduction_data: action.payload?.data?.data
                };
            case ActionTypes.PAYROLL_DEDUCTION_GET_ERROR:
                return {
                    ...state,
                    isLoading: false,
                    error: true,
                    success: false,
                    errorMessage: action.payload?.data?.error?.[0]?.message
                }
        default:
            return state
    }
}