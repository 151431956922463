import * as ActionTypes from '../ActionTypes';

const componentInitialState = {
    isLoading: false,
    success: false,
    error: false,
    wage_report_data: []
}

export const WageReportsReducer = function (state = componentInitialState, action) {
    switch (action.type) {
        case ActionTypes.WAGE_REPORT_GET_REQUEST:
            return {
                ...state,
                isLoading: true,
                success: false,
                error: false,
            };
        case ActionTypes.WAGE_REPORT_GET_SUCCESS:
            return {
                ...state,
                isLoading: false,
                success: true,
                successMessage: action.payload?.message,
                wage_report_data: action.payload?.data?.data
            };
        case ActionTypes.WAGE_REPORT_GET_ERROR:
            return {
                ...state,
                isLoading: false,
                error: true,
                success: false,
                errorMessage: action.payload?.data?.error?.[0]?.message
            }
       
        default:
            return state;
    }
}