import { useEffect } from "react";
import MaterialTable from "material-table";
import {
    Box,
    Grid,
    FormControl,
    Menu,
    MenuItem,
} from "@mui/material";
import { useSelector } from "react-redux";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { ConvertDateTime } from "../../../Base/helper/baseFunctions";
import CommonDatePicker from "../../../Base/views/CommonDatePicker";
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import { GetEmployeeFaceTimeAction } from "../../../../../store/Finance/Profile/Action";
const EmployeeFaceTime = ({ }) => {
    const dispatch = useDispatch();
    const { employee_face_time_data } = useSelector(
        (state) => state?.FinanceReducer.EmployeeProfileReducer
    );
    const date = new Date();
    const [filters, setFilters] = useState({
        month_year: `${date.getFullYear()}-${date.getMonth()}-${date.getDate()}`
    });
    const [exportAnchor, setExportAnchor] = useState(null);
    const [name, setName] = useState(date.toLocaleString('default', { month: 'short', year: 'numeric' }));
    const openExportMenu = (event) => {
        setExportAnchor(event.currentTarget);
    };
    const closeExportMenu = () => {
        setExportAnchor(null);
    };
    useEffect(() => {
        const [f_year, month] = filters.month_year.split("-");
        const f_month = month.padStart(2, "0");
        dispatch(GetEmployeeFaceTimeAction({
            month: f_month,
            year: f_year
        }));
        const selectedDate = new Date(filters.month_year);
        setName(selectedDate.toLocaleString('default', { month: 'short', year: 'numeric' }));
    }, [filters]);
    const handleFilter = (event) => {
        if (["month_year"].includes(event?.target?.name)) {
            setFilters({ ...filters, [event?.target?.name]: event?.target?.value });
        }
    };
    const formatedTime = (time) => {
        const date = new Date(`1970-01-01T${time}`);
        return date.toLocaleTimeString([], { hour: 'numeric', minute: '2-digit', hour12: true });
    };
    const parseTime = (timeString) => new Date(`1970-01-01T${timeString}`);
    const formatTime = (date) => date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: true });
    const aggregateEmployeeData = (data) => {
        const employeeMap = {};
        data.forEach(entry => {
            const { employee_id, name, email, date, attendance_type, checkin, checkout, total_hours } = entry;
            const key = `${employee_id}-${date}`;
            if (!employeeMap[key]) {
                employeeMap[key] = {
                    employee_id,
                    name,
                    email,
                    date,
                    attendance_type,
                    InTime: checkin,
                    OutTime: checkout,
                    total_hours: parseFloat(total_hours)
                };
            } else {
                if (parseTime(checkin) < parseTime(employeeMap[key].InTime)) {
                    employeeMap[key].InTime = checkin;
                }
                if (parseTime(checkout) > parseTime(employeeMap[key].OutTime)) {
                    employeeMap[key].OutTime = checkout;
                }
                employeeMap[key].total_hours += parseFloat(total_hours);
            }
        });
        return Object.values(employeeMap).map(entry => ({
            ...entry,
            InTime: formatTime(parseTime(entry.InTime)),
            OutTime: formatTime(parseTime(entry.OutTime)),
            total_hours: entry.total_hours.toFixed(2)
        }));
    };
    const aggregatedData = aggregateEmployeeData(employee_face_time_data);
    const generateCSV = (data, headers, filename) => {
        const csvData = [headers.map(header => header.label)];
        data.forEach(row => {
            const rowData = headers.map(header => {
                if (header.key === "checkin") {
                    return formatedTime(row[header.key]);
                } else if (header.key === "checkout") {
                    return formatedTime(row[header.key]);
                }
                return row[header.key];
            });
            csvData.push(rowData);
        });
        const csv = csvData.map(row => row.join(",")).join("\n");
        const blob = new Blob([csv], { type: "text/csv" });
        const url = URL.createObjectURL(blob);
        const downloadLink = document.createElement("a");
        downloadLink.href = url;
        downloadLink.download = filename;
        downloadLink.setAttribute("target", "_blank");
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
        URL.revokeObjectURL(url);
    };

    const handleExportDetailedReport = () => {
        const headers = [
            { label: "Name", key: "name" },
            { label: "Mail", key: "email" },
            { label: "Date", key: "date" },
            { label: "In Time", key: "checkin" },
            { label: "Out Time", key: "checkout" },
            { label: "Total Hours", key: "total_hours" },
        ];
        const filename = `InOutDetailedReport_${name}.csv`;
        generateCSV(employee_face_time_data, headers, filename);
        closeExportMenu();
    };

    const handleExportSummaryReport = () => {
        const headers = [
            { label: "Name", key: "name" },
            { label: "Mail", key: "email" },
            { label: "Date", key: "date" },
            { label: "In Time", key: "InTime" },
            { label: "Out Time", key: "OutTime" },
            { label: "Total Hours", key: "total_hours" },
        ];
        const filename = `InOutSummaryReport_${name}.csv`;
        generateCSV(aggregatedData, headers, filename);
        closeExportMenu();
    };
    return (
        <>
            <Box sx={{ m: 1 }}>
                <Grid sx={{ display: "flex", justifyContent: "space-between" }} container columnSpacing={1} mb={1}>
                    <FormControl>
                        <CommonDatePicker
                            name="month_year"
                            openTo="month"
                            views={['year', 'month']}
                            label="Year and Month"
                            pickerType="date"
                            size="small"
                            onKeyDown={(e) => e.preventDefault()}
                            onKeyPress={(e) => e.preventDefault()}
                            pickerFormat="MM/yyyy"
                            DefaultDTvalue={filters.month_year}
                            maxDate={new Date()}
                            handleChange={(value) =>
                                handleFilter({
                                    target: {
                                        name: "month_year",
                                        value: value
                                            ? `${value?.getFullYear()}-${("0" + (value?.getMonth() + 1)).slice(-2)}-${("0" + value?.getDate()).slice(-2)}`
                                            : "",
                                    },
                                })
                            }
                        />
                    </FormControl>
                    <Menu
                        anchorEl={exportAnchor}
                        open={Boolean(exportAnchor)}
                        onClose={closeExportMenu}
                    >
                        <MenuItem onClick={handleExportDetailedReport}>Download Detailed Report</MenuItem>
                        <MenuItem onClick={handleExportSummaryReport}>Download Summary Report</MenuItem>
                    </Menu>
                </Grid>
            </Box>
            <MaterialTable
                columns={[
                    { title: "Name", field: "name", grouping: false },
                    { title: "Email", field: "email", grouping: false },
                    {
                        title: "Date",
                        field: "date",
                        render: (rowData) => ConvertDateTime(rowData.date),
                    },
                    { title: "In Time", field: "InTime" },
                    { title: "Out Time", field: "OutTime" },
                    { title: "Total Hours", field: "total_hours" },
                ]}
                data={aggregatedData}
                options={{
                    maxBodyHeight: "54vh",
                    minBodyHeight: "54vh",
                    rowStyle: { fontSize: "13px" },
                    headerStyle: { padding: "10px 10px 10px 13px", fontWeight: "bold" },
                    sorting: false,
                    search: true,
                    searchText: "",
                    searchFieldAlignment: "right",
                    searchAutoFocus: true,
                    searchFieldVariant: "standard",
                    filtering: false,
                    paging: true,
                    pageSizeOptions: [25, 50],
                    pageSize: 25,
                    paginationType: "stepped",
                    showFirstLastPageButtons: false,
                    paginationPosition: "bottom",
                    exportButton: false,
                    exportFileName: "Employees",
                    addRowPosition: "first",
                    selection: false,
                    actionsColumnIndex: -1,
                    showSelectAllCheckbox: true,
                    showTextRowsSelected: true,
                    grouping: false,
                    columnsButton: false,

                }}
                actions={[
                    {
                        icon: () => <CloudDownloadIcon />,
                        tooltip: 'Export as CSV',
                        onClick: openExportMenu,
                        isFreeAction: true,
                        hidden: employee_face_time_data.length === 0,
                    },

                ]}
                title="Employee Face Time"
            />
        </>
    );
};

export default EmployeeFaceTime;