import * as ActionTypes from "../ActionTypes";


export const GetEarningAction = (params) => {
    return {
        type: ActionTypes.PAYROLL_EARNING_GET_REQUEST,
        params
    }
}
export const GetDeductionAction = (params) => {
    return {
        type: ActionTypes.PAYROLL_DEDUCTION_GET_REQUEST,
        params
    }
}


