/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import {
  Box,
  Button,
  Tooltip,
  FormControl,
  FormControlLabel,
  FormLabel,
  Paper,
  TextField,
  Typography,
  Radio,
  RadioGroup,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import Grid from "@mui/material/Grid";
import { useState } from "react";
import "../../css/style.css";
import {
  convertBase64,
  s3Client,
  getUserData,
} from "../../../Base/helper/baseFunctions";
import * as faceapi from "face-api.js";
import SelectableSearch from "../../../Base/views/SelectableSearch";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { GetEmployeeBasicAction } from "../../../../../store/Employee/Basic/Action";
import { GetDesignationAction } from "../../../../../store/Ancillary/Designation/Action";
import { createStyles, makeStyles } from "@mui/styles";
import { GetObjectCommand } from "@aws-sdk/client-s3";
import { getSignedUrl } from "@aws-sdk/s3-request-presigner";
import { v4 as uuidv4 } from "uuid";
import { toast } from "react-toastify";
import { useDropzone } from "react-dropzone";
import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate";
import {
  AddEmployeeBasicHelper,
  UpdateEmployeeBasicHelper,
} from "../../helper/EmployeeHelper";
import CommonDatePicker from "../../../Base/views/CommonDatePicker";
import {
  GetEmployeeOtherPersonalAction,
  UpdateEmployeeOtherPersonalAction,
} from "../../../../../store/Employee/OtherPersonal/Action";
import history from "../../../../../history";
import ConfirmationDialog from "../../../Base/views/ConfirmationDialog";
import { GetEmployeesAction } from "../../../../../store/Employee/Employee/Action";
import { AddDesignationHelper } from "../../../Ancillary/Designation/helper/DesignationHelper";
import CreatableSearch from "../../../Base/views/CreatableSearch";
import Popup from "../../../Base/views/Popup";

const BasicDetails = ({
  OnboardEmployee,
  setCurrentMode,
  OnboardingEmpData,
  currentMode,
  setStepper,
  setNewData,
  setActiveStep,
}) => {
  const { atsModuleData } = useSelector(
    (state) => state?.RoleAndAccessReducer?.ATSModuleReducer
  );
  const roleAccess = atsModuleData?.find(
    (item) => item.module_name === "My Employee"
  );

  const [disableEdit, setdisableEdit] = useState(
    currentMode === "addemployee" ? false : true
  );
  const [disableEmployee] = useState(
    OnboardEmployee === true ? true : false
  );
  const EditButton = roleAccess ? true : false;
  const [active] = useState(false);
  
  const useStyles = makeStyles((theme) =>
    createStyles({
      Paper: {
        boxShadow:
          theme.palette.mode === "dark"
            ? "rgb(0 0 0 / 20%) 0px 0px 2px 0px, rgb(0 0 0 / 12%) 0px 12px 24px -4px"
            : "0px 3px 3px -2px #cbc3c3, 0px 3px 4px 0px rgb(242 242 242), 0px 1px 8px 0px rgb(242 242 242)",
        padding: "20px",
        borderRadius: "10px",
      },
      comingSoon: {
        fontWeight: 600,
        fontSize: "18px",
        marginTop: "30px",
        color: "white",
      },
      ActiveStatusDesign: {
        height: "24px",
        minWidth: "22px",
        borderRadius: "6px",
        alignItems: "center",
        display: "inline-flex",
        justifyContent: "center",
        padding: "0px 8px",
        color: active ? "rgb(27, 128, 106)" : "rgb(183, 33, 54)",
        fontSize: "0.75rem",
        backgroundColor: active
          ? "rgba(54, 179, 126, 0.16)"
          : "rgba(255, 72, 66, 0.16)",
        fontWeight: 700,
        textTransform: "uppercase",
      },
    })
  );
  const classes = useStyles();
  const userData = getUserData();
  const initialValue = {
    first_name: "",
    middle_name: "",
    last_name: "",
    employee_no: "",
    office_email: "",
    personal_email: "",
    personal_mobile: "",
    _designation_id: "",
    reportingmanager_id: "",
    image: "",
    isactive: true,
    birthdate: null,
    gender: "",
    emg_contact_no: "",
    emg_contact_person: "",
    joining_date: null,
    uan_no: "",
    pan: "",
  };
  const [openCancel, setOpenCancel] = useState(false);
  const [backList, setBackList] = useState(false);
  const [warning, setWarning] = useState(false);
  const [warningpop, setWarningpop] = useState(false);
  const [inputData, setInputData] = useState(initialValue);
  const [candidateEditedData, setCandidateEditedData] = useState();
  const [imgfiles, setimgFiles] = useState([]);
  const [imageUrl, setImageUrl] = useState("");
  const [errors, setErrors] = useState({});
  const [called, setCalled] = useState(true);
  const [designationData, setDesignationData] = useState({
    load: false,
    values: [],
  });

  const handleEdit = () => {
    setdisableEdit(false);
  };
  const { otherPersonalData } = useSelector(
    (state) => state.EmployeeReducer.EmployeeOtherPersonalReducer
  );
  //Personal Details End
  const dispatch = useDispatch();
  const { isLoading, empData } = useSelector(
    (state) => state?.EmployeeReducer?.EmployeeBasicReducer
  );
  const { designation_options } = useSelector(
    (state) => state?.AncillaryReducer?.DesignationReducer
  );
  const { employees_options } = useSelector(
    (state) => state.EmployeeReducer.EmployeesReducer
  );
  const [employeesData, setEmployeesData] = useState({
    load: true,
    values: [],
  });
  useEffect(() => {
    const loadModels = async () => {
      const MODEL_URL = process.env.PUBLIC_URL + "/models";
      await faceapi.loadTinyFaceDetectorModel(MODEL_URL);
      await faceapi.loadFaceLandmarkModel(MODEL_URL);
      await faceapi.loadFaceRecognitionModel(MODEL_URL);
    };
    loadModels();
  }, []);
  useEffect(() => {
    if (currentMode === "add") {
      setdisableEdit(false);
    } else {
      setdisableEdit(true);
    }
    dispatch(GetEmployeesAction({ is_active: true }));
    isLoading && setInputData(initialValue);
    designation_options?.length === 0 && dispatch(GetDesignationAction());
    if (currentMode !== "addemployee") {
      if (OnboardingEmpData && called) {
        setCalled(false);
        dispatch(
          GetEmployeeBasicAction({
            employee_id: OnboardingEmpData?.employee_id,
          })
        );
        dispatch(
          GetEmployeeOtherPersonalAction({
            domain: userData?.domain,
            employee_id: OnboardingEmpData?.employee_id,
          })
        );
      } else if (called) {
        setCalled(false);
        dispatch(GetEmployeeBasicAction());
        dispatch(
          GetEmployeeOtherPersonalAction({
            domain: userData?.domain,
            employee_id: userData?.employee_id,
          })
        );
      }

      const mergedData = {
        ...empData?.[0],
        _designation_id: empData?.[0]?.designation_id
          ? {
            designation_id: empData?.[0]?.designation_id,
            name: empData?.[0]?.designation,
          }
          : "",
        role_id: empData?.[0]?.role_id
          ? { role_id: empData?.[0]?.role_id, name: empData?.[0]?.role }
          : "",

        domain: userData?.domain,
        ...otherPersonalData?.[0],
        reportingmanager_id: {
          employee_id: otherPersonalData?.[0]?.projectmanager_id,
          name: otherPersonalData?.[0]?.projectmanager_name,
        },
      };
      empData?.[0]?.image && getImageSignedUrl(empData?.[0]);
      setInputData(mergedData);
      setCandidateEditedData(mergedData);
    }
    // eslint-disable-next-line
  }, [!empData, !otherPersonalData]);

  const validate = (fieldValues) => {
    let temp = { ...errors };
    if ("first_name" in fieldValues) {
      temp.first_name =
        fieldValues.first_name === "" || null ? "First Name is required" : "";
    }
    if ("gender" in fieldValues) {
      temp.gender =
        fieldValues.gender === "" || fieldValues.gender === null
          ? "Gender is required"
          : "";
    }
    if ("last_name" in fieldValues) {
      temp.last_name =
        fieldValues.last_name === "" || null ? "Last Name is required" : "";
    }
    if ("office_email" in fieldValues) {
      temp.office_email =
        fieldValues.office_email === "" || null
          ? "Office Email is required"
          : /$^|.+@.+..+/.test(fieldValues.office_email)
            ? ""
            : "Invalid Office Email.";
    }
    if ("personal_mobile" in fieldValues) {
      temp.personal_mobile =
        fieldValues.personal_mobile === "" || null
          ? "Personl Mobile is required"
          : fieldValues?.personal_mobile
            ?.toString()
            .match(/^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/)
            ? ""
            : "Invalid Personl Mobile.";
    }
    //Personal Details Start
    if ("birthdate" in fieldValues) {
      temp.birthdate =
        fieldValues.birthdate === null ? "Birthdate is required" : "";
    }
    if ("_designation_id" in fieldValues) {
      temp._designation_id =
        fieldValues._designation_id === "" || null
          ? "Designation is required"
          : "";
    }
    if ("reportingmanager_id" in fieldValues) {
      temp.reportingmanager_id =
        fieldValues.reportingmanager_id === "" || null
          ? "Reporting Manager is required"
          : "";
    }
    if ("joining_date" in fieldValues) {
      temp.joining_date =
        fieldValues.joining_date === null || fieldValues.joining_date === "" ? "Joining Date is required" : "";
    }
    if ("uan_no" in fieldValues) {
      temp.uan_no =
        fieldValues.uan_no === "" || fieldValues.uan_no === null
          ? "UAN Number is required"
          : fieldValues?.uan_no.toString() !== "" &&
            !fieldValues?.uan_no?.toString()?.match(/^\(?[0-9]{12}$/)
            ? "Invalid UAN Number."
            : "";
    }
    if ("pan" in fieldValues) {
      temp.pan =
        fieldValues.pan === "" || fieldValues.pan === null
          ? "PAN Number is required"
          : fieldValues?.pan.toString() !== "" &&
            !fieldValues?.pan?.toString()?.match(/^[A-Z]{5}[0-9]{4}[A-Z]{1}$/)
            ? "Invalid PAN Number."
            : "";
    }
    //Personal Details End
    setErrors({
      ...temp,
    });
    return Object.values(temp).every((x) => x === "");
  };
  const handleEmployees = () => {
    const filteredData = employees_options.filter((employee) => {
      return (
        (employee.employee_id !== userData?.employee_id &&
          employee.core_hr_user_type !== "Employee") ||
        employee.core_hr_user_type === "Owner"
      );
    });
    employees_options &&
      setEmployeesData({ load: false, values: filteredData });
  };

  const handleInput = (event, value) => {
    if (["reportingmanager_id"].includes(event)) {
      if (value) {
        setInputData({ ...inputData, [event]: value });
      } else {
        setInputData({ ...inputData, [event]: "" });
      }
      Object.values?.(errors)?.find((res) => res !== "")?.length > 0 &&
        validate({ ...inputData, [event]: value ? value : "" });
    } else if (
      ["_designation_id"].includes(
        event
      )
    ) {
      if (value?.id === null) {
        AddDesignationHelper({ name: value?.name }).then((res) => {
          setDesignationData({ load: false, values: res?.data?.data });
          setInputData({
            ...inputData,
            [event]: res?.data?.data?.filter(
              (resp) => resp?.name === value?.name
            )?.[0],
          });
        });
      } else setInputData({ ...inputData, [event]: value });
      Object.values?.(errors)?.find((res) => res !== "")?.length > 0 &&
        validate({ ...inputData, [event]: value ? value : "" });
    }

    else if (["isactive"].includes(event?.target?.name)) {
      setInputData({ ...inputData, [event.target.name]: event.target.checked });
    } else {
      setInputData({ ...inputData, [event.target.name]: event.target.value });
      Object.values?.(errors)?.find((res) => res !== "")?.length > 0 &&
        validate({ ...inputData, [event.target.name]: event.target.value });
    }
  };
  const getImageSignedUrl = async (currentData) => {
    let filename = currentData?.image;
    const bucketParams = {
      Bucket: process.env.REACT_APP_DO_BUCKET_NAME,
      Key: filename,
    };
    try {
      const url = await getSignedUrl(
        s3Client,
        new GetObjectCommand(bucketParams),
        { expiresIn: 3 * 60 }
      ); // Adjustable expiration.
      setImageUrl(url);
      return url;
    } catch (err) {
      toast.error(err);
    }
  };
  const { getRootProps, getInputProps } = useDropzone({
    disabled: disableEdit ? true : false,
    multiple: false,
    maxSize: 2000000,
    accept: {
      "image/png": [".png"],
      "image/jpeg": [".jpg", ".jpeg"],
    },
    onDrop: (acceptedFiles) => {
      setWarning(true)
      setimgFiles(acceptedFiles.map((file) => Object.assign(file)));
    },
    onDropRejected: () => {
      toast.error(
        "You can only image upload image with maximum size of 2 MB.",
        {
          duration: 2000,
        }
      );
    },
  });
  const img = imgfiles.map((file) => (
    <img
      style={{ borderRadius: "50%", padding: "2px" }}
      width="100%"
      height="100%"
      key={file.name}
      alt={file.name}
      className="single-file-image"
      src={URL.createObjectURL(file)}
    />
  ));

  const NextStepper = (e, next) => {
    if (
      !disableEdit &&
      inputData?.employee_id &&
      JSON.stringify(inputData) !== JSON.stringify(candidateEditedData)
    ) {
      handleSubmit(e, next);
    } else {
      if (validate(inputData)) {
        setActiveStep(1);
      }

    }
  };
  const handleCancel = () => {
    if (backList) {
      history.push("/myemployee");
    } else {
      setActiveStep(1);
    }
  };
  const handleBackList = () => {
    setBackList(true);
    if (
      !disableEdit &&
      inputData?.employee_id &&
      JSON.stringify(inputData) !== JSON.stringify(candidateEditedData)
    ) {
      setOpenCancel(true);
    } else history.push("/myemployee");
  };

  const handleSubmit = async (e, next) => {
    if (warning && userData?.facerecognition_flow) {
      setWarningpop(true)
      return
    }
    if (validate(inputData)) {
      let img_filename = "";
      let img_b64string = "";
      let old_image = "";
      let detections;
      let descriptor;
      if (imgfiles?.[0]) {
        img_b64string = await convertBase64(imgfiles?.[0]);
        let ext = imgfiles?.[0]?.name?.split(".").at(-1);
        img_filename = `${userData?.domain}/CoreHr/Employee/Profile/` + uuidv4() + "." + ext;
        const img = await faceapi.bufferToImage(imgfiles?.[0]);
        detections = await faceapi.detectSingleFace(img, new faceapi.TinyFaceDetectorOptions()).withFaceLandmarks().withFaceDescriptor();
        const descriptorArray = detections?.descriptor ? Array.from(detections.descriptor) : "";
        descriptor = detections?.descriptor ? JSON.stringify(descriptorArray) :"";
        if (inputData?.image) {
          old_image = inputData?.image;
        }
      } else {
        img_filename = inputData?.image;
        descriptor = inputData?.descriptor;
      }
      if (currentMode === "addemployee") {
        AddEmployeeBasicHelper({
          ...inputData,
          image: img_filename,
          image_name: img_b64string,
        }).then((res) => {
          if (res?.data?.statuscode === 200) {
            setNewData(res?.data?.data?.[0]);
            dispatch(
              UpdateEmployeeOtherPersonalAction({
                ...inputData,
                domain: userData?.domain,
                employee_id: res?.data?.data?.[0]?.employee_id,
              })
            );
            // setInputData(initialValue);
          }
        });
      } else {
        UpdateEmployeeBasicHelper({
          ...inputData,
          image: img_filename,
          image_name: img_b64string,
          old_image: old_image,
          descriptor: descriptor
        })
          .then((res) => {
            if (res?.data?.statuscode === 200) {
              dispatch(
                UpdateEmployeeOtherPersonalAction({
                  ...inputData,
                  domain: userData?.domain,
                  employee_id: res?.data?.data?.[0]?.employee_id,
                })
              );
              if (!OnboardEmployee) {
                setdisableEdit(true);
              }
            }

          })
          .catch((err) => {
            console.log(err);
          });
      }
      if (next === "Next") {
        setActiveStep(1);
      }
    }
  };
  const handleDelete = (e) => {
    e.preventDefault();
    setimgFiles([]);
    setImageUrl("");
    setInputData((prevData) => ({
      ...prevData,
      image: "", // Setting image to an empty string
    }));
  };
  return (
    <Grid
      sx={{
        padding: OnboardEmployee ? "0px 0px 20px 25px" : "0px 30px 20px 30px",
      }}
      container
    >
      {!OnboardEmployee && (
        <Grid
          container
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginRight: "10px",
            marginTop: "20px",
            width: "100%",
          }}
        >
          <Box>
            {currentMode !== undefined ? (
              <Button
                sx={{ marginRight: "10px" }}
                type="submit"
                variant="outlined"
                size="medium"
                onClick={handleBackList}
              >
                Back to List
              </Button>
            ) : null}
          </Box>

          <Typography variant="h6">Basic Details</Typography>

          <Box>
            {currentMode === "addemployee" && (
              <Button
                type="submit"
                variant="contained"
                size="medium"
                onClick={(e) => handleSubmit(e)}
                className="mpl-primary-btn"
              >
                Create Employee
              </Button>
            )}
            {disableEdit && EditButton && currentMode !== "addemployee" && (
              <Button
                type="submit"
                variant="contained"
                size="medium"
                onClick={(e) => handleEdit(e)}
                className="mpl-primary-btn"
              >
                Edit
              </Button>
            )}
            {!disableEdit && currentMode !== "addemployee" && (
              <Button
                type="submit"
                variant="contained"
                size="medium"
                onClick={(e) => handleSubmit(e)}
                className="mpl-primary-btn"
              >
                Save
              </Button>
            )}
            {currentMode !== "addemployee" && (
              <Button
                sx={{ marginLeft: "10px" }}
                type="submit"
                variant="contained"
                size="medium"
                onClick={(e) => NextStepper(e, "Next")}
                className="mpl-primary-btn"
              >
                Next
              </Button>
            )}
          </Box>
        </Grid>
      )}

      {OnboardEmployee && (
        <Grid
          container
          sx={{
            display: "grid",
            gridTemplateColumns: "1fr auto",
            alignItems: "center",
            marginRight: "10px",
            marginTop: "20px",
            width: "100%",
          }}
        >
          {/* Typography centered */}
          <Typography variant="h6" sx={{ textAlign: "center" }}>
            Basic Details
          </Typography>

          {/* Buttons aligned to the right */}
          <Box sx={{ textAlign: "right" }}>
            <Button
              type="submit"
              variant="contained"
              size="medium"
              onClick={(e) => handleSubmit(e)}
              className="mpl-primary-btn"
            >
              Save
            </Button>

            <Button
              sx={{ marginLeft: "10px" }}
              type="submit"
              variant="contained"
              size="medium"
              onClick={(e) => NextStepper(e, "Next")}
              className="mpl-primary-btn"
            >
              Next
            </Button>
          </Box>
        </Grid>
      )}

      <Grid
        item
        xs={12}
        md={3.5}
        sx={{
          paddingRight: "1.5rem",
          marginTop: "1.5rem",
          paddingBottom: "1.5rem",
        }}
      >
        <Box>
          <Paper
            className={classes.Paper}
            sx={{ padding: "80px 24px 40px", borderRadius: "16px" }}
          >
            <Box
              sx={{
                marginBottom: "5px",
                border: "none !important",
                cursor: "default",
              }}
              {...getRootProps({ className: "dropzone" })}
            >
              <input {...getInputProps()} />
              <div className="presentation">
                <div className="placeholder">
                  {imgfiles.length ? (
                    img
                  ) : inputData?.image ? (
                    <img
                      style={{ borderRadius: "50%", padding: "2px" }}
                      alt="Nothing"
                      width="100%"
                      height="100%"
                      src={imageUrl}
                    />
                  ) : (
                    <>
                      <AddPhotoAlternateIcon />
                      <Typography
                        variant="caption"
                        display="block"
                        gutterBottom
                      >
                        Upload Photo
                      </Typography>
                    </>
                  )}
                </div>
              </div>
            </Box>
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              {(imgfiles?.length > 0 || inputData?.image) &&
                (disableEdit ? (
                  <DeleteIcon
                    sx={{ color: "#919eab", cursor: "not-allowed" }}
                  />
                ) : (
                  <Tooltip title="Delete Image">
                    <DeleteIcon
                      sx={{ cursor: "pointer" }}
                      onClick={handleDelete}
                      color="error"
                    />
                  </Tooltip>
                ))}
            </Box>
            <Typography
              sx={{ margin: "5px auto 0px" }}
              variant="caption"
              className="content-sec"
            >
              Only Allowed *.jpeg, *.jpg, *.png
              <br /> max size of 2 MB
            </Typography>
            <Box>
              <Grid
                item
                xs={12}
                md={6}
                sx={{ paddingRight: "1.5rem", marginTop: "1.5rem" }}
              >
                <FormControl disabled={disableEdit} required>
                  <FormLabel id="demo-row-radio-buttons-group-label">
                    Gender
                  </FormLabel>
                  {errors.gender && (
                    <Typography sx={{ color: "#FF4842", fontSize: "0.66rem" }}>
                      {errors.gender}
                    </Typography>
                  )}
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="gender"
                    onChange={handleInput}
                    value={inputData?.gender}
                  >
                    <FormControlLabel
                      value="Male"
                      control={<Radio />}
                      label="Male"
                    />
                    <FormControlLabel
                      value="Female"
                      control={<Radio />}
                      label="Female"
                    />
                    <FormControlLabel
                      value="Transgender"
                      control={<Radio />}
                      label="Transgender"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
            </Box>
          </Paper>
        </Box>
      </Grid>
      <Grid
        item
        xs={12}
        md={8.5}
        sx={{ marginTop: "1.5rem", paddingBottom: "1.5rem" }}
      >
        <Box>
          <Paper
            className={classes.Paper}
            sx={{ padding: "0px", borderRadius: "16px" }}
          >
            <Box display="flex" justifyContent="flex-end">
              <Typography
                variant="h6"
                title="Employee Number/ID"
                fontWeight={700}
              >
                {inputData?.employee_no || "---"}
              </Typography>
            </Box>
            <Grid container>
              <Grid item xs={12} md={6} sx={{ padding: "0.75rem" }}>
                <TextField
                  disabled={disableEdit || disableEmployee}
                  label="First Name"
                  fullWidth
                  required
                  name="first_name"
                  onChange={handleInput}
                  autoFocus={true}
                  value={inputData?.first_name || ""}
                  size="small"
                  {...(errors.first_name && {
                    error: true,
                    helperText: errors.first_name,
                  })}
                />
              </Grid>
              <Grid item xs={12} md={6} sx={{ padding: "0.75rem" }}>
                <TextField
                  disabled={disableEdit || disableEmployee}
                  label="Last Name"
                  fullWidth
                  name="last_name"
                  required
                  onChange={handleInput}
                  value={inputData?.last_name || ""}
                  size="small"
                  {...(errors.last_name && {
                    error: true,
                    helperText: errors.last_name,
                  })}
                />
              </Grid>
              <Grid item xs={12} md={6} sx={{ padding: "0.75rem" }}>
                <TextField
                  disabled={disableEdit}
                  label="Middle Name"
                  fullWidth
                  name="middle_name"
                  onChange={handleInput}
                  value={inputData?.middle_name || ""}
                  size="small"
                />
              </Grid>
              <Grid item xs={12} md={6} sx={{ padding: "0.75rem" }}>
                <TextField
                  disabled={disableEdit}
                  label="Personal Mobile"
                  fullWidth
                  required
                  name="personal_mobile"
                  onChange={handleInput}
                  value={inputData?.personal_mobile || ""}
                  size="small"
                  {...(errors.personal_mobile && {
                    error: true,
                    helperText: errors.personal_mobile,
                  })}
                />
              </Grid>
              <Grid item xs={12} md={6} sx={{ padding: "0.75rem" }}>
                <TextField
                  disabled={true}
                  label="Official Email"
                  fullWidth
                  name="office_email"
                  required
                  onChange={handleInput}
                  value={inputData?.office_email || ""}
                  size="small"
                  {...(errors.office_email && {
                    error: true,
                    helperText: errors.office_email,
                  })}
                />
              </Grid>
              <Grid item xs={12} md={6} sx={{ padding: "0.75rem" }}>
                <FormControl fullWidth>
                  <CommonDatePicker
                    required
                    disabled={disableEdit || disableEmployee}
                    size="small"
                    autoFocus={true}
                    name="birthdate"
                    label="Birth Date"
                    pickerType="date"
                    onKeyDown={(e) => e.preventDefault()}
                    onKeyPress={(e) => e.preventDefault()}
                    maxDate={new Date().setFullYear(
                      new Date().getFullYear() - 18
                    )}
                    pickerFormat="dd/MM/yyyy"
                    DefaultDTvalue={inputData?.birthdate}
                    handleChange={(value) =>
                      handleInput({
                        target: {
                          name: "birthdate",
                          value: value
                            ? `${value?.getFullYear() +
                            "-" +
                            ("0" + (value.getMonth() + 1)).slice(-2) +
                            "-" +
                            ("0" + value.getDate()).slice(-2)
                            }`
                            : "",
                        },
                      })
                    }
                    {...(errors.birthdate && {
                      error: true,
                      helperText: errors.birthdate,
                    })}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6} sx={{ padding: "0.75rem" }}>
                <CreatableSearch
                  isOptionEqualToValue={(option, value) =>
                    +option._designation_id === +value._designation_id
                  }
                  disabled={disableEdit || disableEmployee}
                  label="Designation"
                  value={inputData?._designation_id}
                  loading={() =>
                    setDesignationData({
                      load: false,
                      values: designation_options,
                    })
                  }
                  fieldLabel="name"
                  required={true}
                  variant={"contained"}
                  name="_designation_id"
                  size="small"
                  onChangeEvent={handleInput}
                  data={designationData}
                  showAddOption={true}
                  OptionName="Type to add new Designation"
                  {...(errors._designation_id && {
                    error: true,
                    helperText: errors._designation_id,
                  })}
                />
              </Grid>
              <Grid item xs={12} md={6} sx={{ padding: "0.75rem" }}>
                <FormControl fullWidth>
                  <SelectableSearch
                    disabled={disableEdit || disableEmployee}
                    label="Reporting Manager"
                    value={inputData?.reportingmanager_id}
                    loading={() => handleEmployees()}
                    fieldLabel="name"
                    required={true}
                    variant={"outlined"}
                    name="reportingmanager_id"
                    size="small"
                    onChangeEvent={handleInput}
                    data={employeesData}
                    {...(errors.reportingmanager_id && {
                      error: true,
                      helperText: errors.reportingmanager_id,
                    })}
                  />
                </FormControl>
              </Grid>

              <Grid item xs={12} md={6} sx={{ padding: "0.75rem" }}>
                <FormControl fullWidth>
                  <CommonDatePicker
                    required
                    disabled={disableEdit || disableEmployee}
                    autoFocus={true}
                    size="small"
                    name="joining_date"
                    label="Date of Joining"
                    pickerType="date"
                    onKeyDown={(e) => e.preventDefault()}
                    onKeyPress={(e) => e.preventDefault()}
                    pickerFormat="dd/MM/yyyy"
                    DefaultDTvalue={inputData?.joining_date}
                    handleChange={(value) =>
                      handleInput({
                        target: {
                          name: "joining_date",
                          value: value
                            ? `${value?.getFullYear() +
                            "-" +
                            ("0" + (value?.getMonth() + 1)).slice(-2) +
                            "-" +
                            ("0" + value?.getDate()).slice(-2)
                            }`
                            : "",
                        },
                      })
                    }
                    {...(errors.joining_date && {
                      error: true,
                      helperText: errors.joining_date,
                    })}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6} sx={{ padding: "0.75rem" }}>
                <FormControl fullWidth>
                  <TextField
                    disabled={disableEdit}
                    size="small"
                    value={inputData?.emg_contact_person || ""}
                    onChange={handleInput}
                    variant="outlined"
                    fullWidth
                    id="name"
                    label="Emergency Contact Person"
                    name="emg_contact_person"
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6} sx={{ padding: "0.75rem" }}>
                <FormControl fullWidth>
                  <TextField
                    disabled={disableEdit}
                    label="Emergency Contact Number"
                    fullWidth
                    name="emg_contact_no"
                    onChange={handleInput}
                    value={inputData?.emg_contact_no || ""}
                    size="small"
                  />
                </FormControl>
              </Grid>

              <Grid item xs={12} md={6} sx={{ padding: "0.75rem" }}>
                <TextField
                  disabled={disableEdit}
                  label="Personal Email"
                  fullWidth
                  name="personal_email"
                  onChange={handleInput}
                  value={inputData?.personal_email || ""}
                  size="small"
                />
              </Grid>
             
             
              <Grid item xs={12} md={6} sx={{ padding: "0.75rem" }}>
                <TextField
                  disabled={disableEdit}
                  label="UAN Number"
                  required
                  fullWidth
                  name="uan_no"
                  onChange={handleInput}
                  value={inputData?.uan_no}
                  size="small"
                  {...(errors.uan_no && {
                    error: true,
                    helperText: errors.uan_no,
                  })}
                />
              </Grid>

              <Grid item xs={12} md={6} sx={{ padding: "0.75rem" }}>
                <TextField
                  disabled={disableEdit}
                  label="PAN Number"
                  fullWidth
                  required
                  name="pan"
                  onChange={handleInput}
                  value={inputData?.pan}
                  size="small"
                  {...(errors.pan && {
                    error: true,
                    helperText: errors.pan,
                  })}
                />
              </Grid>


            </Grid>
          </Paper>
        </Box>
      </Grid>
      <ConfirmationDialog
        title="Unsaved Changes"
        content="You have some unsaved changes. Please click on Save before proceeding"
        openDialog={openCancel}
        closeDialog={setOpenCancel}
        popupTitle="Consultancy"
        OkButtonText="Proceed without Save"
        onSubmit={handleCancel}
        CancelButtonText="OK"
      />
      <Popup
        maxWidth="xs"
        title="⚠️ Alert"
        close={false}
        isMasterProduct={true}
        setOpenPopup={setWarningpop}
        openPopup={warningpop}
      >
        <Typography sx={{ textAlign: "center", fontSize: "18px" }}>
        Note: We use this image for Face Recognition in the Attendance Tracker.
        </Typography>
        <Box sx={{ mt: 2, display: "flex", justifyContent: "center" }}>
          <Button
            variant="contained"
            className="mpl-primary-btn"
            onClick={() => { setWarningpop(false); setWarning(false); }}
          >
            Ok
          </Button>
        </Box>
      </Popup>
    </Grid>
  );
};

export default BasicDetails;
