import React, { useEffect, useState } from "react";
import {
    Button,
    Checkbox,
    FormControl,
    FormControlLabel,
    Grid,
    TextField,
    Divider,
    Typography,
    Box,
    Tooltip,
    IconButton
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import { toast } from "react-toastify";
import { makeStyles } from "@mui/styles";
import AddIcon from "@mui/icons-material/Add";
import SelectableSearch from "../../../Base/views/SelectableSearch";
import DeleteIcon from "@mui/icons-material/Delete";
import { GetDeductionAction, GetEarningAction } from "../../../../../store/Finance/Component/Action";
import { useDispatch, useSelector } from "react-redux";
import Popup from "../../../Base/views/Popup";
import { AddPayrollTemplateAction, GetPayrollTemplateAction } from "../../../../../store/Finance/PayRollTemplate/Action";
import { GetEmployeeTemplateAction } from "../../../../../store/Finance/Employee/Action";
import { GetEmployeesAction } from "../../../../../store/Employee/Employee/Action";
import { AddEmployeeTemplateHelper, UpdateEmployeeTemplateHelper } from "../../helper/Employee/EmployeeTemplateHelper";

const EmployeePayroleTemplate = ({ emp, isonboard, createEmp, setOpenTemplate, openTemplate, currentData, setSuccessPopup, setOpenPopup, setCurrentMode, setInternal }) => {
    const useStyles = makeStyles({
        row: {
            display: "flex",
            flexDirection: "row",
        },
        label: {
            fontSize: "1rem",
            fontWeight: 400,
            width: "170px",
            flexShrink: 0,
            color: "rgb(99, 115, 129)",
            height: "30px",
            lineHeight: "30px",
            margin: "4px 0px 4px 0px",
        },
        netlabel: {
            fontSize: "1rem",
            fontWeight: "bold",
            width: "170px",
            flexShrink: 0,
            color: "#212B36",
            height: "30px",
            lineHeight: "30px",
            margin: "4px 0px 4px 0px",
        },

        value: {
            display: "flex",
            flexFlow: "row wrap",
            alignItems: "center",
            color: "rgb(99, 115, 129)",
        },
    });
    const classes = useStyles();
    const initialValue = {
        template_name: "",
        save_as_template: false,
    };
    const templateEarningsValues = {
        earning_key: "",
        earning_value: "0",
    };
    const templateDeductionValues = {
        deduction_key: "",
        deduction_value: "0",
    };
    const [templateEarningData, setTemplateEarningData] = useState([]);
    const [templateDeductionData, setTemplateDeductionData] = useState([]);
    const [opentempname, setOpenTempname] = useState(false);
    const [templatename, setTemplatename] = useState(initialValue);
    const [inputData, setInputData] = useState({ template_id: "" });
    const [errors, setErrors] = useState({});
    const [templateEarningErrors, setTemplateEarningErrors] = useState([]);
    const [templateDeductionErrors, setTemplateDeductionErrors] = useState([]);
    const [disabledEarningTemplate, setDisabledEarningTemplate] = useState(false);
    const [disabledDeductionTemplate, setDisabledDeductionTemplate] = useState(false);
    const [disabled, setDisabled] = useState(false);
    const [grossEarningsTotal, setGrossEarningsTotal] = useState(0);
    const [grossDeductionsTotal, setGrossDeductionsTotal] = useState(0);
    const [netTotal, setNetTotal] = useState(0);
    const [earningData, setEarningData] = useState({
        load: true,
        values: [],
    });
    const [deductionData, setDeductionData] = useState({
        load: true,
        values: [],
    });
    const [templateData, setTemplateData] = useState({ load: true, values: [] });
    useEffect(() => {
        const allFieldsEmpty = templateEarningData?.every(
            (field) =>
                field?.earning_key !== "" &&
                field?.earning_value !== ""
        );
        setDisabledEarningTemplate(!allFieldsEmpty);
    }, [templateEarningData]);
    useEffect(() => {
        const allFieldsEmpty = templateDeductionData?.every(
            (field) =>
                field?.deduction_key !== "" &&
                field?.deduction_value !== ""
        );
        setDisabledDeductionTemplate(!allFieldsEmpty);
    }, [templateDeductionData]);
    const { earning_data, deduction_data } = useSelector(
        (state) => state?.FinanceReducer.ComponentReducer
    );
    const { employee_template_data } = useSelector(
        (state) => state?.FinanceReducer.EmployeeTemplateReducer
    );
    const { payroll_template_data } = useSelector(
        (state) => state?.FinanceReducer.PayrollTemplateReducer
    );
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(GetEarningAction());
        dispatch(GetDeductionAction());
        dispatch(GetEmployeeTemplateAction({
            employee_id: currentData?.employee_id
        }));
        dispatch(GetPayrollTemplateAction())
    }, []);
    useEffect(() => {
        let totalEarnings = 0;
        let totalDeductions = 0;
        if (employee_template_data?.length > 0) {
            const earnings = employee_template_data[0]?.earnings;
            const deductions = employee_template_data[0]?.deductions;
            if (earnings) {
                const earningFields = Object.entries(earnings)
                    .filter(([key, value]) => value?.value != null)
                    .map(([key, value]) => {
                        return {
                            earning_key: {
                                label: value?.label,
                                name: value?.name,
                            },
                            earning_value: value?.value
                        };
                    });
                setTemplateEarningData(earningFields);
                setDisabled(true);
                totalEarnings = earningFields?.reduce((sum, field) => sum + (field.earning_value || 0), 0);
                setGrossEarningsTotal(totalEarnings);

            }
            if (deductions) {
                const deductionFields = Object.entries(deductions)
                    .filter(([key, value]) => value?.value != null)
                    .map(([key, value]) => {
                        return {
                            deduction_key: {
                                label: value?.label,
                                name: value?.name,
                            },
                            deduction_value: value?.value
                        };
                    });
                setTemplateDeductionData(deductionFields);
                setDisabled(true);
                totalDeductions = deductionFields.reduce((sum, field) => sum + (field.deduction_value || 0), 0);
                setGrossDeductionsTotal(totalDeductions);
            }
            setNetTotal(totalEarnings - totalDeductions);
        } else {
            setDisabled(false);
            setTemplateEarningData([]);
            setTemplateDeductionData([]);
            setGrossEarningsTotal(0);
            setGrossDeductionsTotal(0);
            setNetTotal(0);
        }
    }, [employee_template_data]);
    const handleInputChange = (event, value) => {
        if (["save_as_template"].includes(event?.target?.name)) {
            setTemplatename({
                ...templatename,
                [event.target.name]: event.target.checked,
            });
        } else {
            setTemplatename({
                ...templatename,
                [event.target.name]: event.target.value,
            });
        }
    };
    const handleSubmit = () => {
        if (templatename?.save_as_template) {
            const isEarningsValid = Earningvalidate();
            const isDeductionsValid = Deductionvalidate();
            if (isEarningsValid && isDeductionsValid) {
                setOpenTempname(true)
            }
        }
        else {
            const isEarningsValid = Earningvalidate();
            const isDeductionsValid = Deductionvalidate();
            if (isEarningsValid && isDeductionsValid) {
                const EarningformattedData = templateEarningData?.reduce((acc, item) => {
                    if (item.earning_key?.name && item.earning_value) {
                        acc[item.earning_key.name] = item.earning_value;
                    }
                    return acc;
                }, {});
                const DeductionformattedData = templateDeductionData?.reduce((acc, item) => {
                    if (item.deduction_key?.name && item.deduction_value) {
                        acc[item.deduction_key.name] = item.deduction_value;
                    }
                    return acc;
                }, {});
                if (employee_template_data?.length > 0) {
                    UpdateEmployeeTemplateHelper({
                        earnings: JSON.stringify([EarningformattedData]),
                        deductions: JSON.stringify([DeductionformattedData]),
                        employee_id: currentData?.employee_id,
                    }).then((res) => {
                        if (res?.data?.statuscode === 200) {
                            if (emp) {
                                dispatch(GetEmployeesAction({ is_active: true }));
                            }
                        }
                    });
                }
                else {
                    AddEmployeeTemplateHelper({
                        earnings: JSON.stringify([EarningformattedData]),
                        deductions: JSON.stringify([DeductionformattedData]),
                        employee_id: currentData?.employee_id,
                    }).then((res) => {
                        if (res?.data?.statuscode === 200) {
                            if (emp) {
                                dispatch(GetEmployeesAction({ is_active: true }));
                            }
                        }
                    });
                }
                setOpenTemplate(false);
                if(emp){
                    dispatch(GetEmployeesAction({ is_active: true }));
                }
                if (createEmp) {
                    setOpenPopup(false);
                    setSuccessPopup(false)
                }
                else if (isonboard) {
                    setCurrentMode("grid");
                    setInternal(true)
                }
            }
        }
    };
    const validate = (fieldValues) => {
        let temp = { ...errors };
        if ("template_name" in fieldValues) {
            temp.template_name = fieldValues.template_name === "" ? "Template Name is required" : "";
        }
        setErrors({
            ...temp,
        });
        return Object.values(temp).every((x) => x === "");
    };
    const handleCreateTemplate = () => {

        if (validate(templatename) && validateTemplateName(templatename)) {
            const EarningformattedData = templateEarningData?.reduce((acc, item) => {
                if (item.earning_key?.name && item.earning_value) {
                    acc[item.earning_key.name] = item.earning_value;
                }
                return acc;
            }, {});
            const DeductionformattedData = templateDeductionData?.reduce((acc, item) => {
                if (item.deduction_key?.name && item.deduction_value) {
                    acc[item.deduction_key.name] = item.deduction_value;
                }
                return acc;
            }, {});
            const earningKeys = templateEarningData?.map(item => item.earning_key).filter(Boolean);
            const deductionKeys = templateDeductionData?.map(item => item.deduction_key).filter(Boolean);
            const templateFields = JSON.stringify({
                earning_key: earningKeys,
                deduction_key: deductionKeys
            });

            dispatch(AddPayrollTemplateAction({
                template_name: templatename?.template_name,
                template_fields: templateFields
            }));
            if (employee_template_data?.length > 0) {
                UpdateEmployeeTemplateHelper({
                    earnings: JSON.stringify([EarningformattedData]),
                    deductions: JSON.stringify([DeductionformattedData]),
                    employee_id: currentData?.employee_id,
                }).then((res) => {
                    if (res?.data?.statuscode === 200) {
                        if (emp) {
                            dispatch(GetEmployeesAction({ is_active: true }));
                        }
                    }
                });
            }
            else {
                AddEmployeeTemplateHelper({
                    earnings: JSON.stringify([EarningformattedData]),
                    deductions: JSON.stringify([DeductionformattedData]),
                    employee_id: currentData?.employee_id,
                }).then((res) => {
                    if (res?.data?.statuscode === 200) {
                        if (emp) {
                            dispatch(GetEmployeesAction({ is_active: true }));
                        }
                    }
                });
            }
            setOpenTemplate(false);
            setOpenTempname(false);
            if(emp){
                dispatch(GetEmployeesAction({ is_active: true }));
            }
            if (createEmp) {
                setOpenPopup(false);
                setSuccessPopup(false)
            }
            else if (isonboard) {
                setCurrentMode("grid");
                setInternal(true)
            }
        }
    };
    //Earning Template Name Section
    const handleAddEarningFields = () => {
        setTemplateEarningData((state) => [...state, templateEarningsValues]);
    };
    const handleEarningDelete = (e, id, deleteid) => {
        let Data = [...templateEarningData];
        Data.splice(id, 1);
        setTemplateEarningData(Data);
        const newGrossEarningsTotal = Data.reduce((total, item) => {
            return total + (parseFloat(item.earning_value) || 0); // Add the valid values
        }, 0);
        setGrossEarningsTotal(newGrossEarningsTotal);
        const newNetTotal = newGrossEarningsTotal - grossDeductionsTotal;
        setNetTotal(newNetTotal);
    };
    const Earningvalidate = () => {
        let isValid = true;
        const newErrors = [...templateEarningErrors];
        templateEarningData?.forEach((store, index) => {
            const error = {};
            if (!store.earning_key) {
                error.earning_key = "Name is required";
                isValid = false;
            }
            if (!store.earning_value) {
                error.earning_value = "Value is required";
                isValid = false;
            }
            while (newErrors.length <= index) {
                newErrors.push({});
            }
            newErrors[index] = error;
        });
        setTemplateEarningErrors(newErrors);
        return isValid;
    };
    const handleEarningChange = (e, value, id) => {
        let newArr = [...templateEarningData];
        const newErrors = [...templateEarningErrors];
        let item = newArr[id];
        item = { ...item, [e]: value };
        newArr[id] = item;
        newErrors[id] = { ...newErrors[id], [e]: "" };
        setTemplateEarningData(newArr);
        setTemplateEarningErrors(newErrors);
        const newGrossEarningTotal = newArr.reduce((total, item) => {
            return total + (parseFloat(item.earning_value) || 0);
        }, 0);
        setGrossEarningsTotal(newGrossEarningTotal);
        const newNetTotal = newGrossEarningTotal - grossDeductionsTotal;
        setNetTotal(newNetTotal);
    }
    const handleEarnings = () => {
        earning_data &&
            setEarningData({ load: false, values: earning_data?.[0]?.fn_get_earning_components });
    };
    const getFilteredEarnings = (id) => {
        const selectedEarnings = templateEarningData.map((item, index) => index !== id && item.earning_key?.name).filter(Boolean);
        return earningData.values.filter(
            (option) => !selectedEarnings.includes(option.name)
        );
    };

    //Deduction Template Name Section
    const handleDeduction = () => {
        deduction_data &&
            setDeductionData({ load: false, values: deduction_data?.[0]?.fn_get_deduction_components });
    };
    const handleAddDeductionFields = () => {
        setTemplateDeductionData((state) => [...state, templateDeductionValues]);
    };
    const handleDeductionDelete = (e, id, deleteid) => {
        let Data = [...templateDeductionData];
        Data.splice(id, 1);
        setTemplateDeductionData(Data);
        const newGrossDeductionTotal = Data.reduce((total, item) => {
            return total + (parseFloat(item.deduction_value) || 0); // Add the valid values
        }, 0);
        setGrossDeductionsTotal(newGrossDeductionTotal);
        const newNetTotal = grossEarningsTotal - newGrossDeductionTotal;
        setNetTotal(newNetTotal);
    };
    const Deductionvalidate = () => {
        let isValid = true;
        const newErrors = [...templateDeductionErrors];
        templateDeductionData?.forEach((store, index) => {
            const error = {};
            if (!store.deduction_key) {
                error.deduction_key = "Name is required";
                isValid = false;
            }
            if (!store.deduction_value) {
                error.deduction_value = "Value is required";
                isValid = false;
            }
            while (newErrors.length <= index) {
                newErrors.push({});
            }
            newErrors[index] = error;
        });
        setTemplateDeductionErrors(newErrors);
        return isValid;
    };
    const handleDeductionChange = (e, value, id) => {
        let newArr = [...templateDeductionData];
        const newErrors = [...templateDeductionErrors];
        let item = newArr[id];
        item = { ...item, [e]: value };
        newArr[id] = item;
        newErrors[id] = { ...newErrors[id], [e]: "" };
        setTemplateDeductionData(newArr);
        setTemplateDeductionErrors(newErrors);
        const newGrossDeductionTotal = newArr.reduce((total, item) => {
            return total + (parseFloat(item.deduction_value) || 0);
        }, 0);
        setGrossDeductionsTotal(newGrossDeductionTotal);
        setNetTotal(grossEarningsTotal - newGrossDeductionTotal);
    }
    const getFilteredDeduction = (id) => {
        const selectedDeduction = templateDeductionData.map((item, index) => index !== id && item.deduction_key?.name).filter(Boolean);
        return deductionData.values.filter(
            (option) => !selectedDeduction.includes(option.name)
        );
    };

    const handleTemplate = () => {
        payroll_template_data &&
            setTemplateData({ load: false, values: payroll_template_data });
    };
    const handleInput = (event, value) => {
        const selectedTemplate = payroll_template_data?.find(t => t.template_id === value?.template_id);
        if (["template_id"].includes(event)) {
            if (value) {
                setInputData({ ...inputData, [event]: value });
            } else {
                setInputData({ ...inputData, [event]: "" });
            }
        }
        if (selectedTemplate) {
            setTemplateEarningData(selectedTemplate?.template_fields?.earning_key?.map(earning => ({
                earning_key: {
                    name: earning?.name,
                    label: earning?.label
                },
                earning_value: "0"
            })));
            setTemplateDeductionData(selectedTemplate?.template_fields?.deduction_key?.map(deduction => ({
                deduction_key: { name: deduction?.name, label: deduction?.label },
                deduction_value: "0"
            })));
            setGrossEarningsTotal(0);
            setGrossDeductionsTotal(0);
            setNetTotal(0)
        }
    }
    const validateTemplateName = (fieldValues) => {
        const templateExists = payroll_template_data?.some(template => template.template_name === fieldValues?.template_name);
        if (templateExists) {
            toast.error("Template name is already taken", { duration: 2000, });
            return false;
        }
        return true;
    }
    const handleEdit = () => {
        setDisabled(false)
    }

    return (
        <>
            <Grid container>
                <Grid container>
                    <Grid
                        item
                        xs={12}
                        md={6}
                        sx={{
                            marginTop: "1rem",
                            paddingRight: "15px",
                            paddingLeft: "15px",
                        }}
                    >
                        {(payroll_template_data?.length > 0 && !disabled) && (
                            <FormControl fullWidth>
                                <SelectableSearch
                                    isOptionEqualToValue={(option, value) =>
                                        +option.template_id === +value.template_id
                                    }
                                    label="Select from Saved Templates"
                                    fullWidth
                                    disabled={disabled}
                                    value={inputData?.template_id}
                                    loading={() => handleTemplate()}
                                    fieldLabel="template_name"
                                    required={false}
                                    variantType={"standard"}
                                    name="template_id"
                                    size="small"
                                    onChangeEvent={handleInput}
                                    data={templateData}
                                />
                            </FormControl>
                        )}
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        md={6}
                        sx={{
                            marginTop: "1rem",
                            paddingRight: "15px",
                            paddingLeft: "15px",
                            display: "flex",
                            justifyContent: "flex-end"
                        }}
                    >
                        {employee_template_data?.length > 0 && (
                            <EditIcon
                                onClick={(e) => handleEdit(e)}
                                sx={{ margin: "15px 15px 0px 15px", cursor: "pointer" }}
                                fontSize="small"
                            />
                        )}
                    </Grid>
                </Grid>

                <Grid
                    item
                    xs={12}
                    md={6}
                    sx={{
                        marginTop: "1rem",
                        paddingRight: "15px",
                        paddingLeft: "15px",
                    }}
                >
                    <Box sx={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "space-between",
                    }}>
                        <Typography
                            variant="h6"
                            gutterBottom
                        >
                            Earnings
                        </Typography>
                        <Tooltip title="Add">
                            <IconButton
                                disabled={disabledEarningTemplate || disabled}
                                className="mpl-primary-btn"
                                onClick={handleAddEarningFields}
                                style={{ padding: '4px', minWidth: '30px', height: '30px' }}
                            >
                                <AddIcon fontSize="small" />
                            </IconButton>
                        </Tooltip>
                    </Box>
                </Grid>
                <Grid
                    item
                    xs={12}
                    md={6}
                    sx={{
                        marginTop: "1rem",
                        paddingRight: "15px",
                        paddingLeft: "15px",
                    }}
                >
                    <Typography
                        variant="h6"
                        gutterBottom
                    >
                        Monthly
                    </Typography>
                </Grid>
            </Grid>
            {templateEarningData?.map((res, id) => {
                return (
                    <>
                        <Grid container>
                            <Grid
                                item
                                xs={12}
                                md={6}
                                sx={{
                                    marginTop: "1rem",
                                    paddingRight: "15px",
                                    paddingLeft: "15px",
                                }}
                            >
                                <FormControl fullWidth>
                                    <SelectableSearch
                                        isOptionEqualToValue={(option, value) =>
                                            +option.label === +value.label
                                        }
                                        label="Select an Earnings"
                                        fullWidth
                                        disabled={disabled}
                                        value={res?.earning_key}
                                        loading={() => handleEarnings()}
                                        fieldLabel="label"
                                        required={false}
                                        variantType={"standard"}
                                        name="earning_key"
                                        size="small"
                                        onChangeEvent={(e, newValue) =>
                                            handleEarningChange("earning_key", newValue, id)  // Handle change
                                        }
                                        data={{ load: earningData.load, values: getFilteredEarnings(id) }}
                                        {...(templateEarningErrors[id] &&
                                            templateEarningErrors[id].earning_key && {
                                            error: true,
                                            helperText: templateEarningErrors[id].earning_key,
                                        })}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                md={5}
                                sx={{
                                    marginTop: "32px",
                                    paddingRight: "15px",
                                    paddingLeft: "15px",
                                }}
                            >
                                <FormControl fullWidth>
                                    <TextField
                                        size="small"
                                        required
                                        value={res.earning_value}
                                        onChange={(e) => {
                                            const value = e.target.value;
                                            if (/^\d*\.?\d*$/.test(value)) {
                                                handleEarningChange(e.target.name, value, id);
                                            }
                                        }}
                                        variant={"standard"}
                                        fullWidth
                                        disabled={disabled}
                                        id="earning_value"
                                        label=""
                                        name="earning_value"
                                        autoComplete="earning_value"
                                        inputProps={{
                                            inputMode: 'decimal',
                                            pattern: "[0-9]*",
                                            step: "0.01"
                                        }}
                                        {...(templateEarningErrors[id] &&
                                            templateEarningErrors[id].earning_value && {
                                            error: true,
                                            helperText: templateEarningErrors[id].earning_value,
                                        })}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={6} md={1}>
                                <Box
                                    sx={{
                                        marginTop: "40px",
                                        display: "flex",
                                        justifyContent: "flex-end",
                                        alignItems: "flex-end",
                                        cursor: disabled ? "not-allowed" : "pointer",
                                        opacity: disabled ? 0.5 : 1,
                                    }}
                                >
                                    <DeleteIcon color="error" onClick={(e) => {
                                        if (!disabled) {
                                            handleEarningDelete(e, id, res?.certificate_id);
                                        }
                                    }} fontSize="small" />

                                </Box>
                            </Grid>
                        </Grid>
                    </>
                );
            })}
            <Divider sx={{ mt: 2 }} />
            <Grid container>
                <Grid
                    item
                    xs={12}
                    md={6}
                    sx={{
                        marginTop: "1rem",
                        paddingRight: "15px",
                        paddingLeft: "15px",
                    }}
                >
                    <Box sx={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "space-between",
                    }}>
                        <Typography
                            variant="h6"
                            gutterBottom
                        >
                            Deduction
                        </Typography>
                        <Tooltip title="Add">
                            <IconButton
                                disabled={disabledDeductionTemplate || disabled}
                                className="mpl-primary-btn"
                                style={{ padding: '4px', minWidth: '30px', height: '30px' }}
                                onClick={handleAddDeductionFields}
                            >
                                <AddIcon fontSize="small" />
                            </IconButton>
                        </Tooltip>
                    </Box>
                </Grid>
                <Grid
                    item
                    xs={12}
                    md={6}
                    sx={{
                        marginTop: "1rem",
                        paddingRight: "15px",
                        paddingLeft: "15px",
                    }}
                >
                    <Typography
                        variant="h6"
                        gutterBottom
                    >
                        Monthly
                    </Typography>
                </Grid>
            </Grid>
            {templateDeductionData?.map((res, id) => {
                return (
                    <>
                        <Grid container>
                            <Grid
                                item
                                xs={12}
                                md={6}
                                sx={{
                                    marginTop: "1rem",
                                    paddingRight: "15px",
                                    paddingLeft: "15px",
                                }}
                            >
                                <FormControl fullWidth>
                                    <SelectableSearch
                                        isOptionEqualToValue={(option, value) =>
                                            +option.label === +value.label
                                        }
                                        label="Select an Deductions"
                                        fullWidth
                                        disabled={disabled}
                                        value={res?.deduction_key}
                                        loading={() => handleDeduction()}
                                        fieldLabel="label"
                                        required={false}
                                        variantType={"standard"}
                                        name="deduction_key"
                                        size="small"
                                        onChangeEvent={(e, newValue) =>
                                            handleDeductionChange("deduction_key", newValue, id)  // Handle change
                                        }
                                        data={{ load: deductionData.load, values: getFilteredDeduction(id) }}
                                        {...(templateDeductionErrors[id] &&
                                            templateDeductionErrors[id].deduction_key && {
                                            error: true,
                                            helperText: templateDeductionErrors[id].deduction_key,
                                        })}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                md={5}
                                sx={{
                                    marginTop: "32px",
                                    paddingRight: "15px",
                                    paddingLeft: "15px",
                                }}
                            >
                                <FormControl fullWidth>
                                    <TextField
                                        size="small"
                                        required
                                        value={res.deduction_value}
                                        onChange={(e) => {
                                            const value = e.target.value;
                                            if (/^\d*\.?\d*$/.test(value)) {
                                                handleDeductionChange(e.target.name, value, id);
                                            }
                                        }}
                                        variant={"standard"}
                                        fullWidth
                                        id="deduction_value"
                                        disabled={disabled}
                                        label=""
                                        name="deduction_value"
                                        autoComplete="deduction_value"
                                        inputProps={{
                                            inputMode: 'decimal',
                                            pattern: "[0-9]*",
                                            step: "0.01"
                                        }}
                                        {...(templateDeductionErrors[id] &&
                                            templateDeductionErrors[id].deduction_value && {
                                            error: true,
                                            helperText: templateDeductionErrors[id].deduction_value,
                                        })}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={6} md={1}>
                                <Box
                                    sx={{
                                        marginTop: "40px",
                                        display: "flex",
                                        justifyContent: "flex-end",
                                        alignItems: "flex-end",
                                        cursor: disabled ? "not-allowed" : "pointer",
                                        opacity: disabled ? 0.5 : 1,
                                    }}
                                >
                                    <DeleteIcon disabled={disabled} color="error" onClick={(e) => {
                                        if (!disabled) {
                                            handleDeductionDelete(e, id, res?.certificate_id);
                                        }
                                    }} fontSize="small" />

                                </Box>
                            </Grid>
                        </Grid>
                    </>
                );
            })}
            <Grid container>
                <Grid
                    item
                    xs={12}
                    md={6}
                    sx={{
                        marginTop: "1rem",
                        paddingRight: "15px",
                        paddingLeft: "15px",
                    }}
                />
                <Grid
                    item
                    xs={12}
                    md={6}
                    sx={{
                        marginTop: "1rem",
                        paddingRight: "15px",
                        paddingLeft: "15px",
                    }}
                >
                    <div className={classes.row}>
                        <span className={classes.label}> Gross Earning Total </span>
                        <div className={classes.value}>: &nbsp; {grossEarningsTotal}</div>
                    </div>
                    <div className={classes.row}>
                        <span className={classes.label}>  Gross Deduction Total &nbsp;</span>
                        <div className={classes.value}>: &nbsp;  {grossDeductionsTotal}</div>
                    </div>
                    <Divider sx={{ mt: 2 }} />
                    <div className={classes.row}>
                        <span className={classes.netlabel}> Net Total </span>
                        <div className={classes.value}>: &nbsp;  {netTotal}</div>
                    </div>
                </Grid>
            </Grid>


            <Box sx={{
                width: "100%",
                mt: 3,
                display: "flex",
                justifyContent: "space-between",
            }}>
                <FormControlLabel
                    control={
                        <Checkbox
                            disabled={netTotal === 0 || disabled}
                            name="save_as_template"
                            checked={templatename?.save_as_template || false}
                            onChange={handleInputChange}
                        />
                    }
                    label="Save as Template"
                />
                <Button disabled={netTotal === 0 || disabled} onClick={handleSubmit} size="small" variant="outlined">
                    Submit
                </Button>
            </Box>
            <Popup
                maxWidth="sm"
                fullWidth={true}
                title="Enter Template Name"
                openPopup={opentempname}
                setOpenPopup={setOpenTempname}
            >
                <FormControl fullWidth>
                    <TextField
                        size="small"
                        required
                        value={templatename?.template_name}
                        onChange={handleInputChange}
                        variant="outlined"
                        fullWidth
                        id="template_name"
                        label="Template Name"
                        name="template_name"
                        autoComplete="template_name"
                        {...(errors.template_name && {
                            error: true,
                            helperText: errors.template_name,
                        })}
                    />
                </FormControl>
                <Box sx={{
                    width: "100%",
                    mt: 3,
                    display: "flex",
                    justifyContent: "space-between",
                }}>
                    <Button onClick={() => { setOpenTempname(false); }} size="small" variant="outlined">
                        Cancel
                    </Button>
                    <Button onClick={handleCreateTemplate} size="small" variant="outlined">
                        Save
                    </Button>
                </Box>
            </Popup>
        </>
    );
}

export default EmployeePayroleTemplate;
