import UnauthorizedBase from "../../Base/views/UnauthorizedBase";
import {
  TextField,
  Typography,
  Button,
  Box,
  Card,
  Tooltip,
  IconButton,
  InputAdornment,
  CardContent,
  CardMedia,
  FormControl,
} from "@mui/material";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { useState } from "react";
import SelectableSearch from "../../Base/views/SelectableSearch";
import { addDemoRequestHelper } from "../helper/SignUpHelper";
import RequestSvg from "../../../../svg/RequestSvg";
import history from "../../../../history";
import { useLocation } from "react-router-dom";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { getSessionData } from "../../Base/helper/baseHelper";

const RequestDemo = () => {
  const location = useLocation();
  const CountryId = getSessionData({ key: "CountryId" });
  const defaultPlan = location.state?.defaultPlan || "";
  const defaultteamsize = location.state?.defaultteamsize || "";
  const Defaultdisable = location.state?.defaultdisable || false;
  const initialValues = {
    company_name: "",
    country_code: "",
    contactno: "",
    emailid: "",
    teamsize: defaultteamsize,
    plan_type: defaultPlan,
    first_name: "",
    last_name:""
  };

  const options = [
    { value: "Upto 10", label: "Upto 10" },
    { value: "11 to 25", label: "11 to 25" },
    { value: "26 to 50", label: "26 to 50" },
    { value: "51 to 100", label: "51 to 100" },
    { value: "101 to 250", label: "101 to 250" },
    { value: "251 to 500", label: "251 to 500" },
  ];
  const plansoptions = [
    { value: "Starter", label: "Starter" },
    { value: "Growth", label: "Growth" },
    { value: "Premium", label: "Premium" },
  ];

  const [signUpData, setSignUpData] = useState(initialValues);
  const [teamOptions, setTeamOptions] = useState({ load: true, values: [] });
  const [plansOptions, setPlansOptions] = useState({ load: true, values: [] });
  const [errors, setErrors] = useState({});

  const handleChange = (e, value, name) => {
    if (e === "teamsize" || e === "plan_type") {
      if (value) {
        setSignUpData({ ...signUpData, [e]: value });
        if (e === "teamsize") {
          setSignUpData({ ...signUpData, [e]: value, plan_type: "" });
          const updatedPlansOptions =
            value?.value === "Upto 10" ? plansoptions : plansoptions.slice(1);
          setPlansOptions({ load: false, values: updatedPlansOptions });
        }
      } else setSignUpData({ ...signUpData, [e]: "" });
    } else if (name === "contactno") {
      let splitMobile = e?.split(value?.dialCode);
      setSignUpData({
        ...signUpData,
        country_code: value?.dialCode,
        contactno: splitMobile?.[1] || "",
      });
    } else setSignUpData({ ...signUpData, [e.target.name]: e.target.value });
  };

  const validate = (fieldValues) => {
    let temp = { ...errors };
    if ("first_name" in fieldValues) {
      temp.first_name =
        fieldValues.first_name?.trim() === "" ? "First Name is required" : "";
    }
    if ("company_name" in fieldValues) {
      temp.company_name =
        fieldValues.company_name?.trim() === ""
          ? "Company Name is required"
          : "";
    }
    if ("teamsize" in fieldValues) {
      temp.teamsize =
        fieldValues.teamsize === "" ? "Team Size is required" : "";
    }
    if ("plan_type" in fieldValues) {
      temp.plan_type =
        fieldValues.plan_type === "" ? "Choose Plan is required" : "";
    }
    if ("contactno" in fieldValues) {
      temp.contactno =
        fieldValues.contactno === "" ? "Contact Number is required" : "";
    }
    if ("emailid" in fieldValues) {
      temp.emailid =
        fieldValues.emailid === ""
          ? "Email is required"
          : /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(
              fieldValues.emailid
            )
          ? ""
          : "Invalid Email.";
    }
    setErrors({
      ...temp,
    });
    return Object.values(temp).every((x) => x === "");
  };

  const handleSubmit = () => {
    if (validate(signUpData)) {
      addDemoRequestHelper({
        first_name: signUpData?.first_name,
        last_name: signUpData?.last_name,
        emailid: signUpData?.emailid,
        country_code: signUpData?.country_code,
        contactno: signUpData?.contactno,
        company_name: signUpData?.company_name,
        teamsize: signUpData?.teamsize?.value,
        plan_type: signUpData?.plan_type?.value,
      }).then((res) => {
        if (res.message?.statuscode === 200) {
          setSignUpData(initialValues);
          setTimeout(() => {
            history.push("/");
          }, 2000);
        }
      });
    }
  };

  return (
    <>
      <UnauthorizedBase>
        <Box sx={{ display: "flex", justifyContent: "center", p: 3 }}>
          <Card
            sx={{
              boxShadow:
                "rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px",
              p: 5,
              width: "28rem",
            }}
          >
            <CardMedia sx={{ p: 2, textAlign: "center" }}>
              <div style={{ marginBottom: "40px", height: "126px" }}>
                <RequestSvg />
              </div>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Typography variant="h3">Signup For Demo</Typography>
              </Box>
            </CardMedia>
            <CardContent>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                }}
              >
                <TextField
                  type="text"
                  placeholder="Enter First Name"
                  label="First Name"
                  required
                  name="first_name"
                  onChange={handleChange}
                  value={signUpData.first_name}
                  {...(errors.first_name && {
                    error: true,
                    helperText: errors.first_name,
                  })}
                />
                 
                 <TextField
                  sx={{ mt: 2 }}
                  type="text"
                  placeholder="Enter Last Name"
                  label="Last Name"
                  required
                  name="last_name"
                  onChange={handleChange}
                  value={signUpData.last_name}
                  {...(errors.last_name && {
                    error: true,
                    helperText: errors.last_name,
                  })}
                />
                  
                <TextField
                  sx={{ mt: 2, mb: 2 }}
                  type="email"
                  required
                  placeholder="Enter Email ID"
                  label="Email"
                  name="emailid"
                  onChange={handleChange}
                  value={signUpData.emailid}
                  {...(errors.emailid && {
                    error: true,
                    helperText: errors.emailid,
                  })}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Tooltip title="Please Enter Office Email">
                         <IconButton>
                                <InfoOutlinedIcon fontSize="small" />
                            </IconButton>
                            </Tooltip>
                      </InputAdornment>
                      
                    ),
                    maxLength: 4,
                  }}
                />
                <PhoneInput
                  inputProps={{
                    name: "contactno",
                    required: true,
                    autoFocus: true,
                  }}
                  country={CountryId === "AU" ? "au":"in" }
                  value={`${signUpData.country_code}${signUpData.contactno}`}
                  onChange={(e, phone) => handleChange(e, phone, "contactno")}
                  inputStyle={{
                    height: "55px",
                    width: "100%",
                    paddingLeft: "45px",
                    borderRightRadius: "8px",
                  }}
                />
                <TextField
                  sx={{ mt: 2 }}
                  type="text"
                  required
                  placeholder="Enter Company Name"
                  label="Company Name"
                  name="company_name"
                  onChange={handleChange}
                  value={signUpData.company_name}
                  {...(errors.company_name && {
                    error: true,
                    helperText: errors.company_name,
                  })}
                />
                <FormControl sx={{ mt: 2 }}>
                  <SelectableSearch
                    disabled={Defaultdisable}
                    label="Team Size"
                    fieldLabel="label"
                    loading={() =>
                      setTeamOptions({ load: false, values: options })
                    }
                    name="teamsize"
                    data={teamOptions}
                    required
                    size="medium"
                    value={signUpData?.teamsize}
                    onChangeEvent={handleChange}
                    {...(errors.teamsize && {
                      error: true,
                      helperText: errors.teamsize,
                    })}
                  />
                </FormControl>
                <FormControl sx={{ mt: 2 }}>
                  <SelectableSearch
                    disabled={Defaultdisable}
                    label="Choose Plan"
                    fieldLabel="label"
                    loading={() =>
                      setPlansOptions({ load: false, values: plansoptions })
                    }
                    name="plan_type"
                    data={plansOptions}
                    required
                    size="medium"
                    value={signUpData?.plan_type}
                    onChangeEvent={handleChange}
                    {...(errors.plan_type && {
                      error: true,
                      helperText: errors.plan_type,
                    })}
                  />
                </FormControl>
                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                  <Button
                    className="mpl-primary-btn"
                    variant="contained"
                    sx={{ mt: 3, height: "3rem" }}
                    onClick={() => history.push("/")}
                    href="#Pricing"
                  >
                    Back
                  </Button>
                  <Button
                    className="mpl-primary-btn"
                    variant="contained"
                    sx={{ mt: 3, height: "3rem" }}
                    onClick={handleSubmit}
                    disabled={Object.values(signUpData)?.includes("")}
                  >
                    Signup
                  </Button>
                </Box>
              </Box>
            </CardContent>
          </Card>
        </Box>
      </UnauthorizedBase>
    </>
  );
};

export default RequestDemo;
