import { toast } from "react-toastify";
import { getUserData } from "../../../Base/helper/baseFunctions";
import axiosInstance from "../../../../../axiosInstance";
import { HRMS_FINANCE_API_URL } from "../../../../Constants/baseConstant";

export const AddEmployeeTemplateHelper = async (params) => {
    const data = getUserData();
    toast.dismiss()
    toast.info("Saving...")
    return await axiosInstance(`/finance/addEmployeeTemplate`, {
        method: "POST",
        baseURL: HRMS_FINANCE_API_URL,
        data: {
            domain_name: data?.domain,
            user_id: data?.employee_id,
            earnings: params?.earnings,
            deductions: params?.deductions,
            employee_id: params?.employee_id
        }
    })
        .then((res) => {
            toast.dismiss()
            toast.success("Template Added Successfully")
            return { message: "Template Added Successfully", hasError: false, data: res?.data }
        }).catch(err => {
            toast.dismiss()
            toast.error(err?.response?.data?.error?.[0]?.message)
            return { message: "Unable to add Template", hasError: false, data: err?.response?.data }
        })
}

export const UpdateEmployeeTemplateHelper = async (params) => {
    const data = getUserData();
    toast.dismiss()
    toast.info("Saving...")
    return await axiosInstance(`/finance/updateEmployeeTemplate`, {
        method: "PUT",
        baseURL: HRMS_FINANCE_API_URL,
        data: {
            domain_name: data?.domain,
            user_id: data?.employee_id,
            employee_id: params?.employee_id,
            earnings: params?.earnings,
            deductions: params?.deductions,
          
        }
    })
        .then((res) => {
            toast.dismiss()
            toast.success("Template Updated Successfully")
            return { message: "Template Updated Successfully", hasError: false, data: res?.data }
        }).catch(err => {
            toast.dismiss()
            toast.error(err?.response?.data?.error?.[0]?.message)
            return { message: "Unable to update Template", hasError: false, data: err?.response?.data }
        })
}

export const GetEmployeeTemplateHelper = async (params) => {
    const data = getUserData();
    return await axiosInstance(`/finance/getEmployeeTemplate`, {
        method: "POST",
        baseURL: HRMS_FINANCE_API_URL,
        data: {
            domain_name: data?.domain,
            employee_id: params?.employee_id
        }
    })
        .then((res) => {
            return { message: "Template retrived successfully", hasError: false, data: res?.data }
        }).catch(err => {
            return { message: "Unable to retrived Template", hasError: false, data: err?.response?.data }
        })
}