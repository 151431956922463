import { Grid, Paper, Typography } from '@mui/material';
import React from 'react';
import { useSelector } from 'react-redux';
import YearType from '../../../Leave/views/YearType/YearType';
import MyTime from '../../../Leave/views/MyTime/MyTime';
import { useTheme } from "@mui/material/styles";
import CompanyDetail from '../../../Ancillary/CompanyDetails/views/CompanyDetail';
import WeekOff from '../../../Leave/views/WeekOff/WeekOff';
import WorkingHours from '../../../Leave/views/WorkHours/WorkingHours';

const Others = () => {
    const theme = useTheme();
    const { leaveYearTypeData } = useSelector(state => state?.LeaveReducer?.LeaveYearTypeReducer);
    const { mytime_options } = useSelector(state => state.LeaveReducer?.MyTimeConfigureReducer);
    const { week_off_data } = useSelector(state => state.LeaveReducer?.WeekOffConfigureReducer);
    const { work_hours_data } = useSelector(state => state.LeaveReducer?.WeekOffConfigureReducer);

    const values = [
        { name: "Company Logo", comp: <CompanyDetail /> },
        { name: "Business Year Type", comp: <YearType />, count: leaveYearTypeData?.length || 0 },
        { name: "Projects", comp: <MyTime />, count: mytime_options?.length || 0 },
        { name: "Week Off Configuration", comp: <WeekOff />, count: week_off_data?.length || 0 },
        { name: "Working Hours", comp: <WorkingHours />, count: work_hours_data?.length || 0 },
    ];

    return (
        <Grid container spacing={2} style={{ padding: "10px 20px" }}>
            {values.map((value, id) => (
                <Grid item xs={12} md={12} key={id}>
                    <Paper style={{ padding: "20px", borderRadius: "10px" }}>
                        <Typography
                            variant="h6"
                            gutterBottom
                            sx={{ paddingLeft: "15px", color: theme.palette.primary.contrastText }}
                        >
                            {value.name}
                        </Typography>
                        {value.comp}
                    </Paper>
                </Grid>
            ))}
        </Grid>
    );
};

export default Others;
