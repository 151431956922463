import { toast } from "react-toastify";
import axiosInstance from "../../../../axiosInstance";
import { HRMS_FINANCE_API_URL } from "../../../Constants/baseConstant";

export const ShareAttachHelper = async (params) => {
    toast.dismiss();
    toast.info("Sending Email...")
    return await axiosInstance(`/finance/ancillary/shareAttachments`, {
        method: "POST",
        baseURL: HRMS_FINANCE_API_URL,
        data: {
            to: params?.to,
            cc: params?.cc,
            bcc: params?.bcc,
            subject: params?.subject,
            email_content: params?.content,
            invoice_file: params?.invoice_file,
            file_name: params?.file_name,
            csv_file: params?.csv_file, // Include the CSV file here
            csv_file_name: params?.csv_file_name // Include the CSV file name
        }
    })
        .then((res) => {
            toast.dismiss();
            toast.success(params?.message)
            return { message: "Success", hasError: false, data: res?.data }
        }).catch(err => {
            toast.dismiss();
            toast.error(err?.response?.data?.error?.[0]?.message)
            return { message: "Error", hasError: true, data: err?.response?.data }
        })
}
