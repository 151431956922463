import { toast } from "react-toastify";
import { getUserData } from "../../Base/helper/baseFunctions";
import axiosInstance from "../../../../axiosInstance";
import { HRMS_FINANCE_API_URL } from "../../../Constants/baseConstant";

export const AddPayrollTemplateHelper = async (params) => {
    const data = getUserData();
    toast.dismiss()
    toast.info("Saving...")
    return await axiosInstance(`/finance/ancillary/addPayrollTemplate`, {
        method: "POST",
        baseURL: HRMS_FINANCE_API_URL,
        data: {
            domain_name: data?.domain,
            user_id: data?.employee_id,
            template_name: params?.template_name,
            template_fields: params?.template_fields
        }
    })
        .then((res) => {
            toast.dismiss()
            toast.success("Template Added Successfully")
            return { message: "Template Added Successfully", hasError: false, data: res?.data }
        }).catch(err => {
            toast.dismiss()
            toast.error(err?.response?.data?.error?.[0]?.message)
            return { message: "Unable to add Template", hasError: false, data: err?.response?.data }
        })
}

export const UpdatePayrollTemplateHelper = async (params) => {
    const data = getUserData();
    toast.dismiss()
    toast.info("Saving...")
    return await axiosInstance(`/finance/ancillary/updatePayrollTemplate`, {
        method: "PUT",
        baseURL: HRMS_FINANCE_API_URL,
        data: {
            domain_name: data?.domain,
            user_id: data?.employee_id,
            payroll_generation_template_id: params?.payroll_generation_template_id,
            template_name: params?.template_name,
            template_fields: params?.template_fields
          
        }
    })
        .then((res) => {
            toast.dismiss()
            toast.success("Template Updated Successfully")
            return { message: "Template Updated Successfully", hasError: false, data: res?.data }
        }).catch(err => {
            toast.dismiss()
            toast.error(err?.response?.data?.error?.[0]?.message)
            return { message: "Unable to update Template", hasError: false, data: err?.response?.data }
        })
}

export const DeletePayrollTemplateHelper = async (params) => {
    const data = getUserData();
    toast.dismiss()
    toast.info("Deleting...")
    return await axiosInstance(`/finance/ancillary/deletePayrollTemplate`, {
        method: "DELETE",
        baseURL: HRMS_FINANCE_API_URL,
        data: {
            domain_name: data?.domain,
            payroll_generation_template_id: params?.payroll_generation_template_id,
        }
    })
        .then((res) => {
            toast.dismiss()
            toast.success("Template Deleted Successfully")
            return { message: "Template Deleted Successfully", hasError: false, data: res?.data }
        }).catch(err => {
            toast.dismiss()
            toast.error(err?.response?.data?.error?.[0]?.message)
            return { message: "Unable to delete Template", hasError: false, data: err?.response?.data }
        })
}


export const GetPayrollTemplateHelper = async (params) => {
    const data = getUserData();
    return await axiosInstance(`/finance/ancillary/getPayrollTemplate`, {
        method: "POST",
        baseURL: HRMS_FINANCE_API_URL,
        data: {
            domain_name: data?.domain,
            payroll_generation_template_id: params?.payroll_generation_template_id || null
        }
    })
        .then((res) => {
            return { message: "Template retrived successfully", hasError: false, data: res?.data }
        }).catch(err => {
            return { message: "Unable to retrived Template", hasError: false, data: err?.response?.data }
        })
}