import { useState } from "react";
import { Box, Button, FormControl, FormLabel } from "@mui/material";
import Popup from "../../../Base/views/Popup";
import EmployeePayroleTemplate from "./EmployeePayroleTemplate";

const PayrollSetupPopup = ({emp, isonboard, payroll, setPayroll, currentData, setSuccessPopup, setOpenPopup, setCurrentMode, setInternal }) => {
    const [message, setMessage] = useState(
        "Do you want to setup payroll for this employee now?"
    );
    const [show, setShow] = useState("1");
    const [openTemplate, setOpenTemplate] = useState(false);
    const handleLater = () => {
        setShow("2");
        setMessage("You can complete the payroll setup for this employee from Payroll > Employees");
    };
    const handleSubmit = () => {
        setOpenTemplate(true);
    };
    const handleOk = () => {
        if(isonboard){
            setCurrentMode("grid");
            setInternal(true)
        }
        else{
            setSuccessPopup(false);
            setOpenPopup(false)
        }
        setPayroll(false);
    };


    return (
        <>
            <Popup
                close={false}
                fullWidth={true}
                maxWidth="sm"
                title="Payroll Setup for Employee"
                openPopup={payroll}
                setOpenPopup={setPayroll}
            >
                <Box>
                    <FormControl>
                        <FormLabel id="gender-label">{message}</FormLabel>
                    </FormControl>
                    <Box sx={{ mt: 2, display: "flex", justifyContent: show === '1' ? "space-between" : "center" }}>
                        {show === "1" && (
                            <>
                                <Button
                                    variant="outlined"
                                    className="mpl-secondary-btn"
                                    onClick={handleLater}
                                >
                                    Later
                                </Button>
                                <Button
                                    variant="contained"
                                    sx={{ ml: 2 }}
                                    className="mpl-primary-btn"
                                    onClick={handleSubmit}
                                >
                                    Yes
                                </Button>
                            </>
                        )}
                        {show === "2" && (
                            <Button
                                variant="contained"
                                className="mpl-primary-btn"
                                onClick={handleOk}
                            >
                                Ok
                            </Button>
                        )}
                    </Box>
                </Box>
            </Popup>
            <Popup
                maxWidth="md"
                fullWidth={true}
                title={`Payroll Configuration - ${currentData?.name}`}
                openPopup={openTemplate}
                setOpenPopup={setOpenTemplate}
            >
                <EmployeePayroleTemplate emp={emp} createEmp={true} isonboard={isonboard} setCurrentMode={setCurrentMode} setInternal={setInternal} setOpenTemplate={setOpenTemplate} openTemplate={openTemplate} currentData={currentData} setSuccessPopup={setSuccessPopup} setOpenPopup={setOpenPopup} />
            </Popup>
        </>
    )
}

export default PayrollSetupPopup