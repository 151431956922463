import { MTablePagination } from "material-table";
import { Box } from "@mui/material";

const CustomPagination = (props) => {
  const displayableKeys = props?.displayableKeys;
  return (
    <Box sx={{ backgroundColor: "rgb(237,228,252)",padding:"2px" }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        {/* Pagination Section */}
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            padding: "0px 10px",
          }}
        >
          <Box>
            <MTablePagination
              {...props}
              rowsPerPageOptions={[25, 50]}
              labelRowsPerPage="Rows per page:"
              labelDisplayedRows={({ from, to, count }) =>
                `${from}-${to} of ${count}`
              }
            />
          </Box>
        </Box>
            {/* Total Row Data */}
        {displayableKeys?.length > 0 && (
          <Box
            id="total"
            sx={{
              padding: "10px 20px 10px 0px",
              display: "flex",
              alignItems: "center",
              gap: "10px",
              color: "rgb(99,115,129)",
            }}
          >
            <Box sx={{ fontWeight: "bold", pl: "10px" }}>Total</Box>
            <Box
              sx={{
                display: "flex",
                gap: "30px",
                alignItems: "center",
              }}
            >
              {displayableKeys.map((key, index) => (
                <Box key={key[0] + index} sx={{ fontWeight: "bold" }}>
                  {`${key[0]}: ${key[1]}`}
                </Box>
              ))}
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default CustomPagination;
