import React, { useEffect, useRef, useState, } from "react";
import Webcam from "react-webcam";
import * as faceapi from "face-api.js";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { Box, Typography } from "@mui/material";
import ErrorIcon from '@mui/icons-material/Error';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled';
import SuccessTemplate from "./SuccessTemplate";
import ArrowBackButton from "../Base/views/ArrowBackButton";
import history from "../../../history";
import { GetEmployeesAction } from "../../../store/Employee/Employee/Action";
import { addFaceDataHelper } from "./helper/FaceRecognizationHelper";
import { getSignedUrl } from "@aws-sdk/s3-request-presigner";
import { GetObjectCommand } from "@aws-sdk/client-s3";
import { s3Client } from "../Base/helper/baseFunctions";

const Alert = ({ message, flag }) => {

    const containerStyle = {
        backgroundColor: flag === "success" ? "#b8dfc6" : flag === "detect" ? "#c4e8fa" : "#fde3de",
        borderRadius: "5px",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        padding: "10px 15px",
        color: "#000",
        position: "relative",
        borderBottom: flag === "success" ? "1px solid #24965f" : flag === "detect" ? "1px solid #476b96" : "1px solid #c34638",
        marginBottom: "20px"
    };

    const infoIconStyle = {
        color: flag === "success" ? "#1b9857" : flag === "detect" ? "#3b649f" : "#bf4638",
        marginRight: "10px",
        fontSize: "18px",
    };

    const textStyle = {
        margin: 0,
        flex: 1,
        color: "#375640"
    };

    return (
        <div style={{ width: "100%", textAlign: "center", display: "flex", justifyContent: "center" }}>
            <div style={containerStyle}>
                <span style={infoIconStyle}>
                    {flag === "success" ? (
                        <CheckCircleIcon />
                    ) :
                        flag === "detect" ? (
                            <AccessTimeFilledIcon />
                        ) :
                            (
                                <ErrorIcon />
                            )}
                </span>
                <p style={textStyle}>
                    {message}
                </p>
            </div>
        </div>
    );
};

const FaceAttendanceTracker = () => {
    const webcamRef = useRef(null);
    const [flag, setFlag] = useState("detect");
    const [imageUrl, setImageUrl] = useState("");
    const [attendanceMarked, setAttendanceMarked] = useState(false);
    const [message, setMessage] = useState("Detecting face...");
    const [detectedUser, setDetectedUser] = useState(null);
    const dispatch = useDispatch();
    const { employees_options } = useSelector(
        (state) => state?.EmployeeReducer.EmployeesReducer
    );

    useEffect(() => {
        const loadModels = async () => {
            const MODEL_URL = process.env.PUBLIC_URL + "/models";
            await faceapi.loadTinyFaceDetectorModel(MODEL_URL);
            await faceapi.loadFaceLandmarkModel(MODEL_URL);
            await faceapi.loadFaceRecognitionModel(MODEL_URL);
        };
        loadModels();
        dispatch(GetEmployeesAction({ is_active: true }));
    }, []);
    const getImageSignedUrl = async (currentData) => {
        let filename = currentData?.image;
        const bucketParams = {
            Bucket: process.env.REACT_APP_DO_BUCKET_NAME,
            Key: filename,
        };
        try {
            const url = await getSignedUrl(
                s3Client,
                new GetObjectCommand(bucketParams),
                { expiresIn: 3 * 60 }
            ); // Adjustable expiration.
            setImageUrl(url);
            return url;
        } catch (err) {
        }
    };
    const detectFace = async () => {
        setMessage("Detecting face...");
        const registeredUsers = employees_options || [];
        if (webcamRef.current && webcamRef.current.video.readyState === 4) {
           
            const video = webcamRef.current.video;
            const detections = await faceapi.detectSingleFace(video, new faceapi.TinyFaceDetectorOptions()).withFaceLandmarks().withFaceDescriptor();
            if (detections) {
                let matchedUser = null;
                registeredUsers.forEach((user) => {
                    if (user.descriptor) {
                        const userDescriptorArray = new Float32Array(JSON.parse(user.descriptor));
                        const detectionDescriptorArray = new Float32Array(detections.descriptor);
                        if (userDescriptorArray.length === detectionDescriptorArray.length) {
                            const distance = faceapi.euclideanDistance(userDescriptorArray, detectionDescriptorArray);
                            if (distance < 0.5) {
                                matchedUser = user;
                            }
                        } 
                    }
                });
                if (matchedUser) {
                    getImageSignedUrl(matchedUser)
                    setDetectedUser(matchedUser);
                    markAttendance(matchedUser);
                } else {
                    setMessage("User is unknown");
                    setFlag("error");
                }
            }
        }
    };

    const markAttendance = (data) => {
        const getLocalISOTime = () => {
            const now = new Date();
            const offsetInMinutes = now.getTimezoneOffset(); // Offset in minutes
            const localTime = new Date(now.getTime() - offsetInMinutes * 60000);
            return localTime.toISOString().slice(0, -1); // Remove the "Z" at the end
        };
        const record_time =getLocalISOTime();
        addFaceDataHelper({
            ...data,
            record_time: record_time,
        }).then((res) => {
            if (res?.data?.statuscode === 200) {
                setAttendanceMarked(true);
                setMessage(`Attendance marked for ${data?.name}`);
                setTimeout(() => {
                    setAttendanceMarked(false);
                }, 2000);
            }
        });
    };

    useEffect(() => {
        const interval = setInterval(() => {
            detectFace();
        }, 3000);
        return () => clearInterval(interval);
    }, []);

    return (
        <>
            {!attendanceMarked && (
                <Box className="bg_form_main" sx={{ minHeight: "100vh", paddingTop: "20px", width: "100%" }}>
                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>

                        <div style={{ textAlign: "center", position: "relative", width: '100%', maxWidth: '640px', height: '440px', margin: '0 auto' }}>
                            <Box
                                sx={{
                                    display: "flex",
                                    WebkitBoxPack: "justify",
                                    alignItems: "center",
                                    padding: "8px 16px 10px 0px",
                                }}
                            >
                                <ArrowBackButton onClick={() => history.goBack()} />
                                <Typography sx={{ fontFamily: "Nunito Sans", fontWeight: "bold", ml: 8 }} variant="h4">Face Recognition Attendance</Typography>
                            </Box>

                            {/* Camera Section with Centered Four-Corner Border */}

                            <div style={{
                                position: 'relative',
                                width: '100%',
                                height: '100%',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}>
                                <Webcam
                                    ref={webcamRef}
                                    audio={false}
                                    screenshotFormat="image/jpeg"
                                    videoConstraints={{ width: 640, height: 480, facingMode: "user" }}
                                    style={{
                                        width: '100%',
                                        height: '100%',
                                        objectFit: 'cover'
                                    }}
                                />
                                <div style={{
                                    position: 'absolute',
                                    top: 0,
                                    left: 0,
                                    width: '100%',
                                    height: '100%',
                                    pointerEvents: 'none'
                                }}>
                                    <div style={{
                                        position: 'absolute',
                                        top: 0,
                                        left: 0,
                                        width: '70px',
                                        height: '70px',
                                        borderTop: '5px solid #00ff00',
                                        borderLeft: '5px solid #00ff00',
                                        borderRadius: '16px 0 0 0px',
                                    }} />
                                    <div style={{
                                        position: 'absolute',
                                        top: 0,
                                        right: 0,
                                        width: '70px',
                                        height: '70px',
                                        borderTop: '5px solid #00ff00',
                                        borderRight: '5px solid #00ff00',
                                        borderRadius: '0 16px 0 0',
                                    }} />
                                    <div style={{
                                        position: 'absolute',
                                        bottom: 0,
                                        left: 0,
                                        width: '70px',
                                        height: '70px',
                                        borderBottom: '5px solid #00ff00',
                                        borderLeft: '5px solid #00ff00',
                                        borderRadius: '0 0 0 16px',
                                    }} />
                                    <div style={{
                                        position: 'absolute',
                                        bottom: 0,
                                        right: 0,
                                        width: '70px',
                                        height: '70px',
                                        borderBottom: '5px solid #00ff00',
                                        borderRight: '5px solid #00ff00',
                                        borderRadius: '0 0 16px 0',
                                    }} />
                                </div>
                            </div>


                            <div style={{ margin: "20px" }}>
                                {attendanceMarked ? (
                                    <Alert message={message} flag={flag} />
                                ) : (

                                    <Alert message={message} flag={flag} />
                                )}
                            </div>
                        </div>
                    </Box>
                </Box>
            )}
            {attendanceMarked && (
                <SuccessTemplate detectedUser={detectedUser} message={message} imageUrl={imageUrl}/>
            )}
        </>
    );
};

export default FaceAttendanceTracker;
