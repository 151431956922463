import { takeLatest } from 'redux-saga/effects';
import * as ActionTypes from './ActionTypes';
import {
    AddTaxDeclaration,
    GetEmployeeProfile,
    GetTaxDeclaration,
    UpdateTaxDeclaration,
    AddSalaryComponent,
    AddSalaryStructure,
    DeleteSalaryComponent,
    DeleteSalaryStructure,
    GetSalaryComponent,
    GetSalaryComponentType,
    GetSalaryStructure,
    UpdateSalaryComponent,
    UpdateSalaryStructure,
    GetEarningComponent,
    GetDeductionComponent,
    GetPayrollTemplate,
    AddPayrollTemplate,
    UpdatePayrollTemplate,
    DeletePayrollTemplate,
    GetEmployeeTemplate,
    AddEmployeeTemplate,
    UpdateEmployeeTemplate,
    GetWageReport,
    GetEmployeeTime,
    GetEmployeeFaceTime
} from './FinanceSaga';

export default function* FinanceWatcher() {
    yield takeLatest(ActionTypes.TAX_DECLARATION_GET_REQUEST, GetTaxDeclaration);
    yield takeLatest(ActionTypes.TAX_DECLARATION_ADD_REQUEST, AddTaxDeclaration);
    yield takeLatest(ActionTypes.TAX_DECLARATION_UPDATE_REQUEST, UpdateTaxDeclaration);
    yield takeLatest(ActionTypes.EMPLOYEE_PROFILE_PAYROLL_GET_REQUEST, GetEmployeeProfile);
    yield takeLatest(ActionTypes.SALARY_STRUCTURE_GET_REQUEST, GetSalaryStructure);
    yield takeLatest(ActionTypes.SALARY_STRUCTURE_ADD_REQUEST, AddSalaryStructure);
    yield takeLatest(ActionTypes.SALARY_STRUCTURE_UPDATE_REQUEST, UpdateSalaryStructure);
    yield takeLatest(ActionTypes.SALARY_STRUCTURE_DELETE_REQUEST, DeleteSalaryStructure);
    yield takeLatest(ActionTypes.SALARY_COMPONENT_GET_REQUEST, GetSalaryComponent);
    yield takeLatest(ActionTypes.SALARY_COMPONENT_ADD_REQUEST, AddSalaryComponent);
    yield takeLatest(ActionTypes.SALARY_COMPONENT_UPDATE_REQUEST, UpdateSalaryComponent);
    yield takeLatest(ActionTypes.SALARY_COMPONENT_DELETE_REQUEST, DeleteSalaryComponent);
    yield takeLatest(ActionTypes.SALARY_COMPONENT_TYPE_GET_REQUEST, GetSalaryComponentType);
    yield takeLatest(ActionTypes.PAYROLL_EARNING_GET_REQUEST, GetEarningComponent);
    yield takeLatest(ActionTypes.PAYROLL_DEDUCTION_GET_REQUEST, GetDeductionComponent);
    yield takeLatest(ActionTypes.PAYROLL_TEMPLATE_GET_REQUEST, GetPayrollTemplate);
    yield takeLatest(ActionTypes.PAYROLL_TEMPLATE_ADD_REQUEST, AddPayrollTemplate);
    yield takeLatest(ActionTypes.PAYROLL_TEMPLATE_UPDATE_REQUEST, UpdatePayrollTemplate);
    yield takeLatest(ActionTypes.PAYROLL_TEMPLATE_DELETE_REQUEST, DeletePayrollTemplate);
    yield takeLatest(ActionTypes.EMPLOYEE_TEMPLATE_GET_REQUEST, GetEmployeeTemplate);
    yield takeLatest(ActionTypes.EMPLOYEE_TEMPLATE_ADD_REQUEST, AddEmployeeTemplate);
    yield takeLatest(ActionTypes.EMPLOYEE_TEMPLATE_UPDATE_REQUEST, UpdateEmployeeTemplate);
    yield takeLatest(ActionTypes.WAGE_REPORT_GET_REQUEST, GetWageReport);
    yield takeLatest(ActionTypes.EMPLOYEE_TIME_GET_REQUEST, GetEmployeeTime);
    yield takeLatest(ActionTypes.EMPLOYEE_FACE_TIME_GET_REQUEST, GetEmployeeFaceTime);
    
}