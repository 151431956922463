import React, { useEffect } from "react";
import { Menu, MenuItem, Grid, Box, ListItemIcon, ListItemText, Button, FormControl, InputLabel, Select, CircularProgress, Typography } from "@mui/material";
import Base from "../../../Base/views/Base";
import MaterialTable from "material-table";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import SendIcon from '@mui/icons-material/Send';
import { Tooltip, IconButton } from "@mui/material";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { useState } from "react";
import SelectableSearch from "../../../Base/views/SelectableSearch";
import { GetAtsCompanyAction } from "../../../../../store/ATS/Company/Action";
import { useDispatch, useSelector } from "react-redux";
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import { GetAtsGenerateInvoiceCandidatesAction, GetAtsInvoiceAction, GetAtsInvoiceCandidatesAction, UpdateAtsInvoiceAction } from "../../../../../store/ATS/Invoice/Action";
import { ConvertDate, getUserData } from "../../../Base/helper/baseFunctions";
import ATSGeneratedInvoicePopup from "./ATSGeneratedInvoicePopup";
import AtsInvoicePdf from "./AtsInvoicePdf";
import { PDFDownloadLink } from "@react-pdf/renderer";
import { CSVLink } from "react-csv";
import ATSGenerateInvoicePopup from "./ATSGenerateInvoicePopup";
import Popup from "../../../Base/views/Popup";
import AtsInvoiceMail from "./AtsInvoiceMail";
import CommonDatePicker from "../../../Base/views/CommonDatePicker";
import { GetATSModuleFeaturesAction } from "../../../../../store/RoleAccess/ATSModuleFeatures/Action";
import CustomToggle from "../../../Base/views/CustomToggle";
import { GetAtsGroupCompanyAction } from "../../../../../store/ATS/GroupCompany/Action";
import { useLocation } from "react-router-dom";
import history from "../../../../../history";

const ATSGenerateInvoice = () => {
    const user = getUserData();
    const isGroupOrgFlowTrueText = user?.grouporg_flow === true?'Group Organisation': 'Client';
    const isGroupOrgFlowTrue = user?.grouporg_flow === true;
    const dispatch = useDispatch();
    const { atsCompanyData } = useSelector(state => state.AtsReducer?.AtsCompanyReducer)
    const { atsGroupCompanyData } = useSelector(
        (state) => state.AtsReducer?.AtsGroupCompanyReducer
      );
    const { invoiceData, generateInvoiceCandidateData } = useSelector(state => state.AtsReducer?.AtsInvoiceReducer)
    const [currentData, setCurrentData] = useState({});
    const { invoiceCandidateData, isCandidateLoading } = useSelector(state => state.AtsReducer?.AtsInvoiceReducer)
    const [anchorEl, setAnchorEl] = useState(false);
    const [toggle, setToggle] = useState(false);
    const [companyData, setCompanyData] = useState({ load: false, values: [] })
    const [atsgroupUser, setAtsGroupUser] = useState({
        load: true,
        values: [],
      });
    const [showGeneratedInvoice, setShowGeneratedInvoice] = useState(false);
    const [showGenerateInvoice, setShowGenerateInvoice] = useState(false);
    const [filters, setFilters] = useState({ company_filter: "", days: 7, invoice_filter: "All" });
    const [selectedRow, setSelectedRow] = useState(0);
    const [generateCandidate, setGenerateCandidate] = useState(generateInvoiceCandidateData)
    const [selectedCandidates, setSelectedCandidates] = useState([])
    const [sendmail, setSendMail] = useState(false);
    const [paidDate, setPaidDate] = useState({});
    const { featuresData } = useSelector(state => state?.RoleAndAccessReducer?.ATSModuleFeaturesReducer)
    const location = useLocation();
    const  { defaultValue }  = location?.state  || {}; 
    const headers = [
        { label: "Candidate Name", key: "first_name" },
        { label: "Job Title", key: "job_title" },
        { label: "Joined Date", key: "joined_date" },
        { label: "Billable CTC", key: "billable_ctc" },
    ];

    useEffect(() => {
        toggle === true && dispatch(GetAtsInvoiceAction({ status: "All", ats_company_id: filters?.company_filter, past_days: filters?.days }))
        if(defaultValue && toggle === false){
            const company= findCompany();
            dispatch(GetAtsGenerateInvoiceCandidatesAction({ ats_company_id: company?.[0], past_days: filters?.days }));
            setFilters({...filters, company_filter:company?.[0]});
            history.replace({ ...location, state: {} });
        }
        else{
            toggle === false && dispatch(GetAtsGenerateInvoiceCandidatesAction({ ats_company_id: filters?.company_filter, past_days: filters?.days }));
        }
        dispatch(GetATSModuleFeaturesAction({ module_name: "ATS Invoice" }));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [toggle])

    const findCompany = () =>{
        let company;
        if(isGroupOrgFlowTrue){
            company=atsGroupCompanyData?.filter((el)=> el.ats_company_id === defaultValue)
        }
        else{
            company=atsCompanyData?.filter((el)=> el.ats_company_id === defaultValue)
        }
        return company
    }
    useEffect(() => {
        isGroupOrgFlowTrue ? dispatch(GetAtsGroupCompanyAction()): dispatch(GetAtsCompanyAction())
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        setGenerateCandidate(generateInvoiceCandidateData)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [generateInvoiceCandidateData])

    const handleDisabled = (permission) => {
        const foundPermission = featuresData?.find((item) => item.permission === permission);
        return foundPermission ? true : false;
    };

    const handleClick = (rows) => {
        if (rows.length > 0) setSelectedRow(rows);
        else setSelectedRow(0)
        setSelectedCandidates(rows)
    };

    const handleFilter = (event, value) => {
        if (["company_filter"].includes(event)) {
            setFilters({ ...filters, [event]: value })
            if (toggle) dispatch(GetAtsInvoiceAction({ status: "All", ats_company_id: value, past_days: filters?.days }))
            else dispatch(GetAtsGenerateInvoiceCandidatesAction({ ats_company_id: value, past_days: filters?.days }))
        } else if (["invoice_filter", "days"].includes(event?.target?.name)) {
            setFilters({ ...filters, [event?.target?.name]: event?.target?.value })
            if (toggle){ 
                dispatch(GetAtsInvoiceAction({
                status: event?.target?.name === "invoice_filter" ? event?.target?.value : filters?.invoice_filter,
                ats_company_id: filters?.company_filter,
                past_days: event?.target?.name === "days" ? event?.target?.value : filters?.days
            }))
        }
            else {
                dispatch(GetAtsGenerateInvoiceCandidatesAction({ ats_company_id: filters?.company_filter, past_days: event?.target?.name === "days" ? event?.target?.value : filters?.days }))
            }
            }
    }

    const handleMoreVertIconClick = (event, rowData) => {
        setCurrentData(rowData)
        dispatch(GetAtsInvoiceCandidatesAction({ ats_invoice_id: rowData?.ats_invoice_id }))
        setAnchorEl(event.currentTarget);
    };

    const handleMoreVertIconClose = () => {
        setAnchorEl(null);
    };

    const handleEdit = () => {
        setAnchorEl(false);
    };

    const handleInvoicePdf = () => {
        setAnchorEl(null);
    }

    const handleSendMail = () => {
        setAnchorEl(null);
        setSendMail(true)
    }

    const handleToggle = () => {
        setToggle(true);
        setFilters({ company_filter: "", days: 7, invoice_filter: "All" }); // Clear the filters state
    };

    const handleToggleoff = () => {
        setToggle(false)
        setFilters({ company_filter: "", days: 7, invoice_filter: "All" }); // Clear the filters state
    }

    const handleCellEdit = (newValue, rowData, columnDef) => {
        if ([null, ""].includes(filters?.company_filter)) handleAutoFilter(rowData)
        else {
            let tempData = [...generateCandidate]
            let candIndex = tempData?.findIndex(res => res?.candidate_id === rowData?.candidate_id);
            tempData[candIndex][columnDef?.field] = newValue
            setGenerateCandidate(tempData)
        }
    }

    const handleAutoFilter = (rows) => {
        dispatch(GetAtsGenerateInvoiceCandidatesAction({ ats_company_id: { ats_company_id: +rows?.ats_company_id || +rows?.ats_grouporg_id  }, past_days: filters?.days }))
        let compData = isGroupOrgFlowTrue ? atsGroupCompanyData?.filter(res => +res?.ats_grouporg_id === +rows?.ats_company_id) : atsCompanyData?.filter(res => +res?.ats_company_id === +rows?.ats_company_id)
        setFilters({ ...filters, company_filter: compData?.[0] })
    }

    const handleGeneratedRowClick = (event, rowData) => {
        setCurrentData(rowData);
        dispatch(GetAtsInvoiceCandidatesAction({ ats_invoice_id: rowData?.ats_invoice_id }))
        setShowGeneratedInvoice(true);
    }

    const handlePaidChange = (value) => {
        setPaidDate(value)
    }

    const handlePaidStatus = (rowData) => {
        const date = new Date();
        const paid_date = `${date?.getFullYear()}-${date?.getMonth() + 1}-${date?.getDate()}`
        dispatch(UpdateAtsInvoiceAction({
            ats_invoice_id: rowData?.ats_invoice_id,
            paid_status: rowData?.status === "Paid" ? "Not Paid" : "Paid",
            status: filters?.invoice_filter,
            ats_company_id: +filters?.company_filter?.ats_company_id || +filters?.company_filter?.ats_grouporg_id,
            past_days: filters?.days,
            paid_date: rowData?.status === "Paid" ? null : (paidDate?.target?.value ? paidDate?.target?.value : rowData?.paid_date || paid_date)
        }))
        setPaidDate(null)
    }

    const hadleGenerateClick = () => {
        setShowGenerateInvoice(true)
    }

    const isEditable = !handleDisabled("invoice_generate_invoice") ? "always" : "never";

    return (
        <Base>
         <Box sx={{ maxHeight: "87vh", overflowY: 'auto' }}>
            {
                showGeneratedInvoice &&
                <ATSGeneratedInvoicePopup
                    setShowGeneratedInvoice={setShowGeneratedInvoice}
                    currentData={currentData}
                    headers={headers}
                />
            }
            {
                showGenerateInvoice &&
                <ATSGenerateInvoicePopup
                    setShowGenerateInvoice={setShowGenerateInvoice}
                    selectedCandidates={selectedCandidates}
                    currentData={filters?.company_filter}
                    showGeneratedInvoice={showGeneratedInvoice}
                    filters={filters}
                />
            }
            <Box sx={{ mb: 1,mt:1 }}>
                <Grid container columnSpacing={1}>
                    <Grid item xs={12} sm={2.5}>
                       
                        {isGroupOrgFlowTrue ? (
                                    <SelectableSearch
                                    isOptionEqualToValue={(option, value) =>
                                        +option.ats_grouporg_id === +value.ats_grouporg_id
                                    }
                                    label="Group Org"
                                    value={filters?.company_filter}
                                    loading={() => setAtsGroupUser({ load: false, values: atsGroupCompanyData })}
                                    fieldLabel="name"
                                    required={false}
                                    variant={"outlined"}
                                    name="company_filter"
                                    size="small"
                                    onChangeEvent={handleFilter}
                                    data={atsgroupUser}
                                />
                                  ) : (
                                    <SelectableSearch
                                    isOptionEqualToValue={(option, value) =>
                                        +option.ats_company_id === +value.ats_company_id
                                    }
                                    label="Client"
                                    value={filters?.company_filter}
                                    loading={() => setCompanyData({ load: false, values: atsCompanyData })}
                                    fieldLabel="name"
                                    required={false}
                                    variant={"outlined"}
                                    name="company_filter"
                                    size="small"
                                    onChangeEvent={handleFilter}
                                    data={companyData}
                                />
                                  )}
                        {toggle === false && <Typography variant="body2" sx={{ color: "gray" }}>Please choose {isGroupOrgFlowTrueText} to generate invoice.</Typography>}
                    </Grid>
                    <Grid item xs={12} sm={2}>
                        <FormControl size="small" fullWidth>
                            <InputLabel id="recruiter-type-select-label">Days</InputLabel>
                            <Select
                                name='days'
                                labelId="days"
                                id="days"
                                value={filters?.days}
                                label="Days"
                                onChange={handleFilter}
                            >
                                <MenuItem value={7}>Last 7 Days</MenuItem>
                                <MenuItem value={15}>Last 15 Days</MenuItem>
                                <MenuItem value={30}>Last 30 Days</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={2}>
                        {
                            toggle &&
                            <FormControl size="small" fullWidth>
                                <InputLabel id="recruiter-type-select-label">Status</InputLabel>
                                <Select
                                    name='invoice_filter'
                                    labelId="invoice_filter"
                                    id="invoice_filter"
                                    value={filters?.invoice_filter}
                                    label="Status"
                                    onChange={handleFilter}
                                >
                                    <MenuItem value={"All"}>All</MenuItem>
                                    <MenuItem value={"Paid"}>Invoice Paid</MenuItem>
                                    <MenuItem value={"Not Paid"}>Invoice To be Paid</MenuItem>
                                </Select>
                            </FormControl>
                        }
                    </Grid>
                </Grid>
            </Box>
            {toggle === false ?
                <>
                <div className="invoicesection">
                    <MaterialTable
                       
                        columns={[
                            { title: "Candidate Name", cellStyle: { padding: "10px", textAlign: "left" }, headerStyle: { padding: "10px", textAlign: "left" }, field: "candidate_name", editable: "never" },
                            { title: isGroupOrgFlowTrue ? "Group Org" : "Client Name", ccellStyle: { padding: "10px", textAlign: "left" }, headerStyle: { padding: "10px", textAlign: "left" }, field: "company", editable: "never" },
                            { title: "Position", cellStyle: { padding: "10px", textAlign: "left" }, headerStyle: { padding: "10px", textAlign: "left" }, field: "job_title", editable: "never" },
                            {
                                title: "Joined Date", cellStyle: { padding: "10px", textAlign: "left" }, headerStyle: { padding: "10px", textAlign: "left" }, field: "joined_date", editable: "never",
                                render: rowData => { return rowData?.joined_date ? ConvertDate(rowData?.joined_date) : "    " }
                            },
                            { title: "Joined CTC", cellStyle: { padding: "10px",textAlign: "center"}, headerStyle: { padding: "10px",textAlign: "center"}, field: "joined_ctc", editable: "never" },
                            {
                                title: "Billable CTC", className: "billable",cellStyle: { padding: "10px", textAlign: "center", }, headerStyle: { padding: "10px", textAlign: "center" }, field: "billable_ctc", validate: rowData => rowData?.code === '' || rowData?.code === undefined ? 'Com % cannot be empty' : '', editable: isEditable,
                              
                            },
                            {
                                title: "Professional Fee % ( > 0)", cellStyle: { padding: "10px", textAlign: "center" }, headerStyle: { padding: "10px", textAlign: "center" }, field: "commission_percentage", validate: rowData => rowData?.com === '' || rowData?.com === undefined ? 'Com % cannot be empty' : '', editable: isEditable,
                                
                              
                            },
                        ]}
                        data={generateCandidate || []}
                        cellEditable={{
                            onCellEditApproved: (newValue, oldValue, rowData, columnDef) => {
                                return new Promise((resolve, reject) => {
                                    handleCellEdit(newValue, rowData, columnDef)
                                    setTimeout(resolve, 200);
                                });
                            }
                        }}
                        options={{
                            maxBodyHeight: "60vh",
                            minBodyHeight: "60vh",
                            rowStyle: { fontSize: "13px" },
                            sorting: true,
                            search: false,
                            searchFieldAlignment: "right",
                            searchAutoFocus: true,
                            searchFieldVariant: "standard",
                            filtering: false,
                            paging: true,
                            pageSizeOptions: [25, 50],
                            pageSize: 25,
                            selection: [null, ""].includes(filters?.company_filter) ? false : true,
                            showTextRowsSelected: true,
                            selectionProps: rowData => ({
                                disabled: [null, '', 0, '0'].includes(rowData.commission_percentage),
                            }),
                            showSelectAllCheckbox: false,
                            actionsColumnIndex: -1,
                            grouping: false,
                            addRowPosition: "first",
                            
                        }}
                        onSelectionChange={(e) => handleClick(e)}
                        actions={[
                            {
                                icon: () => (
                                    <CustomToggle
                                        isChecked={toggle}
                                        handleToggleChange={handleToggle}
                                        Yestext={'Generated'}
                                        NoText={'Generate'}
                                        style={{ height: '32px', padding: '0px', width: '150px', labelPadding: '1px' }}
                                    />
                                ),
                                position: "row",
                                isFreeAction: true,
                                tooltip: "Toggle to view generated invoice",
                            },
                            ![null, ""].includes(filters?.company_filter) && {
                                icon: () => (
                                    <Button
                                        component="span"
                                        variant="contained"
                                        className="mpl-primary-btn"
                                        onClick={hadleGenerateClick}
                                    >
                                        Generate Proforma
                                    </Button>
                                ),
                                isFreeAction: false,
                                tooltip: "Generate Proforma",
                                position: "toolbarOnSelect",
                                hidden: selectedRow === 0,
                            },
                        ]}
                        title={
                            <div style={{ display: "flex", alignItems: "center" }}>
                                <Typography variant="h6" style={{ fontWeight: "bold" }}>
                                    Generate Invoice
                                </Typography>
                                <Tooltip title="Only candidates in Joined stage of a job can be invoiced">
                                    <IconButton size="small" style={{ padding: 4 }}>
                                        <InfoOutlinedIcon fontSize="small" />
                                    </IconButton>
                                </Tooltip>
                            </div>
                        }  
                    />
                    </div>
                </>
                : <>
                    <MaterialTable
                        onRowClick={(event, rowData) => handleGeneratedRowClick(event, rowData)}
                        columns={[
                            { title: "Invoice No", cellStyle: { padding: "0px 10px" }, field: "ats_invoice_no" },
                            {
                                title: "Invoice Date", cellStyle: { padding: "0px 10px" }, field: "invoice_date",
                                render: rowData => { return ConvertDate(rowData?.invoice_date) }
                            },
                            { title: "Invoice Amount", cellStyle: { padding: "0px 10px" }, field: "invoice_amount" },
                            {
                                title: 'Paid date',
                                cellStyle: { padding: '10px 10px' },
                                field: 'paid_date',
                                type: 'date',
                                render: (rowData) =>
                                    rowData.status === "Not Paid" ?
                                        (<Box onClick={(e) => e.stopPropagation()}>
                                            <CommonDatePicker
                                                size="small"
                                                name="paid_date"
                                                label="Paid Date"
                                                pickerType="date"
                                                onKeyPress={(e) => {
                                                    e.preventDefault(); e.stopPropagation();
                                                }}
                                                onKeyDown={(e) => { e.preventDefault(); e.stopPropagation()}}
                                                pickerFormat="dd/MM/yyyy"
                                                DefaultDTvalue={paidDate?.target?.value || rowData?.paid_date}
                                                maxDate={new Date()}
                                                handleChange={(value) => handlePaidChange({
                                                    target: {
                                                        name: "paid_date",
                                                        value: value
                                                            ? `${value?.getFullYear() + "-" + ("0" + (value?.getMonth() + 1)).slice(-2) + "-" + ("0" + value?.getDate()).slice(-2)}`
                                                            : "",
                                                    },
                                                })}
                                            />
                                        </Box>) : (rowData?.paid_date ? ConvertDate(rowData?.paid_date) : ""),
                            },
                            { title: isGroupOrgFlowTrue ? "Group Org" : "Client", cellStyle: { padding: "0px 10px" }, field: "company" },
                        ]}
                        data={invoiceData || []}
                        options={{
                            maxBodyHeight: "60vh",
                            minBodyHeight: "60vh",
                            rowStyle: { fontSize: "13px" },
                            sorting: true,
                            search: false,
                            searchFieldAlignment: "right",
                            searchAutoFocus: true,
                            searchFieldVariant: "standard",
                            filtering: false,
                            paging: true,
                            pageSizeOptions: [25, 50],
                            pageSize: 25,
                            selection: false,
                            actionsColumnIndex: -1,
                            actionsCellStyle: -1,
                            grouping: false,
                            addRowPosition: "first",
                        }}
                        title="Generated Invoice"
                        actions={[
                            {
                                icon: () => <MoreVertIcon />,
                                position: "row",
                                tooltip: "More",
                                onClick: (_event, rowData) => handleMoreVertIconClick(_event, rowData)
                            },
                            {
                                icon: () => (
                                    <CustomToggle
                                        isChecked={toggle}
                                        handleToggleChange={handleToggleoff}
                                        Yestext={'Generated'}
                                        NoText={'Generate'}
                                        style={{ height: '32px', padding: '0px', width: '150px', labelPadding: '1px' }}
                                    />
                                ),
                                position: "row",
                                isFreeAction: true,
                                tooltip: "Toggle to view generate invoice",
                            },
                            rowData => ({
                                icon: () => <TaskAltIcon />,
                                tooltip: handleDisabled("invoice_generated_invoice_payment") ? "You Don't have sufficient permission. Please contact Admin." : rowData.status === "Paid" ? "Mark as Unpaid" : 'Mark as Paid',
                                position: "row",
                                disabled: handleDisabled("invoice_generated_invoice_payment"),
                                onClick: (_event, rowData) => handlePaidStatus(rowData),
                            })

                        ]}
                    />

                    <Menu
                        id="more-options-menu"
                        anchorEl={anchorEl}
                        open={anchorEl}
                        onClose={handleMoreVertIconClose}
                        MenuListProps={{
                            'aria-labelledby': 'more-options-button',
                        }}
                    >
                        {isCandidateLoading ?
                            <MenuItem key="load" sx={{ display: "flex", justifyContent: "center", minWidth: "180px" }}>
                                <CircularProgress color="primary" />
                            </MenuItem> :
                            <>
                                <MenuItem key="download" onClick={() => handleEdit(currentData)}>
                                    <ListItemIcon> <SaveAltIcon fontSize="small" /></ListItemIcon>
                                    <ListItemText>
                                        <CSVLink style={{ color: "black" }} data={invoiceCandidateData} headers={headers} filename={currentData?.ats_invoice_no}>
                                            Download CSV
                                        </CSVLink>
                                    </ListItemText>
                                </MenuItem>
                                <MenuItem key="download pdf" onClick={handleInvoicePdf}>
                                    <ListItemIcon> <SaveAltIcon fontSize="small" /></ListItemIcon>
                                    <ListItemText>
                                        <PDFDownloadLink style={{ color: "black" }} document={<AtsInvoicePdf data={currentData} invoiceCandidateData={invoiceCandidateData} />} fileName={currentData?.ats_invoice_no}>
                                            Download PDF
                                        </PDFDownloadLink>
                                    </ListItemText>
                                </MenuItem>
                                <MenuItem key="sendmail" onClick={handleSendMail}>
                                    <ListItemIcon> <SendIcon fontSize="small" /></ListItemIcon>
                                    <ListItemText>Send Mail</ListItemText>
                                </MenuItem>
                            </>
                        }


                    </Menu>
                </>}
            <Popup
                title={currentData?.ats_invoice_no}
                openPopup={sendmail}
                setOpenPopup={setSendMail}
                maxWidth={"md"}
                fullWidth={true}
            >
                <AtsInvoiceMail setSendMail={setSendMail} currentData={currentData} invoiceCandidateData={invoiceCandidateData} />
            </Popup>
        </Box>
        </Base >
    )
}

export default ATSGenerateInvoice;