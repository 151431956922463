import * as ActionTypes from '../ActionTypes';

export const GetPayrollTemplateAction = (params) => {
    return {
        type: ActionTypes.PAYROLL_TEMPLATE_GET_REQUEST,
        params
    }
}

export const AddPayrollTemplateAction = (params) => {
    return {
        type: ActionTypes.PAYROLL_TEMPLATE_ADD_REQUEST,
        params
    }
}

export const UpdatePayrollTemplatetAction = (params) => {
    return {
        type: ActionTypes.PAYROLL_TEMPLATE_UPDATE_REQUEST,
        params
    }
}

export const DeletePayrollTemplateAction = (params) => {
    return {
        type: ActionTypes.PAYROLL_TEMPLATE_DELETE_REQUEST,
        params
    }
}