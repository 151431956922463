import * as ActionTypes from "../ActionTypes";


export const GetEmployeeProfileAction = () => {
    return {
        type: ActionTypes.EMPLOYEE_PROFILE_PAYROLL_GET_REQUEST,
    }
}
export const GetEmployeeTimeAction = (params) => {
    return {
        type: ActionTypes.EMPLOYEE_TIME_GET_REQUEST,
        params
    }
}

export const GetEmployeeFaceTimeAction = (params) => {
    return {
        type: ActionTypes.EMPLOYEE_FACE_TIME_GET_REQUEST,
        params
    }
}