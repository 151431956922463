export const FIXED_MENU_OPEN = "FIXED_MENU_OPEN";
export const FIXED_MENU_CLOSE = "FIXED_MENU_CLOSE";
export const OPEN_MENU = "OPEN_MENU";
export const CLOSE_MENU = "CLOSE_MENU";

export const MOBILE_MENU = "MOBILE_MENU";

export const ATS_COLLAPSE_MENU = "ATS_COLLAPSE_MENU";
export const ATS_COLLAPSE_SUB_MENU = "ATS_COLLAPSE_SUB_MENU";
export const ATS_REPORT_COLLAPSE_MENU = "ATS_REPORT_COLLAPSE_MENU";
export const LMS_COLLAPSE_MENU = "LMS_COLLAPSE_MENU";
export const EMPLOYEE_COLLAPSE_MENU = "EMPLOYEE_COLLAPSE_MENU";
export const FINACNE_COLLAPSE_MENU = "FINACNE_COLLAPSE_MENU";
export const PAYROLL_COLLAPSE_MENU = "PAYROLL_COLLAPSE_MENU";



export const ATS_MENU_HEADING = "ATS_MENU_HEADING";