import { Box, Button, FormControl, FormHelperText, Grid, InputLabel, MenuItem, Select } from "@mui/material";
import Popup from "../../../../Base/views/Popup";

const RejectPopup = ({ openPopup, setOpenPopup, handleRejectClick, errors, setValues, values, setErrors }) => {

    const handleClose = () => {
        setOpenPopup(false);
        setErrors([])
    }

    return (
        <>
            <Popup
                fullWidth={true}
                maxWidth="xs"
                title="Reject Candidate"
                openPopup={openPopup}
                setOpenPopup={handleClose}
            >
                <Box>
                    <Grid container spacing={2}>
                        <Grid key="venue" item xs={12} md={12}>
                            <FormControl size="small" fullWidth error={errors?.reason ? true : false}>
                                <InputLabel id="recruiter-type-select-label">Reason</InputLabel>
                                <Select
                                    name='reason'
                                    id="reason"
                                    label="Status"
                                    value={values?.reason}
                                    onChange={(e) => setValues({ ...values, reason: e.target.value })}
                                >
                                    <MenuItem value={"Fake Candidate"}>Fake Candidate</MenuItem>
                                    <MenuItem value={"Technical Reject"}>Technical Reject</MenuItem>
                                    <MenuItem value={"Cultural Reject"}>Cultural Reject</MenuItem>
                                    <MenuItem value={"No Show"}>No Show</MenuItem>
                                    <MenuItem value={"Doesn't fit the role"}>Doesn't Fit The Role</MenuItem>
                                    <MenuItem value={"Internal approval rejected"}>Internal Approval Rejected </MenuItem>
                                    <MenuItem value={"Salary Fitment Issue"}>Salary Fitment Issue </MenuItem>
                                    <MenuItem value={"BGV Issue"}>BGV Issue </MenuItem>
                                </Select>
                                <FormHelperText>{errors?.reason}</FormHelperText>
                            </FormControl>

                        </Grid>
                        <Grid display="flex" justifyContent="flex-end" container>
                            <Button
                                sx={{ marginTop: "1.75rem", marginRight: "1rem" }}
                                variant="outlined"
                                onClick={() => handleClose()}
                            >
                                Cancel
                            </Button>
                            <Button
                                onClick={handleRejectClick}
                                variant="contained"
                                color="primary"
                                type="submit"
                                className="mpl-primary-btn"
                                sx={{ marginTop: "1.75rem" }}
                            >
                                Reject
                            </Button>
                        </Grid>
                    </Grid>
                </Box>
            </Popup >
        </>
    )
}

export default RejectPopup;
