import { Backdrop, Box, Button, CircularProgress, Divider, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material"
import Popup from "../../../Base/views/Popup"
import { useEffect, useState } from "react";
import { GetAtsInvoiceCalculationHelper } from "../../helper/AtsInvoiceHelper";
import { ConvertDate } from "../../../Base/helper/baseFunctions";
import SendIcon from '@mui/icons-material/Send';
import AtsInvoicePdf from "./AtsInvoicePdf";
import { pdf } from "@react-pdf/renderer";
import { CSVLink } from "react-csv";
import AtsInvoiceMail from "./AtsInvoiceMail";
import { useDispatch } from "react-redux";
import { AddAtsInvoiceAction, GetAtsGenerateInvoiceCandidatesAction } from "../../../../../store/ATS/Invoice/Action";
import { useTransition } from "react";

const ATSGenerateInvoicePopup = ({ setShowGenerateInvoice, selectedCandidates, currentData, filters }) => {
    const [finalData, setFinalData] = useState([]);
    const [candidateAmount, setCandidateAmount] = useState([]);
    const [sendmail, setSendMail] = useState(false);
    const [show, setShow] = useState(true);
    const [, startTransition] = useTransition();
    const date = new Date();
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    const today = year + '-' + month + '-' + day;
    const dispatch = useDispatch()

    useEffect(() => {
        getCalculation();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getCalculation = () => {
        let filteredData = []
        selectedCandidates?.forEach(res => {
            filteredData.push({ candidate_id: res?.candidate_id, commission_percentage: +res?.commission_percentage, billable_ctc: +res?.billable_ctc })
        })
        GetAtsInvoiceCalculationHelper({
            ats_company_id: currentData?.ats_company_id || currentData?.ats_grouporg_id,
            candidate_data: JSON.stringify(filteredData)
        }).then(res => {
            setShow(false)
            if (res?.data?.statuscode === 200) {
                setFinalData(res?.data?.data);
                startTransition(() => {
                    let temp = []
                    selectedCandidates?.forEach(result => {
                        var data = res?.data?.data?.find(item => +item.candidate_id === +result.candidate_id)
                        temp.push({ ...result, amount: data?.amount })
                    })
                    setCandidateAmount(temp)
                });
            }
        })

    }

    const getAmount = (id) => {
        let data = finalData?.filter(res => +res.candidate_id === +id)
        return data?.[0]?.amount
    }

    const handleDownloadPDF = async () => {
        const MyDocument = (
            <AtsInvoicePdf
                performadata={currentData}
                performaCandidateData={selectedCandidates}
                amountData={finalData}
                getAmount={getAmount}
                today={today}
            />
        );

        const blob = await pdf(MyDocument).toBlob();
        const url = URL.createObjectURL(blob);

        const a = document.createElement("a");
        a.href = url;
        a.download = `Proforma.pdf`;
        a.click();
        // Clean up the URL object
        URL.revokeObjectURL(url);
    };

    const headers = [
        { label: "Candidate Name", key: "candidate_name" },
        { label: "Job Title", key: "job_title" },
        { label: "Joined Date", key: "joined_date" },
        { label: "Billable CTC", key: "billable_ctc" },
        { label: "Payable Amount", key: "amount" },
    ];
    const handleSendMail = () => {
        setSendMail(true)
    }
    const handleSubmit = () => {
        let job_id = []
        candidateAmount?.forEach(res => {
            job_id.push(res?.candidate_job_id)
        })
        dispatch(AddAtsInvoiceAction({
            invoice_date: today,
            invoice_amount: +finalData?.[0]?.invoice_amt,
            gst_total: +finalData?.[0]?.gst_total,
            company_id: currentData?.ats_company_id || currentData?.ats_grouporg_id,
            candidates_json: JSON.stringify(candidateAmount),
            candidate_job_ids: `{${job_id}}`
        }))
        setShowGenerateInvoice(false);
        dispatch(GetAtsGenerateInvoiceCandidatesAction({ ats_company_id: filters?.company_filter, past_days: filters?.days }))
    }

    return (
        <>
            <Popup
                title={"Proforma"}
                openPopup={true}
                setOpenPopup={setShowGenerateInvoice}
                maxWidth={"md"}
                fullWidth={true}
            >
                {
                    show && <Backdrop
                        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                        open={true}
                    >
                        <CircularProgress color="inherit" />
                    </Backdrop>
                }
                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                    <Box item xs={12} sm={6}>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <Typography sx={{ fontWeight: "bold", minWidth: '100px' }}>Bill To&nbsp;</Typography>
                            <Typography sx={{ ml: 2 }}>{currentData?.billing_name || "NA"}</Typography>
                        </Box>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <Typography sx={{ fontWeight: "bold", minWidth: '100px' }}>Billing Address&nbsp;</Typography>
                            <Typography sx={{ ml: 1.5 }}>{currentData?.billing_address || "NA"}</Typography>
                        </Box>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <Typography sx={{ fontWeight: "bold", minWidth: '100px' }}>GST No.&nbsp;</Typography>
                            <Typography sx={{ ml: 2 }}>{currentData?.gst || "NA"}</Typography>
                        </Box>
                    </Box>
                    <Box item xs={12} sm={6}>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <Typography sx={{ fontWeight: "bold", minWidth: '100px' }}>Proforma Date&nbsp;</Typography>
                            <Typography sx={{ mr: 1 }}>{ConvertDate(today) || "NA"}</Typography>
                        </Box>
                    </Box>
                </Box>
                <Box sx={{ display: "flex", justifyContent: "flex-end", m: 1 }}>
                    <Button className="mpl-primary-btn" onClick={handleDownloadPDF}>
                        Download PDF
                    </Button>
                    <Button sx={{ ml: 2 }} className="mpl-primary-btn">
                        <CSVLink className="ats-csv-btn" data={candidateAmount} headers={headers} filename={"Proforma"}>
                            Download CSV
                        </CSVLink>
                    </Button>
                    <Button sx={{ ml: 2 }} className="mpl-primary-btn" onClick={handleSendMail}>
                        <SendIcon fontSize="small" sx={{ mr: 1 }} /> Send Mail
                    </Button>
                </Box>
                <Box>
                    <TableContainer>
                        <Table sx={{ minWidth: 650 }}>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Candidate Name</TableCell>
                                    <TableCell>Job Title</TableCell>
                                    <TableCell align="right">Joined CTC</TableCell>
                                    <TableCell align="right">Professional Fee %</TableCell>
                                    <TableCell align="right">Billable CTC</TableCell>
                                    <TableCell align="right">Payable Amount</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {selectedCandidates.map((row) => (
                                    <TableRow
                                        key={row?.name}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                        <TableCell>{row?.candidate_name}</TableCell>
                                        <TableCell>{row?.job_title}</TableCell>
                                        <TableCell align="right">{row?.joined_ctc}</TableCell>
                                        <TableCell align="right">{row?.commission_percentage}</TableCell>
                                        <TableCell align="right">{row?.billable_ctc}</TableCell>
                                        <TableCell align="right">{getAmount(row?.candidate_id)}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>
                <Popup
                    title={"Proforma"}
                    openPopup={sendmail}
                    setOpenPopup={setSendMail}
                    maxWidth={"md"}
                    fullWidth={true}
                >
                    <AtsInvoiceMail
                        setSendMail={setSendMail}
                        setShowGenerateInvoice={setShowGenerateInvoice}
                        performadata={currentData}
                        performaCandidateData={selectedCandidates}
                        amountData={finalData}
                        getAmount={getAmount}
                        today={today}
                    />
                </Popup>
                <Box sx={{ mt: 2, justifyContent: "flex-end", display: "flex" }}>
                    <Box>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <Typography sx={{ fontWeight: "bold", minWidth: '120px' }}>Invoice Amount&nbsp;</Typography>
                            <Typography sx={{ mr: 1 }}>{finalData?.[0]?.candidate_total || 0}</Typography>
                        </Box>
                        {
                            finalData?.[0]?.gst_applicable &&
                            <>
                                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                    <Typography sx={{ fontWeight: "bold", minWidth: '120px' }}>CGST&nbsp;-&nbsp;{finalData?.[0]?.cgst || 0}%</Typography>
                                    <Typography sx={{ mr: 1 }}>{finalData?.[0]?.cgst_total || 0}</Typography>
                                </Box>
                                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                    <Typography sx={{ fontWeight: "bold", minWidth: '120px' }}>SGST&nbsp;-&nbsp;{finalData?.[0]?.sgst || 0}%</Typography>
                                    <Typography sx={{ mr: 1 }}>{finalData?.[0]?.sgst_total || 0}</Typography>
                                </Box>
                            </>
                        }
                        <Divider sx={{ fontWeight: "bold" }} />
                        <Box sx={{ display: 'flex', alignItems: 'center', mt: 1 }}>
                            <Typography sx={{ fontWeight: "bold", minWidth: '120px', fontSize: "17px" }}>Total Amount&nbsp;</Typography>
                            <Typography sx={{ fontWeight: "bold", mr: 1, fontSize: "17px" }}>{+finalData?.[0]?.invoice_amt || 0}</Typography>
                        </Box>
                    </Box>
                </Box>
                <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 2 }}>
                    <Button
                        className="mpl-primary-btn"
                        onClick={handleSubmit}
                        sx={{ mt: 2 }}
                    >
                        Generate Invoice
                    </Button>
                </Box>
            </Popup>
        </>
    )
}

export default ATSGenerateInvoicePopup
